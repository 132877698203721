import React, { FC } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control/core-form-control.component';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { CoreInputProps } from '../core-input/core-input.component';
import { OutlinedInputProps } from '@mui/material';
import { NumericInputWrapperComponent } from './numeric-input-wrapper.component';

export interface CoreNumericFieldProps
	extends CoreFormControlProps,
		Omit<NumericFormatProps<OutlinedInputProps & CoreInputProps>, 'label'> {}

export const CoreNumericField: FC<CoreNumericFieldProps> = (props) => {
	const { disabled, error, helperText, decimalScale, maxLength, label, id, focused, fullWidth, controlSx, requiredMark, pattern, ...numericFormatProps } =
		props;

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			id={id}
			requiredMark={requiredMark}
			focused={focused}
			fullWidth={fullWidth}
			controlSx={controlSx}
		>
			<NumericFormat id={id} {...numericFormatProps} maxLength={maxLength} decimalScale={decimalScale} customInput={NumericInputWrapperComponent} />
		</CoreFormControl>
	);
};
