import { SxProps, Theme } from '@mui/material';

export const addReceiptByQrcodeInstructionModalStyles = {
  container: {
    pt: 3,
    px: 1,
    pb: 2,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  } as SxProps<Theme>,
  carouselItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    mt: 2,
  } as SxProps<Theme>,
  carouselText: {
    height: '60px',
    mb: 2,
    color: 'grey.700',
    fontSize: '1.2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps<Theme>,
  imageItem: {
    position: 'relative',
    flex: '1 1 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '100%',
      borderRadius: '20px',
    },
  } as SxProps<Theme>,
  skeleton: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
  } as SxProps<Theme>,
  iconCorrect: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    color: 'green',
    fontSize: 40,
  } as SxProps<Theme>,
  iconIncorrect: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    color: 'red',
    fontSize: 40,
  } as SxProps<Theme>,
  footer: {
    zIndex: 9,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    borderTop: '1px solid #e0e0e0',
    px: 2,
    py: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  } as SxProps<Theme>,
  saveButton: {
    flexGrow: 1,
    ml: 2,
    borderRadius: '50px',
    fontSize: '1.1em',
  } as SxProps<Theme>,
  modalHeader: {
    fontSize: 22,
    fontWeight: 'bold',
    borderBottom: '1px solid #e0e0e0',
    pb: 2,
    textAlign: 'center',
  } as SxProps<Theme>,
};
