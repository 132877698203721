import React, { FC, useRef, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { productNameStyles } from './product-name-input.styles';
import { CoreTextField } from "../../../../_core/components/_ui/core-textfield";

interface ProductNameInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
  disabled: boolean;
  autoFocus: boolean;
}

export const ProductNameInput: FC<ProductNameInputProps> = ({ value, onChange, error, disabled, autoFocus }) => {
  const [t] = useTranslation();
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <CoreTextField
      fullWidth
      id="name"
      name="name"
      label={t("Ім'я продукту")}
      labelSx={{mt:2}}
      placeholder={t('Енергетик Монстер Monster 500ml')}
      value={value}
      onChange={onChange}
      sx={productNameStyles.textField}
      disabled={disabled}
      error={!!error}
      helperText={error}
      inputRef={nameInputRef}
      requiredMark={true}
    />
  );
};
