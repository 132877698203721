import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import {CoreButton} from "../../../../_core/components/_ui/core-button";

interface MobileConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 1,
  borderRadius: '16px',
};

const iconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: 'primary.main',
  borderRadius: '50%',
  width: 50,
  height: 50,
  color: 'white',
  mb: 2,
};

export const MobileConfirmationModal: FC<MobileConfirmationModalProps> = ({ open, onClose, onConfirm }) => {
  const [t] = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={iconStyle}>
            <LockIcon />
          </Box>
          <Typography id="modal-title" variant="h6" textAlign="center" gutterBottom>
            {t('sign-out.confirm-message')}
          </Typography>
          <Box sx={{ display: 'flex', gap: 5, my: 2 }}>
            <CoreButton variant={'secondary'} onClick={onClose}>
              {t('defaults.modals.cancel-button')}
            </CoreButton>
            <CoreButton variant={'danger'} color="error" onClick={onConfirm}>
              {t('profile.logout')}
            </CoreButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
