import React, { FC, useState, useEffect } from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CoreCalendarField } from '../core-calendar-field';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import { useTheme } from "@mui/material";
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import {CoreIconButton} from "../core-button/index";

interface DateRangePickerProps {
  label: string;
  id: string;
  fromDate: string;
  toDate: string;
  onFromDateChange: (date: string) => void;
  onToDateChange: (date: string) => void;
  disabled?: boolean;
}

const formatDate = (date: Date | null) => {
  if (!date) return '';
  return format(date, 'yyyy-MM-dd', { locale: uk }); // Формат YYYY-MM-DD
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
                                                            label,
                                                            id,
                                                            fromDate,
                                                            toDate,
                                                            onFromDateChange,
                                                            onToDateChange,
                                                            disabled = false,
                                                          }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(fromDate ? new Date(fromDate) : null);
  const [endDate, setEndDate] = useState<Date | null>(toDate ? new Date(toDate) : null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (fromDate) {
      setStartDate(new Date(fromDate));
    }
    if (toDate) {
      setEndDate(new Date(toDate));
    }
  }, [fromDate, toDate]);

  const handleStartDateChange = (date: Date | null) => {
    if (date && endDate && date > endDate) {
      setError(t('Дата початку не може бути пізніше за дату кінця'));
    } else {
      setError('');
      setStartDate(date);
      if (date) onFromDateChange(formatDate(date));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (startDate && date && date < startDate) {
      setError(t('Дата кінця не може бути раніше за дату початку'));
    } else {
      setError('');
      setEndDate(date);
      if (date) onToDateChange(formatDate(date));
    }
  };

  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
    onFromDateChange('');
    onToDateChange('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Typography sx={{ color: useTheme().colors.gray }}>{label}</Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <CoreCalendarField
              id={`from-${id}`}
              withPortal={true}
              label={t('Від')}
              value={startDate ? formatDate(startDate) : ''}
              onChange={(date) => handleStartDateChange(date ? new Date(date) : null)}
              disabled={disabled}
              error={Boolean(error)}
              helperText={error}
              locale={uk}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <CoreCalendarField
              fullWidth
              withPortal={true}
              id={`to-${id}`}
              label={t('До')}
              value={endDate ? formatDate(endDate) : ''}
              onChange={(date) => handleEndDateChange(date ? new Date(date) : null)}
              disabled={disabled}
              error={Boolean(error)}
              helperText={error}
              locale={uk}
            />
          </Grid>
          
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'5%' }}>
            <IconButton onClick={handleClean} disabled={disabled} size={'small'}>
              <BackspaceOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};
