import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { useFetchUsersData } from '../../hooks/fetch-account-data.hook';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import { AccountDto } from "libs/models/dto/account.dto";

interface AccountSelectProps {
  account: string;
  defaultAccountId?: string;
  requiredMark?: boolean;
  handleAccountChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  hasAll?: boolean;
}

const getOptionsOrValue = (accountsList: AccountDto[], hasAll: boolean) => {
  let accounts = accountsList.map(x => new Option(x.name, x.id));
  if (hasAll)
    accounts.unshift(new Option('Усі', 'all'));
  return accounts;
}

const AccountSelect: React.FC<AccountSelectProps> = ({ account, defaultAccountId, handleAccountChange, disabled, hasAll = false, requiredMark = false }) => {
  const [t] = useTranslation();
  const { accountsList, isListLoading } = useFetchUsersData();
  const [selectedAccount, setSelectedAccount] = useState<string>(account || defaultAccountId || '');

  useEffect(() => {
    if (defaultAccountId) {
      setSelectedAccount(defaultAccountId);
      handleAccountChange({ target: { value: defaultAccountId } } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    }
  }, [defaultAccountId, handleAccountChange]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedAccount(event.target.value);
    handleAccountChange(event);
  };

  return (
    <div style={{ margin: 'normal', minWidth: 120, width: '100%' }}>
      {isListLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="circular-progress" style={{ width: 24, height: 24 }} />
        </div>
      ) : (
        <CoreSelect
          id="account-select"
          value={selectedAccount}
          label={'Аккаунт'}
          requiredMark={requiredMark}
          onChange={handleChange}
          placeholder={'Оберіть аккаунт'}
          disabled={disabled}
          fullWidth
          options={getOptionsOrValue(accountsList, hasAll).map(x => new Option(t(x.label), x.value))}
        >
        </CoreSelect>
      )}
    </div>
  );
};

export default AccountSelect;
