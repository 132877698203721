import React, { FC, useState, useEffect, useCallback, useRef, ChangeEvent } from 'react';
import { Container, Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addReceiptByManualStyles } from './add-receipt-by-manual.styles';
import AccountSelect from "../../components/account-select/account-select";
import { CoreButton, CoreIconButton } from "../../../_core/components/_ui/core-button/index";
import { ProductComponent } from '../../components/product/product';
import { Product } from '../../interfaces/product.interface';
import AddIcon from '@mui/icons-material/Add';
import { NewReceiptByManualDto } from "../../../../libs/models/dto/new-receipt-by-manual.dto";
import { useAddNewReceiptManualMutation } from "../../../../store/api/transactions.api";
import { NotifyModalProps } from "modules/add-transaction/interfaces/notify-modal-props.interface";
import DateTimePicker from "../../../_core/components/_ui/core-datetime-picker/core-datetime-picker.component";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreSelect from '../../components/store-select/store-select.component';
import { CoreTextField } from "../../../_core/components/_ui/core-textfield/core-textfield.component";
import { CoreModal } from "../../../_core/components/_ui/core-modal";
import CloseIcon from "@mui/icons-material/Close";

const getCurrentLocalDateTime = () => {
  const now = new Date();
  const offset = now.getTimezoneOffset();
  const localDate = new Date(now.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, 16);
};

const AddReceiptByManualPage: FC<NotifyModalProps> = ({ onClose, onOpen, onNotify }) => {
  const [t] = useTranslation();
  const [account, setAccount] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [date, setDate] = useState<string>(getCurrentLocalDateTime());
  const [products, setProducts] = useState<Product[]>([{ name: '', quantity: 0, price: 0 }]);
  const [store, setStore] = useState<string>('');
  const [isMoreOptionsVisible, setIsMoreOptionsVisible] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [wasProductAdded, setWasProductAdded] = useState<boolean>(true);

  const [addNewReceipt, { isLoading }] = useAddNewReceiptManualMutation();

  const endOfProductsRef = useRef<HTMLDivElement>(null);

  const handleAccountChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAccount(event.target.value as string);
  };

  const handleAddProduct = () => {
    setProducts([...products, { name: '', quantity: 0, price: 0 }]);
    setWasProductAdded(true);
  };

  useEffect(() => {
    if (wasProductAdded) {
      const timer = setTimeout(() => {
        if (endOfProductsRef.current) {
          endOfProductsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          setWasProductAdded(false);
        }
      }, 100);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [products, wasProductAdded]);

  const handleProductChange = (index: number, product: Product) => {
    const updatedProducts = [...products];
    updatedProducts[index] = product;
    setProducts(updatedProducts);
  };

  const handleDeleteProduct = (index: number) => {
    if (products.length > 1) {
      const updatedProducts = products.filter((_, i) => i !== index);
      setProducts(updatedProducts);
    }
  };

  const validateStore = (value: string) => {
    if (value.trim() === '') return '';
    if (value.length < 3) return t('Магазин має містити мінімум 3 символи');
    if (/^\d+$/.test(value)) return t('Магазин не може складатися тільки з цифр');
    return '';
  };

  const validateForm = () => {
    const isAccountValid = account.trim() !== '';
    const isDateValid = date.trim() !== '';
    const isStoreValid = !isMoreOptionsVisible || validateStore(store) === '';
    const areProductsValid = products.length > 0 && products.every(
      product =>
        product.name.length >= 3 &&
        product.quantity > 0 &&
        product.price > 0
    );

    setIsValid(isAccountValid && isDateValid && areProductsValid && isStoreValid);
  };

  const calculateTotalAmount = () => {
    const total = products.reduce((acc, product) => acc + product.quantity * product.price, 0);
    setTotalAmount(total);
  };

  useEffect(() => {
    validateForm();
    calculateTotalAmount();
  }, [account, date, store, products, isMoreOptionsVisible]);

  const handleFormSubmit = useCallback(async () => {
    if (!isValid) return;

    const transactionData: NewReceiptByManualDto = {
      accountId: account,
      date,
      items: products
    };

    if (isMoreOptionsVisible) {
      console.log(store);
      transactionData.description = description != ''?description:undefined;
      transactionData.shop = store != ''?store:undefined;
    }

    try {
      await addNewReceipt(transactionData).unwrap();
      onNotify('Успішно додано', 'success');
      onClose();
    } catch (error) {
      onNotify('Виникли проблеми', 'error');
      onClose();
    }
  }, [isValid, account, date, products, description, isMoreOptionsVisible, addNewReceipt, onNotify, onClose]);

  useEffect(() => {
    if (!onOpen) {
      setAccount('');
      setDescription('');
      setDate(getCurrentLocalDateTime());
      setProducts([{ name: '', quantity: 0, price: 0 }]);
      setStore('');
      setIsMoreOptionsVisible(false);
      setIsValid(false);
      setTotalAmount(0);
    }
  }, [onOpen]);

  return (
    <CoreModal
      modalTitle={'Додати покупку'}
      titleSx={addReceiptByManualStyles.modalHeader}
      open={onOpen}
      bodySx={{ p: 0 }}
      onClose={onClose}
      hideCloseIcon
      dialogSx={{ px: 0 }}
      paperSx={{ height: '100%', m: 0, pt: 0 }}>
      <Container sx={addReceiptByManualStyles.newProduct}>
        <Box component="form" sx={{ pt: 1 }} noValidate autoComplete="off">
          <AccountSelect account={account} requiredMark={true} handleAccountChange={handleAccountChange} disabled={isLoading} />
          <Grid container spacing={2} alignItems="center" sx={addReceiptByManualStyles.dateFieldContainer}>
            <Grid item xs={12}>
              <DateTimePicker
                label={t('Дата')}
                value={date}
                onChange={setDate}
                allowFuture={false}
                requiredMark={true}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
          <Accordion
            expanded={isMoreOptionsVisible}
            onChange={() => setIsMoreOptionsVisible(!isMoreOptionsVisible)}
            sx={addReceiptByManualStyles.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="additional-options-content"
              id="additional-options-header"
              sx={addReceiptByManualStyles.accordionSummary}
            >
              <Typography>{t('Додаткові опції')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={addReceiptByManualStyles.accordionDetails}>
              <StoreSelect
                store={store}
                setStore={setStore}
                isLoading={isLoading}
              />
              <CoreTextField
                fullWidth
                id="description"
                label={t('Опис')}
                placeholder={t('Продукти на вечерю')}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={addReceiptByManualStyles.description}
                disabled={isLoading}
              />
            </AccordionDetails>
          </Accordion>
          <br />
          {products.map((product, index) => (
            <ProductComponent
              key={index}
              product={product}
              onProductChange={(updatedProduct) => handleProductChange(index, updatedProduct)}
              onDelete={() => handleDeleteProduct(index)}
              disabled={isLoading}
              onValidationChange={validateForm}
              canDelete={products.length > 1}
              autoFocus={wasProductAdded && index === products.length - 1}
            />
          ))}
          <CoreButton onClick={handleAddProduct} sx={addReceiptByManualStyles.button}>
            <AddIcon />
            {t('Додати продукт')}
          </CoreButton>
          <Box ref={endOfProductsRef} />
        </Box>
      </Container>
      <Box sx={addReceiptByManualStyles.footer}>
        <CoreIconButton
          aria-label="close"
          onClick={onClose}
          sx={addReceiptByManualStyles.closeButton}
        >
          <CloseIcon />
        </CoreIconButton>
        <CoreButton
          aria-label="save"
          onClick={handleFormSubmit}
          sx={addReceiptByManualStyles.saveButton}
          disabled={!isValid || isLoading}
        >
          <Typography noWrap>
            {`Зберегти покупку · ${totalAmount.toFixed(2)}₴`}
          </Typography>
        </CoreButton>
      </Box>
    </CoreModal>
  );
};

export default React.memo(AddReceiptByManualPage);
