import { SxProps, Theme } from '@mui/material';

export const coreAutocompleteStyles: SxProps<Theme> = {
	'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
		padding: '8px 16px',
	},

	'& .MuiAutocomplete-inputRoot': {
		paddingLeft: 0,
		paddingBottom: '5px',
		paddingTop: '3px',

		'&.Mui-focused': {
			'.MuiAutocomplete-clearIndicator': {
				color: (theme) => theme.colors.black,
			},
		},

		'& .MuiAutocomplete-endAdornment': {
			top: 'calc(50% + 1px)',
			transform: 'translateY(-50%)',

			'.MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
				padding: '5px',
				color: 'rgba(0, 0, 0, 0.54)',

				'&.MuiAutocomplete-popupIndicatorOpen': {
					color: (theme) => theme.colors.black,
				},

				'&:hover': {
					backgroundColor: (theme) => theme.colors.blueTransparentLight,
				},
			},
		},
	},
};

export const autocompleteCheckboxStyles: SxProps<Theme> = {
	// marginLeft: '-13px',
	marginRight: '14px',
	padding: '0',
};
