import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SearchLarge } from '../../../constants/icons.constants';

interface CoreInfiniteListEmptyProps {
	label: string;
	description?: string | null;
	emptySearch?: boolean;
}

const CoreInfiniteListEmpty: FC<CoreInfiniteListEmptyProps> = (props) => {
	const { label, description, emptySearch } = props;

	const { t } = useTranslation();
	const isMobile = useTranslation();

	return (
		<Box
			sx={{
				p: 2,
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column'
			}}
		>
			{emptySearch && (
				<Box sx={{ mb: 3 }}>
					<SearchLarge />
				</Box>
			)}

			<Typography variant={isMobile ? 'h4' : 'h2'} sx={{ textAlign: 'center' }}>
				{emptySearch ? t('table.empty-search-title') : label}
			</Typography>

			{(description || emptySearch) && (
				<Typography
					variant={isMobile ? 'label' : 'body2'}
					sx={{
						color: theme => theme.colors.gray,
						mt: 2,
						textAlign: 'center',
						maxWidth: emptySearch ? 510 : 'unset'
					}}
				>
					{emptySearch ? t('table.empty-search-description') : description}
				</Typography>
			)}
		</Box>
	);
};

export default CoreInfiniteListEmpty;
