import React, { FC, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { uk } from 'date-fns/locale';

interface DateTimePickerProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  allowFuture?: boolean;
  disabled?: boolean;
  requiredMark?: boolean;
}

const DateTimePicker: FC<DateTimePickerProps> = ({
                                                   label,
                                                   value,
                                                   onChange,
                                                   allowFuture = true,
                                                   disabled = false,
                                                   requiredMark = false
                                                 }) => {
  const [error, setError] = useState<string>('');
  const [dateValue, setDateValue] = useState<Date | null>(null);

  useEffect(() => {
    if (value) {
      setDateValue(new Date(value));
    }
  }, [value]);

  const handleChange = (newDate: Date | null) => {
    if (!newDate) return;

    const currentDate = new Date();
    if (!allowFuture && newDate > currentDate) {
      setError('Майбутній час не дозволений');
    } else {
      setError('');
      setDateValue(newDate);
      onChange(newDate.toISOString());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uk}>
      <Box>
        <MobileDateTimePicker
          label={label}
          value={dateValue}
          onChange={handleChange}
          disabled={disabled}
          ampm={false}
          disableFuture={!allowFuture}
          slotProps={{
            textField: {
              fullWidth: true,
              error: Boolean(error),
              helperText: error || undefined,
              required: requiredMark,
              label: label,
              disabled: disabled,
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateTimePicker;
