import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CardContent, Typography, Card, Box, IconButton } from '@mui/material';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useCustomGoogleAuthButton, GoogleAuthButton } from '../../../auth';
import {
	contactInfoButtonStyles,
	contactInfoRowStyles,
	subtitleStyles,
	profileDataCategoryStyles,
	cardStyles,
} from './profile-card.styles';
import { useMuiModal } from '../../../_core/hooks';
import { EditUserDataModal, EditUserDataModalProps } from './modals/edit-user-data-modal.component';
import { EditPhoneModal, EditPhoneModalProps } from './modals/edit-phone-modal.component';
import { EditEmailModal, EditEmailModalProps } from './modals/edit-email-modal.component';
import { isGoogleAuthEnabled } from '../../../../libs/constants';
import { UserDto } from 'libs/models/dto';
import EditIcon from '@mui/icons-material/Edit';

interface ProfileCardProps {
	profile: UserDto;
}

export const ProfileCard: FC<ProfileCardProps> = (props) => {
	const { profile } = props;

	const [t] = useTranslation();

	const { handleGoogleButtonClick, setGoogleButtonRef } = useCustomGoogleAuthButton();

	const { openModal: openEditUserDataModal } = useMuiModal<EditUserDataModalProps>(EditUserDataModal);
	const { openModal: openEditEmailModal } = useMuiModal<EditEmailModalProps>(EditEmailModal);
	const { openModal: openEditPhoneModal } = useMuiModal<EditPhoneModalProps>(EditPhoneModal);

	const handleOpenEditUserDataModal = useCallback(() => {
		openEditUserDataModal({
			data: profile,
		});
	}, [openEditUserDataModal, profile]);

	const handleOpenEditEmailModal = useCallback(() => {
		openEditEmailModal({
			email: profile.email,
		});
	}, [openEditEmailModal, profile.email]);

	const handleOpenEditPhoneModal = useCallback(() => {
		openEditPhoneModal({
			phoneNumber: profile.phoneNumber,
		});
	}, [openEditPhoneModal, profile.phoneNumber]);

	return (
		<Card sx={cardStyles}>
			<CardContent>
				<Typography variant="h5" sx={{ mb: 2 }}>
					{t('profile.title')}
				</Typography>

				<Box sx={profileDataCategoryStyles}>
					<Typography variant="h6" sx={subtitleStyles}>
						{t('profile.user-data')}
					</Typography>
					<Box sx={contactInfoRowStyles}>
						<Typography>
							{t('Імя')}: {profile.firstName}
						</Typography>
						
						{/*<IconButton sx={contactInfoButtonStyles} onClick={handleOpenEditUserDataModal}>*/}
						{/*	<EditIcon />*/}
						{/*</IconButton>*/}
					</Box>
					<Box sx={contactInfoRowStyles}>
						<Typography>
							{t('Прізвище')}: {profile.secondName}
						</Typography>

						{/*<IconButton sx={contactInfoButtonStyles} onClick={handleOpenEditUserDataModal}>*/}
						{/*	<EditIcon />*/}
						{/*</IconButton>*/}
					</Box>
					
				</Box>

				<Box sx={profileDataCategoryStyles}>
					<Typography variant="h6" sx={subtitleStyles}>
						{t('profile.contact-info')}
					</Typography>
					<Box sx={contactInfoRowStyles}>
						<Typography>
							{t('profile.email')}: {profile.email}
						</Typography>
						{/*<IconButton sx={contactInfoButtonStyles} onClick={handleOpenEditEmailModal}>*/}
						{/*	<EditIcon />*/}
						{/*</IconButton>*/}
					</Box>
					<Box sx={contactInfoRowStyles}>
						<Typography>
							{t('profile.phone')}: {profile.phoneNumber}
						</Typography>
						{/*<IconButton sx={contactInfoButtonStyles} onClick={handleOpenEditPhoneModal}>*/}
						{/*	<EditIcon />*/}
						{/*</IconButton>*/}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};