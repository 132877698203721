import React, { FC, useEffect, useState, ChangeEvent, useRef } from 'react';
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Grid,
  InputAdornment,
  Container
} from '@mui/material';
import { receiptEditStyle } from './receipt-edit.style';
import { useLazyGetReceiptQuery, useLazyGetOutcomeTransactionQuery, useEditReceiptMutation, useEditOutcomeTransactionMutation } from '../../../../store/api/transactions.api';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import {CoreButton, CoreIconButton} from "../../../_core/components/_ui/core-button";
import { CoreTextField } from "../../../_core/components/_ui/core-textfield";
import { CoreNumericField } from "../../../_core/components/_ui/core-numeric-field";
import StoreSelect from "../../../add-transaction/components/store-select/store-select.component";
import { ProductComponent } from "../../../add-transaction/components/product/product";
import { Product } from "../../../add-transaction/interfaces/product.interface";
import AccountSelect from "../../../add-transaction/components/account-select/account-select";
import DateTimePicker from "../../../_core/components/_ui/core-datetime-picker/core-datetime-picker.component";
import {CoreModal} from "../../../_core/components/_ui/core-modal";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ReceiptViewModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  receiptId: string;
  isTransaction: boolean;
}

const normalizeValue = (value: any) => (value === '' ? null : value);

const areValuesEqual = (value1: any, value2: any) => {
  if (value1 === undefined || value1 === '') value1 = '';
  if (value2 === undefined || value2 === '') value2 = '';
  return value1 === value2;
};

export const ReceiptEditModal: FC<ReceiptViewModalProps> = ({ open, onClose, onSuccess, receiptId, isTransaction }) => {
  const [trigger, { data, error, isLoading }] = useLazyGetReceiptQuery();
  const [transactionTrigger, { data: transactionData, error: transactionError, isLoading: transactionIsLoading }] = useLazyGetOutcomeTransactionQuery();
  const [editReceipt] = useEditReceiptMutation();
  const [editTransaction] = useEditOutcomeTransactionMutation();
  const [formData, setFormData] = useState<any>({});
  const [originalData, setOriginalData] = useState<any>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const { t } = useTranslation();
  const prevFormDataRef = useRef<any>({});
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const [productsValidation, setProductsValidation] = useState<boolean[]>([]);

  useEffect(() => {
    if (open) {
      if (isTransaction) {
        transactionTrigger(receiptId);
      } else {
        trigger(receiptId);
      }
    }
  }, [open, trigger, transactionTrigger, receiptId, isTransaction]);

  useEffect(() => {
    if (data) {
      const normalizedData = normalizeFormData(data);
      setFormData(normalizedData);
      setOriginalData(normalizedData);
      setProductsValidation(new Array(normalizedData.products?.length || 0).fill(true));
    } else if (transactionData) {
      const normalizedData = normalizeFormData(transactionData);
      setFormData(normalizedData);
      setOriginalData(normalizedData);
      setProductsValidation(new Array(normalizedData.products?.length || 0).fill(true));
    }
  }, [data, transactionData]);

  useEffect(() => {
    if (JSON.stringify(prevFormDataRef.current) !== JSON.stringify(formData)) {
      validateForm();
      prevFormDataRef.current = formData;
    }
  }, [formData]);

  useEffect(() => {
    validateForm();
  }, [productsValidation]);

  const handleAccountChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData((prev: any) => ({
      ...prev,
      accountId: normalizeValue(event.target.value),
    }));
  };

  const handleStoreChange = (store: string) => {
    setFormData((prev: any) => ({
      ...prev,
      shopName: normalizeValue(store),
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: normalizeValue(value),
    }));
  };

  const handleDateChange = (date: string) => {
    setFormData((prev: any) => ({
      ...prev,
      dateOfPurchase: date,
    }));
  };

  const calculateTotal = (products: Product[]) => {
    const sum = products.reduce((acc, product) => acc + (product.quantity * product.price), 0);
    const tax = sum * 0.20;
    return { sum, tax };
  };

  const handleProductChange = (index: number, product: Product) => {
    const updatedProducts = [...formData.products];
    updatedProducts[index] = product;
    const { sum, tax } = calculateTotal(updatedProducts);

    setFormData((prev: any) => ({
      ...prev,
      products: updatedProducts,
      sum,
      tax,
    }));
  };

  const handleAddProduct = () => {
    const newProduct = { name: '', quantity: 0, price: 0 };
    const updatedProducts = [...formData.products, newProduct];

    setFormData((prev: any) => ({
      ...prev,
      products: updatedProducts,
    }));

    setProductsValidation((prev) => [...prev, false]);
  };

  const handleDeleteProduct = (index: number) => {
    const updatedProducts = [...formData.products];
    updatedProducts.splice(index, 1);
    const { sum, tax } = calculateTotal(updatedProducts);

    setFormData((prev: any) => ({
      ...prev,
      products: updatedProducts,
      sum,
      tax,
    }));

    setProductsValidation((prev) => {
      const updatedValidation = [...prev];
      updatedValidation.splice(index, 1);
      return updatedValidation;
    });
  };

  const normalizeFormData = (data: any) => {
    const normalizedData = { ...data };
    Object.keys(normalizedData).forEach((key) => {
      normalizedData[key] = normalizeValue(normalizedData[key]);
    });
    return normalizedData;
  };

  const validateForm = () => {
    let isValid = true;
    const errors: { [key: string]: string } = {};

    const fieldsToValidate = ['description', 'address', 'cashiersName'];
    fieldsToValidate.forEach(field => {
      if (formData[field] && formData[field].length < 3) {
        isValid = false;
        errors[field] = `${t('Мінімальна довжина')} 3 ${t('символи')}`;
      }
    });

    if (isTransaction && !formData.shopName) {
      isValid = false;
      errors['shopName'] = t('Магазин обов’язковий для транзакції');
    }

    const normalizedFormData = normalizeFormData(formData);
    const normalizedOriginalData = normalizeFormData(originalData);
    for (const key in normalizedFormData) {
      if (!areValuesEqual(normalizedFormData[key], normalizedOriginalData[key])) {
        isValid = true;
        break;
      }
      isValid = false;
    }

    if (Object.keys(errors).length > 0) {
      isValid = false;
    }

    if (productsValidation.includes(false)) {
      isValid = false;
    }

    setValidationErrors(errors);
    setIsFormValid(isValid);
  };

  const resetForm = () => {
    setFormData(normalizeFormData(originalData));
    setIsFormValid(false);
    setValidationErrors({});
    setProductsValidation(new Array(originalData.products?.length || 0).fill(true));
  };

  const handleSave = async () => {
    if (isTransaction) {
      await editTransaction(formData);
    } else {
      await editReceipt(formData);
    }
    onSuccess();
    onClose();
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const formatDate = (date: string | undefined) => {
    return new Date(date as string).toLocaleString('uk-UA', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const formatNumber = (num: number | undefined) => {
    if(num != undefined){
      return num.toFixed(2);
    }
    return undefined;
  };

  const renderData = isTransaction ? transactionData : data;

  if (isLoading || transactionIsLoading) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
    );
  }

  return open ? (
    <CoreModal
      open={open}
      bodySx={{ p: 0 }}
      dialogSx={{ px: 0 }}
      hideCloseIcon
      titleSx={receiptEditStyle.title}
      modalTitle={t('Редагувати чек від: ') + formatDate(renderData?.dateOfPurchase) ?? '---'}
      onClose={handleClose}
      paperSx={{ height: '100%', m: 0, pt: 0 }}>
      <Container sx={receiptEditStyle.newProduct}>
        <Box component="form" noValidate autoComplete="off" sx={receiptEditStyle.content}>
          <Typography variant="body1" sx={receiptEditStyle.sectionTitle}>
            Інформація про покупку
          </Typography>
          <Box sx={receiptEditStyle.fieldRow}>
            <AccountSelect
              account={formData.accountId}
              defaultAccountId={formData.accountId}
              requiredMark={true}
              handleAccountChange={handleAccountChange}
              disabled={isLoading}
            />
          </Box>
          <Box sx={receiptEditStyle.fieldRow}>
            <StoreSelect
              store={formData.shopName}
              setStore={handleStoreChange}
              isLoading={isLoading}
              requiredMark={isTransaction}
            />
          </Box>
          {!isTransaction && (
            <Box sx={receiptEditStyle.fieldRow}>
              <CoreTextField
                fullWidth
                id="address"
                placeholder={t('Введіть адресу')}
                label={t('Адреса')}
                name="address"
                value={formData.address ?? ''}
                onChange={handleChange}
                error={!!validationErrors['address']}
                helperText={validationErrors['address']}
              />
            </Box>
          )}
          {!isTransaction && (
            <Box sx={receiptEditStyle.fieldRow}>
              <CoreTextField
                fullWidth
                id="cashiersName"
                placeholder={t(`Введіть ім'я касира`)}
                label={t('Касир')}
                name="cashiersName"
                value={formData.cashiersName ?? ''}
                onChange={handleChange}
                error={!!validationErrors['cashiersName']}
                helperText={validationErrors['cashiersName']}
              />
            </Box>
          )}
          <Box sx={receiptEditStyle.fieldRow}>
            <CoreTextField
              fullWidth
              id="description"
              label={t('Опис')}
              name="description"
              placeholder={t('Продукти на вечерю')}
              value={formData.description ?? ''}
              onChange={handleChange}
              error={!!validationErrors['description']}
              helperText={validationErrors['description']}
            />
          </Box>
          <Box sx={receiptEditStyle.fieldRow}>
            <DateTimePicker
              label={t('Дата')}
              value={formData.dateOfPurchase}
              onChange={handleDateChange}
              allowFuture={false}
              requiredMark={true}
              disabled={isLoading}
            />
          </Box>

          {!isTransaction && formData.products && (
            <>
              <Typography variant="body1" sx={receiptEditStyle.sectionTitle}>
                Товари
              </Typography>
              {formData.products.map((product: Product, index: number) => (
                <ProductComponent
                  key={index}
                  product={product}
                  onProductChange={(updatedProduct) => handleProductChange(index, updatedProduct)}
                  onDelete={() => handleDeleteProduct(index)}
                  disabled={isLoading}
                  canDelete={formData.products.length > 1}
                  onValidationChange={(isValid: boolean) => {
                    setProductsValidation((prev) => {
                      const updatedValidation = [...prev];
                      updatedValidation[index] = isValid;
                      return updatedValidation;
                    });
                  }}
                />
              ))}
              <CoreButton onClick={handleAddProduct} sx={receiptEditStyle.addButton}>
                <AddIcon />
                {t('Додати продукт')}
              </CoreButton>
            </>
          )}

          {isTransaction && (
            <Box sx={receiptEditStyle.fieldRow}>
              <CoreNumericField
                fullWidth
                id="totalSum"
                name="totalSum"
                decimalScale={2}
                label={t('Загальна сума')}
                inputMode="decimal"
                value={formData.sum ?? ''}
                requiredMark={true}
                onChange={(e) => {
                  const { value } = e.target;
                  setFormData((prev: any) => ({
                    ...prev,
                    sum: parseFloat(value),
                  }));
                }}
                startAdornment={<InputAdornment position="start">₴</InputAdornment>}
              />
            </Box>
          )}

          <Typography variant="body1" sx={receiptEditStyle.sectionTitle}>
            Загальна вартість
          </Typography>
          {formData.tax && (
            <Box sx={receiptEditStyle.fieldRow}>
              <Typography variant="body2" sx={receiptEditStyle.fieldLabel}><strong>Податок:</strong></Typography>
              <Typography variant="body2" sx={receiptEditStyle.fieldValue}>{formatNumber(formData.tax)} грн</Typography>
            </Box>
          )}
          <Box sx={receiptEditStyle.fieldRow}>
            <Typography variant="body2" sx={receiptEditStyle.fieldLabel}><strong>Сума без податку:</strong></Typography>
            <Typography variant="body2" sx={receiptEditStyle.fieldValue}>{formatNumber(formData.sum - (formData.tax ?? 0))} грн</Typography>
          </Box>
          <Grid container sx={{ pt: 3 }}>
            <Grid item xs={1}/>
            <Grid item xs={11}>
              <Typography variant="h6" noWrap sx={receiptEditStyle.totalAmount}>
                Сума: {formatNumber(formData.sum) ?? '---'} грн
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box sx={receiptEditStyle.footer}>
        <CoreButton variant="primary" onClick={handleSave} disabled={!isFormValid}>
          Зберегти
        </CoreButton>
        <CoreButton variant="secondary" onClick={handleClose}>
          Закрити
        </CoreButton>
      </Box>
    </CoreModal>
  ) : null;
};
