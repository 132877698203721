import React, { FC, ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {CoreButton} from "../../../../_core/components/_ui/core-button/core-button.component";

interface BaseDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  children?: ReactNode;
}

const BaseDialog: FC<BaseDialogProps> = ({
                                           open,
                                           onClose,
                                           title,
                                           description,
                                           onConfirm,
                                           confirmText,
                                           cancelText,
                                           children,
                                         }) => {
  const [t] = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" aria-describedby="dialog-description">
      <DialogTitle id="dialog-title">{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          {t(description)}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <CoreButton onClick={onClose} variant={'secondary'}>
          {t(cancelText || 'Скасувати')}
        </CoreButton>
        {onConfirm && (
          <CoreButton onClick={onConfirm} variant={'primary'} autoFocus>
            {t(confirmText || 'Продовжити')}
          </CoreButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BaseDialog;
