import { FC, forwardRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox, CheckboxProps } from '@mui/material';
import { StyledComponent } from '@emotion/styled';

export interface CoreCheckboxProps extends CheckboxProps {
	name?: string;
}

export const CoreStyledCheckbox: StyledComponent<CheckboxProps> = styled(Checkbox)(({ theme }) => ({
	'&.MuiCheckbox-root': {
		color: theme.colors.gray,

		'&.Mui-checked': {
			color: theme.colors.lightBlue,
		},
	},
}));

// @ts-ignore
export const CoreCheckbox: FC<CoreCheckboxProps> = forwardRef<any, CoreCheckboxProps>((props, ref) => {
	const { value, checked } = props ?? {};

	const isChecked = useMemo(
		() => (typeof checked === 'boolean' ? checked : typeof value === 'string' ? value === 'true' : !!value),
		[checked, value]
	);

	return <CoreStyledCheckbox {...props} checked={isChecked} inputRef={ref} />;
});

CoreCheckbox.displayName = 'CoreCheckbox';
