interface QrCodeReceiptModel {
  id: string;
  fn: number;
  sum: number;
  date: string;
  time: string;
}

enum QrCodeTypeEnum {
  TypeA,
  TypeB,
  TypeC,
  TypeD,
  Unknown
}

const TimePatterns: string[] = [
  "^([01]\\d|2[0-3]):([0-5]\\d):([0-5]\\d)$", // HH:mm:ss,
  "^([01]\\d|2[0-3]):([0-5]\\d)$", // HH:mm
  "time=(\\d{2}:\\d{2})(:\\d{2})?",
  "^(0[1-9]|1[0-2]):([0-5]\\d):([0-5]\\d) (AM|PM)$", // hh:mm:ss AM/PM
  "^(0[1-9]|1[0-2]):([0-5]\\d) (AM|PM)$", // hh:mm AM/PM
  "^([01]\\d|2[0-3]):([0-5]\\d):([0-5]\\d)\\.\\d{3}$", // HH:mm:ss.fff
  "^(0[1-9]|1[0-2]):([0-5]\\d):([0-5]\\d)\\.\\d{3} (AM|PM)$", // hh:mm:ss.fff AM/PM
  "(?<time>\\d{1,2}:\\d{1,2}:\\d{1,2})",
  "time=(\\d{4,6})"
];

const DatePatterns: string[] = [
  "date=(\\d{8})", // date=YYYYMMDD
  "^\\d{4}-\\d{1,2}-\\d{1,2}$", // YYYY-MM-DD
  "^\\d{1,2}/\\d{1,2}/\\d{4}$", // MM/DD/YYYY
  "date=(\\d{4}-\\d{1,2}-\\d{1,2})",
  "date=(\\d{4}.\\d{1,2}.\\d{1,2})",
  "(?<date>\\d{1,2}\\.\\d{1,2}\\.\\d{4})" // DD.MM.YYYY
];

const IdPatterns: string[] = [
  "id=(\\d{3,11})",
  "id=([a-zA-Z0-9_\\-.]{9,12})"
];

const FnPatterns: string[] = [
  "fn=(\\d{10})"
];

const PatternQrDataA = "^(?=.*\\bid=[a-zA-Z0-9_\\-.]+?\\b)(?=.*\\bfn=.+?\\b)(?=.*\\b(?:sm|sum)=.+?\\b)(?=.*\\bdate=.+?\\b)(?=.*\\btime=.+?\\b).*$";

const PatternQrDataB = "(?=.*FN\\d{9,10})(?=.*N\\d{5})(?=.*=\\d+(\\.\\d{1,2})?)(?=.*\\d{1,2}\\.\\d{2}\\.\\d{4}\\s\\d{2}:\\d{2}:\\d{2})(?=.*MAC=).*";

const PatternQrDataC = "^[A-Za-z0-9+/=]{40,44};\\d{10};\\d{10};\\d{10};\\d{10}$";

const PatternQrDataD = "^[A-Z0-9]{8}\\s+\\d{2}\\.\\d{2}\\.\\d{4}\\s+\\d{2}-\\d{2}-\\d{2}\\s+#\\d+\\s+\\d+(\\.\\d{2})?\\s+#\\d+$";

class ReceiptUtils {
  static convertToSearchModel(data: string): QrCodeReceiptModel | null {
    const decodedData = decodeURIComponent(data);

    const type = this.getType(decodedData);

    switch (type) {
      case QrCodeTypeEnum.TypeA:
        return this.extractQrCodeReceiptTypeA(decodedData);
      case QrCodeTypeEnum.TypeB:
        return this.extractQrCodeReceiptTypeB(decodedData);
      case QrCodeTypeEnum.TypeC:
        return this.extractQrCodeReceiptTypeC(decodedData);
      case QrCodeTypeEnum.TypeD:
        return this.extractQrCodeReceiptTypeD(decodedData);
      case QrCodeTypeEnum.Unknown:
      default:
        return null;
    }
  }

  private static extractQrCodeReceiptTypeB(data: string): QrCodeReceiptModel {
    const fnPattern = /FN(?<fn>\d{5,10})/;
    const nPattern = / N(?<n>\d{5,10})/;
    const sumPattern = /=(?<sum>\d+(\.\d{1,2})?)/;

    const fnMatch = data.match(fnPattern);
    const nMatch = data.match(nPattern);
    const sumMatch = data.match(sumPattern);
    const dateMatch = this.tryToExtractByPatterns(data, DatePatterns);
    const timeMatch = this.tryToExtractByPatterns(data, TimePatterns);

    if (fnMatch && nMatch && sumMatch && dateMatch && timeMatch) {
      return {
        id: nMatch[1],
        fn: parseInt(fnMatch[1]),
        sum: parseFloat(sumMatch[2]),
        date: dateMatch[1],
        time: timeMatch[1]
      };
    }

    throw new Error("FormatException");
  }

  private static extractQrCodeReceiptTypeC(data: string): QrCodeReceiptModel {
    const pattern = /;(\d{10});(\d{10});(\d{10});(\d{10})/;

    const match = data.match(pattern);

    if (!match) 
      throw new Error("FormatException");

    const sumInCents = match[1];
    const dateTime = match[2];
    const fn = match[3];
    const id = match[4];

    if (dateTime.length !== 10) 
      throw new Error("FormatException");

    const datePart = dateTime.substring(0, 6); // Перші 6 символів для дати
    const timePart = dateTime.substring(6, 4); // Наступні 4 символи для часу

    return {
      id: id,
      fn: parseInt(fn),
      sum: parseFloat(sumInCents) / 100,
      date: datePart,
      time: timePart
    };
  }

  private static extractQrCodeReceiptTypeD(data: string): QrCodeReceiptModel {
    const pattern = /(\d{2}\.\d{2}\.\d{4}) (\d{2}-\d{2}-\d{2}) #(\d+) (\d+\.\d{2}) #(\d+)/;

    const match = data.match(pattern);

    if (!match) 
      throw new Error("FormatException");

    const date = match[1];
    const time = match[2];
    const id = match[3];
    const fn = match[5];
    const sum = parseFloat(match[4]);

    return {
      date: date,
      time: time,
      id: id,
      sum: sum,
      fn: parseInt(fn)
    };
  }

  private static getType(data: string): QrCodeTypeEnum {
    if (new RegExp(PatternQrDataA).test(data)) {
      return QrCodeTypeEnum.TypeA;
    }

    if (new RegExp(PatternQrDataB).test(data)) {
      return QrCodeTypeEnum.TypeB;
    }

    if (new RegExp(PatternQrDataC).test(data)) {
      return QrCodeTypeEnum.TypeC;
    }

    if (new RegExp(PatternQrDataD).test(data)) {
      return QrCodeTypeEnum.TypeD;
    }

    return QrCodeTypeEnum.Unknown;
  }

  private static tryToExtractByPatterns(url: string, patterns: string[]): string | null {
    for (const pattern of patterns) {
      const match = url.match(new RegExp(pattern));
      if (match) {
        return match[1];
      }
    }
    return null;
  }

  private static extractQrCodeReceiptTypeA(url: string): QrCodeReceiptModel {
    const smSumPattern = /(?:sm|sum)=(\d+(\.\d{2})?)/;

    const id = this.tryToExtractByPatterns(url, IdPatterns);
    const fn = this.tryToExtractByPatterns(url, FnPatterns);
    const sumMatch = url.match(smSumPattern);
    const date = this.tryToExtractByPatterns(url, DatePatterns);
    const time = this.tryToExtractByPatterns(url, TimePatterns);
    if (!id || !fn || !sumMatch || !date || !time)
      throw new Error("FormatException");

    const sum = parseFloat(sumMatch[1]);

    return {
      id: id,
      fn: parseInt(fn),
      sum: sum,
      date: date,
      time: time
    };
  }
}

export { ReceiptUtils };
export type { QrCodeReceiptModel };
export { QrCodeTypeEnum };
