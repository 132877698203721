import React, { FC } from 'react';
import BaseDialog from '../../../_core/components/_ui/base-dialog/base-dialog.component';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationReceiptDialog: FC<ConfirmationDialogProps> = ({ open, onClose, onConfirm }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Ця покупка уже додана"
      description="Ця покупка уже додана! Бажаєте ще раз її додати?"
      onConfirm={onConfirm}
      confirmText="Так"
      cancelText="Ні"
    />
  );
};

export default ConfirmationReceiptDialog;
