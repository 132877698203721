import React, { FC } from 'react';
import { CoreButtonBase, CoreButtonBaseProps } from './core-button-base.component';
import { FadeSpinner } from '../fade-spinner';
import { useTheme } from '@mui/material';
import { CoreCircleButton, CoreCircleButtonProps } from './core-circle-button.component';
import { coreButtonFadeSpinnedStyles } from './core-button.styles';
import { flexRowCenter } from '../../../styles/flex.styles';
import { clsx } from '../../../utils/style.utils';

type DefaultButtonType = CoreButtonBaseProps & { circle?: false };
type CircleButtonType = CoreCircleButtonProps & { circle: true };

export type CoreButtonProps = (DefaultButtonType | CircleButtonType) & { loading?: boolean };

export const CoreButton: FC<CoreButtonProps> = (props) => {
	const { loading, circle, disabled, children, ...buttonProps } = props;

	const theme = useTheme();

	const Component = circle ? CoreCircleButton : CoreButtonBase;
	const size = circle ? props.size : undefined;
	const hideChildren = loading && circle;

	return (
		<Component
			{...buttonProps}
			sx={clsx(flexRowCenter, buttonProps.sx)}
			size={size}
			disabled={loading || disabled}
			className={loading ? 'core-button-loading' : undefined}
		>
			{loading && (
				<FadeSpinner
					size={17}
					height={5}
					width={2}
					borderRadius={1}
					color={theme.colors.white}
					sx={coreButtonFadeSpinnedStyles(circle)}
				/>
			)}
			{!hideChildren && children}
		</Component>
	);
};
