import { SxProps, Theme } from '@mui/material';

export const flex: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'row',
};

/* flex-direction */

export const flexColumn: SxProps<Theme> = {
	...flex,
	flexFlow: 'column nowrap',
	rowGap: 1,
};

export const flexRow: SxProps<Theme> = {
	...flex,
	flexFlow: 'row nowrap',
	columnGap: 1,
};

/* custom-directions */
export const flexRowWithMobileBreakpoint: SxProps<Theme> = [
	flexRow,
	({ breakpoints }) => ({ [breakpoints.down('sm')]: flexColumn }),
];

export const flexRowWithOverflow: SxProps<Theme> = {
	...flexRow,
	overflowX: 'auto',
};

/* flex-wrap */

export const flexWrap: SxProps<Theme> = {
	flexWrap: 'wrap',
};

export const flexNoWrap: SxProps<Theme> = {
	flexWrap: 'nowrap',
};

/* flex-grow */

export const flexNoGrow: SxProps<Theme> = {
	flexGrow: 0,
};

export const flexGrow: SxProps<Theme> = {
	flexGrow: 1,
};

/* flex-shrink */

export const flexNoShrink: SxProps<Theme> = {
	flexShrink: 0,
};

export const flexShrink: SxProps<Theme> = {
	flexShrink: 1,
};

/* justify-content */

export const flexContentCenter: SxProps<Theme> = {
	justifyContent: 'center',
};

export const flexContentEnd: SxProps<Theme> = {
	justifyContent: 'flex-end',
};

export const flexContentStart: SxProps<Theme> = {
	justifyContent: 'flex-start',
};

export const flexContentEvenly: SxProps<Theme> = {
	justifyContent: 'space-evenly',
};

export const flexContentBetween: SxProps<Theme> = {
	justifyContent: 'space-between',
};

export const flexContentAround: SxProps<Theme> = {
	justifyContent: 'space-around',
};

/* align-items */

export const flexItemsCenter: SxProps<Theme> = {
	alignItems: 'center',
};

export const flexItemsStart: SxProps<Theme> = {
	alignItems: 'flex-start',
};

export const flexItemsEnd: SxProps<Theme> = {
	alignItems: 'flex-end',
};

export const flexItemsStretch: SxProps<Theme> = {
	alignItems: 'stretch',
};

/* align-content */

export const flexAlignContentCenter: SxProps<Theme> = {
	alignContent: 'center',
};

export const flexAlignContentStart: SxProps<Theme> = {
	alignContent: 'flex-start',
};

export const flexAlignContentEnd: SxProps<Theme> = {
	alignContent: 'flex-end',
};

export const flexAlignContentStretch: SxProps<Theme> = {
	alignContent: 'stretch',
};

export const flexAlignContentBetween: SxProps<Theme> = {
	alignContent: 'space-between',
};

export const flexAlignContentAround: SxProps<Theme> = {
	alignContent: 'space-around',
};

export const flexAlignContentEvenly: SxProps<Theme> = {
	alignContent: 'space-evenly',
};

/* align-self */

export const flexAlignSelfStart: SxProps<Theme> = {
	alignSelf: 'flex-start',
};

export const flexAlignSelfEnd: SxProps<Theme> = {
	alignSelf: 'flex-end',
};

export const flexAlignSelfStretch: SxProps<Theme> = {
	alignSelf: 'stretch',
};

export const flexAlignSelfCenter: SxProps<Theme> = {
	alignSelf: 'center',
};

/* custom-align */

export const flexCenter: SxProps<Theme> = {
	...flex,
	justifyContent: 'center',
	alignItems: 'center',
};

export const flexColumnCenter: SxProps<Theme> = {
	...flexColumn,
	...flexCenter,
};

export const flexRowCenter: SxProps<Theme> = {
	...flexRow,
	...flexCenter,
};

export const flexRowItemsCenter: SxProps<Theme> = {
	...flexRow,
	...flexItemsCenter,
};

export const flexRowCenterWithMobileBreakpoint: SxProps<Theme> = [
	flexRowCenter,
	({ breakpoints }) => ({ [breakpoints.down('sm')]: flexColumnCenter }),
];
