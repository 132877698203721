import { SxProps, Theme } from '@mui/material';

export const qrScannerStyles = {
  fullScreenContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: 1300,
    padding: '10px',
  } as SxProps<Theme>,
  qrReaderContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SxProps<Theme>,
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px',
  } as React.CSSProperties,
  title: {
    position: 'absolute',
    top: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#ffffff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '5px 10px',
    borderRadius: '5px',
  } as SxProps<Theme>,
  buttonContainer: {
    position: 'absolute',
    bottom: '30px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  } as SxProps<Theme>,
  cancelButton: {
    color: '#9D9D9D',
    borderColor: '#9D9D9D',
  } as SxProps<Theme>,
  flashButton: {
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme => theme.colors.white,
    boxShadow: 1,
  } as SxProps<Theme>,
  infoButton: {
    position: 'absolute',
    top: '26px',
    left: '15px',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme => theme.colors.white
  } as SxProps<Theme>,
  alert: {
    mt: 2,
  } as SxProps<Theme>,
};
