import { LogicalOperatorEnum, LogicalOperatorsList } from './logical-operator.enum';
import { IsIn, IsNotEmpty, IsString, IsUUID } from 'class-validator';

export class ConditionalIdsDto {
	@IsIn(LogicalOperatorsList)
	operator: LogicalOperatorEnum;

	@IsUUID('4', { each: true })
	ids: string[];
}

export class CustomOperatorIdsDto<T = string> {
	@IsString()
	@IsNotEmpty()
	operator: T;

	@IsUUID('4', { each: true })
	ids: string[];
}
