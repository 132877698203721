import React from 'react';
import { CoreCalendar, CoreCalendarProps } from '../core-calendar';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';

type CoreCalendarFieldProps<WithRange extends boolean | undefined = undefined> = CoreCalendarProps<WithRange> &
	CoreFormControlProps;

export const CoreCalendarField = <WithRange extends boolean | undefined = undefined>(
	props: CoreCalendarFieldProps<WithRange>
) => {
	const { label, fullWidth, controlSx, focused, disabled, error, id, helperText, requiredMark, ...calendarProps } =
		props;

	return (
		<CoreFormControl
			disabled={disabled}
			requiredMark={requiredMark}
			error={error}
			fullWidth={fullWidth}
			helperText={helperText}
			label={label}
			controlSx={{
				...controlSx,
				position: 'initial',
				'& .react-datepicker-popper': {
					zIndex: 10,
				},
			}}
			id={id}
			focused={focused}
		>
			<CoreCalendar
				{...calendarProps}
				id={id}
				disabled={disabled}
				inputProps={{
					...calendarProps.inputProps,
					fullWidth,
				}}
			/>
		</CoreFormControl>
	);
};
