import React, { FC, useState } from 'react';
import { Box, Button, Input, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QrScanner from 'qr-scanner';
import {CoreIconButton} from "../../../_core/components/_ui/core-button/core-icon-button.component";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Theme } from '@mui/material';

interface FileUploadScannerProps {
  setQrData: (data: string) => void;
  setError: (error: string | null) => void;
}

export const FileUploadScanner: FC<FileUploadScannerProps> = ({ setQrData, setError }) => {
  const [t] = useTranslation();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      try {
        const qrCodeData = await QrScanner.scanImage(file);
        setQrData(qrCodeData);
        setError(null);
      } catch (err) {
        setError(t('Не вдалося розпізнати QR-код. Спробуйте інше зображення.'));
      }
    }
  };

  return (
    <Box sx ={{
        width: 56,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        boxShadow: 1,
        backgroundColor: Theme=> Theme.colors.white
      }}>
      
      <label htmlFor="file-upload">
        <CoreIconButton color="primary" component="span" sx={{backgroundColor: Theme=> Theme.colors.white}}>
          <Input
            type="file"
            inputProps={{ accept: 'image/*' }}
            onChange={handleFileChange}
            sx={{ display: 'none' }}
            id="file-upload"
          />
          <CloudUploadIcon sx={{fontSize: 30}}/>
        </CoreIconButton>
      </label>
    </Box>
  );
};
