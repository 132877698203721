import React, { FC } from 'react';
import BaseDialog from '../../../_core/components/_ui/base-dialog/base-dialog.component';

interface PermissionDialogProps {
  open: boolean;
  onClose: () => void;
  onRequestPermission: () => void;
}

const PermissionDialog: FC<PermissionDialogProps> = ({ open, onClose, onRequestPermission }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Доступ до камери"
      description="Програма потребує доступу до камери. Натисніть 'Продовжити', щоб надати дозвіл, потім натисніть 'Дозволити' у спливаючому вікні браузера."
      onConfirm={onRequestPermission}
      confirmText="Продовжити"
      cancelText="Скасувати"
    />
  );
};

export default PermissionDialog;
