import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {Box, Grid, Modal, Typography} from '@mui/material';
import {ReceiptFilterStyles} from './receipt-filter.styles';
import {Close} from '../../../_core/constants/icons.constants';
import AccountSelect from "../../../add-transaction/components/account-select/account-select";
import {CoreButton} from "../../../_core/components/_ui/core-button/core-button.component";
import {addedWays} from '../../../_core/constants/receipt.constants';
import {useTranslation} from "react-i18next";
import StoreSelect from '../../../add-transaction/components/store-select/store-select.component';
import {CoreSelect, SelectOption} from '../../../_core/components/_ui/core-select';
import {CoreNumericRange} from '../../../_core/components/_ui/core-numeric-range';
import {useGetMinAndMaxForRangeQuery} from '../../../../store/api/transactions.api';
import {DateRangePicker} from '../../../_core/components/_ui/core-datetime-picker/date-range-picker.component';
import { ReceiptsListFilter } from "libs/models/filters";

interface ReceiptFilterModalProps {
  open: boolean;
  onClose: () => void;
  onApplyFilter: (filters: ReceiptsListFilter) => void;
  onResetFilter: () => void;
}

const initialState = {
  accountId: 'all',
  store: '',
  addedWay: 'all',
  priceRange: { from: 0, to: 0 },
  fromDateAdding: '',
  toDateAdding: '',
  fromDatePurchase: '',
  toDatePurchase: '',
};

export const ReceiptFilterModal: FC<ReceiptFilterModalProps> = ({ open, onClose, onApplyFilter, onResetFilter }) => {
  const { t } = useTranslation();
  const [accountId, setAccountId] = useState<string>('all');
  const [store, setStore] = useState<string>('');
  const [useStore, setUseStore] = useState<boolean>(false);
  const [addedWayEnum, setAddedWayEnum] = useState<string>('all');
  const [priceRange, setPriceRange] = useState<{ from: number; to: number }>({ from: 0, to: 0 });
  const [fromDateAdding, setFromDateAdding] = useState<string>('');
  const [toDateAdding, setToDateAdding] = useState<string>('');
  const [fromDatePurchase, setFromDatePurchase] = useState<string>('');
  const [toDatePurchase, setToDatePurchase] = useState<string>('');
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  const [initialValues, setInitialValues] = useState(initialState);
  const initialLoad = useRef(true);

  const { data: minMaxData } = useGetMinAndMaxForRangeQuery();

  useEffect(() => {
    if (minMaxData) {
      setPriceRange({ from: minMaxData.min, to: minMaxData.max });
      if (initialLoad.current) {
        setInitialValues(prevValues => ({
          ...prevValues,
          priceRange: { from: minMaxData.min, to: minMaxData.max },
        }));
        initialLoad.current = false;
      }
    }
  }, [minMaxData]);

  useEffect(() => {
    validateForm();
  }, [accountId, store, useStore, addedWayEnum, priceRange, fromDateAdding, toDateAdding, fromDatePurchase, toDatePurchase]);

  const handleAccountChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAccountId(event.target.value as string);
  };

  function setError(value: boolean) {
    setUseStore(value);
  }

  const handleAddedWayChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAddedWayEnum(event.target.value as string);
  };

  const handlePriceRangeChange = (values: { from?: number; to?: number }) => {
    setPriceRange({
      from: values.from ?? 0,
      to: values.to ?? (minMaxData?.max ?? 0),
    });
  };

  const addedWayOptions: SelectOption[] = addedWays.map(option => ({
    value: option.value,
    label: t(option.label)
  }));

  const validateForm = () => {
    const isValidDateRange = (fromDate: string, toDate: string) => {
      if ((fromDate && !toDate) || (!fromDate && toDate)) return false;
      if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) return false;
      return true;
    };

    const isFormChanged = () => {
      return accountId !== initialValues.accountId ||
        store !== initialValues.store ||
        addedWayEnum !== initialValues.addedWay ||
        priceRange.from !== initialValues.priceRange.from ||
        priceRange.to !== initialValues.priceRange.to ||
        fromDateAdding !== initialValues.fromDateAdding ||
        toDateAdding !== initialValues.toDateAdding ||
        fromDatePurchase !== initialValues.fromDatePurchase ||
        toDatePurchase !== initialValues.toDatePurchase;
    };

    const isFormValid = isValidDateRange(fromDateAdding, toDateAdding) && isValidDateRange(fromDatePurchase, toDatePurchase) && isFormChanged();
    setIsSaveDisabled(!isFormValid);
  };

  const handleReset = () => {
    setAccountId('all');
    setStore('');
    setAddedWayEnum('all');
    setPriceRange({ from: minMaxData?.min ?? 0, to: minMaxData?.max ?? 0 });
    setFromDateAdding('');
    setToDateAdding('');
    setFromDatePurchase('');
    setToDatePurchase('');
    setIsSaveDisabled(true);
    onResetFilter();
    onClose();
  };

  const handleApply = () => {
    const filters: ReceiptsListFilter = {
      includeProperties: [],
      pageSize: 10,
      page: 0,
      orderedBy: [],
      orderReversed: [],
      ids: [],
      accountId,
      shop: store,
      purchaseDateFrom: fromDatePurchase ? new Date(fromDatePurchase) : null,
      purchaseDateTo: toDatePurchase ? new Date(toDatePurchase) : null,
      creatingDateFrom: fromDateAdding ? new Date(fromDateAdding) : null,
      creatingDateTo: toDateAdding ? new Date(toDateAdding) : null,
      priceFrom: priceRange.from,
      priceTo: priceRange.to,
      addedWayEnum: addedWayEnum
    };
    onApplyFilter(filters);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}
    >
      <Box sx={ReceiptFilterStyles.modal}>
        <Box sx={ReceiptFilterStyles.header}>
          <Typography variant="h6" sx={ReceiptFilterStyles.title}>
            {t('Фільтрування')}
          </Typography>
          <Close onClick={onClose} />
        </Box>
        <Grid container spacing={2} alignItems="center" >
          <Grid item xs={12}>
            <AccountSelect account={accountId} hasAll={true} handleAccountChange={handleAccountChange} />
          </Grid>
          <Grid item xs={12}>
            <CoreSelect
              id="addedWay-select"
              fullWidth
              value={addedWayEnum}
              label={t('Спосіб додавання')}
              onChange={handleAddedWayChange}
              placeholder={t('Оберіть спосіб додавання')}
              options={addedWayOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <StoreSelect store={store} setStore={setStore} setError={setError} isLoading={false} />
          </Grid>
          <Grid item xs={12}>
            <CoreNumericRange
              label="Ціна"
              fullWidth
              currencySymb={'₴'}
              numberFrom={priceRange.from}
              numberTo={priceRange.to}
              onValueChange={handlePriceRangeChange}
              max={minMaxData?.max ?? 100000}
              step={2}
              withSlider
              minSliderDistance={100}
              maxSliderValue={minMaxData?.max ?? 100000}
              sliderColor={'success'}
              positionStatic
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangePicker
              id={'purchase'}
              label="Дата покупки"
              fromDate={fromDatePurchase}
              toDate={toDatePurchase}
              onFromDateChange={setFromDatePurchase}
              onToDateChange={setToDatePurchase}
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangePicker
              id={'adding'}
              label="Дата додавання"
              fromDate={fromDateAdding}
              toDate={toDateAdding}
              onFromDateChange={setFromDateAdding}
              onToDateChange={setToDateAdding}
            />
          </Grid>
        </Grid>
        <Box sx={ReceiptFilterStyles.buttonsContainer}>
          <CoreButton variant={'secondary'} onClick={handleReset} sx={ReceiptFilterStyles.button}>
            Скинути все
          </CoreButton>
          <CoreButton onClick={handleApply} sx={ReceiptFilterStyles.button} disabled={isSaveDisabled}>
            Застосувати
          </CoreButton>
        </Box>
      </Box>
    </Modal>
  );
};
