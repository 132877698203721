import { Box, useTheme } from "@mui/material";
import {FadeSpinner} from "../fade-spinner/index";

const CoreInfiniteListLoader = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				py: 2,
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			<FadeSpinner
				size={34}
				height={10}
				width={4}
				borderRadius={2}
				color={theme.colors.black}
			/>
		</Box>
	);
};

export default CoreInfiniteListLoader;
