import React, { FC, useState, useCallback, useRef, useEffect } from 'react';
import {
  Box, Typography, IconButton, Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAddNewReceiptGptMutation } from "../../../../store/api/transactions.api";
import { NotifyModalProps } from "../../interfaces/notify-modal-props.interface";
import { CoreModal } from "../../../_core/components/_ui/core-modal";
import PermissionDialog from '../../components/dialogs/permission-dialog.component';
import DeniedDialog from '../../components/dialogs/denied-dialog.component';
import { addTransactionByGptStyles } from './add-transaction-by-gpt.styles'
import CloseIcon from "@mui/icons-material/Close";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PhotoLibrary from "@mui/icons-material/PhotoLibrary";
import { CoreButton, CoreIconButton } from "../../../_core/components/_ui/core-button/index";

const AddTransactionByGptPage: FC<NotifyModalProps> = ({ onClose, onOpen, onNotify }) => {
  const [t] = useTranslation();

  const [addNewReceipt, { isLoading }] = useAddNewReceiptGptMutation();
  const [cameraAccess, setCameraAccess] = useState<boolean>(true);
  const [openPermissionDialog, setOpenPermissionDialog] = useState<boolean>(false);
  const [openDeniedDialog, setOpenDeniedDialog] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);
  const [notificationSeverity, setNotificationSeverity] = useState<'success' | 'error'>('success');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cameraInputRef = useRef<HTMLInputElement | null>(null);

  const handleCancelQrReader = useCallback(() => {
    onClose();
    setCameraAccess(true);
    setOpenPermissionDialog(false);
    setOpenDeniedDialog(false);
    setNotificationMessage(undefined);
    setNotificationSeverity('success');
  }, [onClose]);

  const handleRequestPermission = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(() => {
        setCameraAccess(true);
        setOpenPermissionDialog(false);
        cameraInputRef.current?.click();
      })
      .catch(() => {
        setCameraAccess(false);
        setOpenPermissionDialog(false);
        handleCancelQrReader();
      });
  }, [handleCancelQrReader]);

  const handleShowCameraInput = useCallback(() => {
    navigator.permissions.query({ name: 'camera' as PermissionName })
      .then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          setCameraAccess(true);
          cameraInputRef.current?.click();
        } else if (permissionStatus.state === 'denied') {
          setCameraAccess(false);
          setOpenDeniedDialog(true);
        } else {
          setOpenPermissionDialog(true);
        }
      });
  }, []);

  const handleFormSubmit = useCallback(async () => {
    if (!selectedImage) return;
    try {
      await addNewReceipt({ fileName: 'some_name', base64Image: selectedImage }).unwrap();
      onNotify('Відбувається процес додавання', 'success');
      onClose();
    } catch (error) {
      onNotify('Виникли проблеми', 'error');
      onClose();
    }
  }, [addNewReceipt, onNotify, onClose, selectedImage]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenFileInput = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (!onOpen) {
      setSelectedImage(null);
      setCameraAccess(true);
      setOpenPermissionDialog(false);
      setOpenDeniedDialog(false);
    }
  }, [onOpen]);

  return (
    <CoreModal
      modalTitle={'Додати покупку'}
      titleSx={addTransactionByGptStyles.modalHeader}
      open={onOpen}
      bodySx={{ p: 0 }}
      hideCloseIcon
      onClose={handleCancelQrReader}
      dialogSx={{ px: 0 }}
      paperSx={{ height: '100%', m: 0, pt: 0 }}>
      <Box sx={addTransactionByGptStyles.container}>
        <PermissionDialog
          open={openPermissionDialog}
          onClose={handleCancelQrReader}
          onRequestPermission={handleRequestPermission}
        />
        <DeniedDialog
          open={openDeniedDialog}
          onClose={handleCancelQrReader}
        />
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleImageUpload}
        />
        <input
          type="file"
          accept="image/*"
          capture="environment"
          style={{ display: 'none' }}
          ref={cameraInputRef}
          onChange={handleImageUpload}
        />
        <Box sx={addTransactionByGptStyles.imageContainer}>
          {selectedImage ? (
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
          ) : (
            <Box sx={addTransactionByGptStyles.placeholder}>
              <Typography variant="body1">Тут має бути зображення вашого чеку</Typography>
            </Box>
          )}
        </Box>
        {selectedImage ? (
          <CoreButton
            aria-label="remove"
            onClick={() => setSelectedImage(null)}
            variant={'danger'}
            sx={{ mt: 2 }}
          >
            <Typography noWrap>
              {`Видалити зображення`}
            </Typography>
          </CoreButton>
        ) : (
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Button
              variant="outlined"
              onClick={handleShowCameraInput}
              startIcon={<PhotoCamera />}
              sx={addTransactionByGptStyles.addButton}
            >
              Зробити фото
            </Button>
            <Button
              variant="outlined"
              onClick={handleOpenFileInput}
              startIcon={<PhotoLibrary />}
              sx={addTransactionByGptStyles.addButton}
            >
              Вибрати з галереї
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={addTransactionByGptStyles.footer}>
        <CoreIconButton
          aria-label="close"
          onClick={onClose}
          sx={addTransactionByGptStyles.closeButton}
        >
          <CloseIcon />
        </CoreIconButton>
        <CoreButton
          aria-label="save"
          onClick={handleFormSubmit}
          sx={addTransactionByGptStyles.saveButton}
          disabled={!selectedImage || isLoading}
        >
          <Typography noWrap>
            {`Зберегти`}
          </Typography>
        </CoreButton>
      </Box>
    </CoreModal>
  );
};

export default React.memo(AddTransactionByGptPage);
