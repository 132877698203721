import { SxProps, Theme } from '@mui/material';

export const addTransactionByGptStyles = {
  modalHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    borderBottom: '1px solid #e0e0e0',
    pb: 2,
    textAlign: 'center'
  } as SxProps<Theme>,
  container: {
    pt: 3,
    px: 2,
    pb: 2,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as SxProps<Theme>,
  imageContainer: {
    width: '100%',
    maxWidth: 300,
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
    marginBottom: 2,
  } as SxProps<Theme>,
  placeholder: {
    color: '#b0b0b0',
  } as SxProps<Theme>,
  addButton: {
    marginTop: 1,
    marginBottom: 1,
    width: '90%',
  } as SxProps<Theme>,
  footer: {
    zIndex: 9,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    borderTop: '1px solid #e0e0e0',
    px: 1,
    py: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  } as SxProps<Theme>,
  closeButton: {
    ml: 1,
    backgroundColor: theme => theme.colors.darkGray,
    color: theme => theme.colors.white,
    '&:hover': {
      backgroundColor: theme => theme.colors.gray,
    },
  } as SxProps<Theme>,
  saveButton: {
    width: '85%',
    mr: 1,
  } as SxProps<Theme>,
};
