import { InputBaseProps, SxProps, Theme, TooltipProps } from '@mui/material';
import { Ref, forwardRef } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';
import { CoreInput, CoreInputProps } from '../core-input';

export interface CoreTextFieldProps extends CoreFormControlProps, InputBaseProps, CoreInputProps {
	wrapperRef?: Ref<unknown>;
	labelSx?: SxProps<Theme>;
	placementLabelHint?: TooltipProps['placement'];
	tooltipSx?: SxProps<Theme>;
	labelHintIconSx?: SxProps<Theme>;
}

export const CoreTextField = forwardRef<unknown, CoreTextFieldProps>((props, ref) => {
	const {
		id,
		fullWidth,
		controlSx,
		label,
		helperText,
		error,
		disabled,
		focused,
		inputRef,
		wrapperRef,
		requiredMark,
		labelSx,
		labelHint,
		tooltipSx,
		placementLabelHint,
		labelHintIconSx,
		...inputProps
	} = props;

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			labelSx={labelSx}
			id={id}
			requiredMark={requiredMark}
			focused={focused}
			fullWidth={fullWidth}
			controlSx={controlSx}
			labelHint={labelHint}
			tooltipSx={tooltipSx}
			labelHintIconSx={labelHintIconSx}
			placementLabelHint={placementLabelHint}
		>
			<CoreInput {...inputProps} id={id} ref={wrapperRef} inputRef={inputRef ?? ref} />
		</CoreFormControl>
	);
});

CoreTextField.displayName = 'CoreTextField';
