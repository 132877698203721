import React, { useCallback, HTMLAttributes, useState, useMemo } from 'react';
import {
	Autocomplete,
	AutocompleteRenderInputParams,
	AutocompleteRenderOptionState,
	AutocompleteRenderGetTagProps,
	AutocompleteProps,
	AutocompleteValue,
	SxProps,
	Theme,
	InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CoreTextFieldProps } from '../core-textfield';
import { Option } from './core-autocomplete.types';
import { coreAutocompleteStyles } from './core-autocomplete.styles';
import { selectPaperStyles } from '../core-select/core-select.styles';
import { Close, ExpandMoreRounded } from '../../../constants/icons.constants';
import { CoreSelectMenuItem } from '../core-select/core-select-menu-item.component';
import { CoreAutocompleteChip } from './core-autocomplete-chip.component';
import { difference, flatten } from '../../../../../utils/lodash.utils';
import { CoreAutocompleteInput } from './core-autocomplete-input.component';

type TextFieldProps = Pick<
	CoreTextFieldProps,
	'disabled' | 'fullWidth' | 'label' | 'helperText' | 'error' | 'controlSx' | 'placeholder' | 'requiredMark' | 'id'
>;

interface CoreAutocompleteProps<Multiple extends boolean | undefined, DisableClearable extends boolean | undefined>
	extends TextFieldProps,
		Pick<
			AutocompleteProps<Option, Multiple, DisableClearable, false>,
			'value' | 'onChange' | 'multiple' | 'disableClearable' | 'disableCloseOnSelect' | 'loading' | 'noOptionsText'
		> {
	sx?: SxProps<Theme>;
	options: Option[];
	allOption?: Option;
	showSelectedCount?: boolean;
	hideInput?: boolean;
	startAdornment?: React.ReactNode;
	onBlur?(): void;
	onOpen?(): void;
	onClose?(): void;
	onInputChange?(value: string): void;
	renderOption?(option: Option): React.ReactNode;
	renderTags?(tagValues: Option[], getTagProps: AutocompleteRenderGetTagProps): React.ReactNode;
}

export const CoreAutocomplete = <Multiple extends boolean | undefined, DisableClearable extends boolean | undefined>(
	props: CoreAutocompleteProps<Multiple, DisableClearable>
) => {
	const {
		sx,
		onBlur,
		options,
		allOption,
		disabled,
		fullWidth,
		label,
		helperText,
		error,
		controlSx,
		placeholder,
		id,
		loading,
		disableClearable,
		onOpen,
		multiple,
		onChange,
		disableCloseOnSelect,
		value,
		requiredMark,
		noOptionsText,
		onClose,
		showSelectedCount,
		onInputChange,
		renderOption: outerRenderOption,
		renderTags,
		hideInput,
		startAdornment,
	} = props;

	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	const extendedOptions = useMemo(() => [...(allOption ? [allOption] : []), ...options], [allOption, options]);

	const allOptionsSelected = useMemo(
		() =>
			difference(
				options.map((x) => x.value),
				flatten(value ? [value] : []).map((x) => x.value)
			).length === 0,
		[options, value]
	);

	const handleAllOptionClick = useCallback(
		(e: React.SyntheticEvent) => {
			if (!multiple) {
				return;
			}

			if (allOptionsSelected) {
				onChange?.(e, [] as Array<Option> as AutocompleteValue<Option, Multiple, DisableClearable, false>, 'clear');
			} else {
				onChange?.(e, options as AutocompleteValue<Option, Multiple, DisableClearable, false>, 'selectOption');
			}
		},
		[multiple, allOptionsSelected, onChange, options]
	);

	const handleOpen = useCallback(() => {
		setOpen(true);
		onOpen?.();
	}, [onOpen]);

	const handleClose = useCallback(() => {
		setOpen(false);
		onClose?.();
	}, [onClose]);

	const renderInput = useCallback(
		(inputParams: AutocompleteRenderInputParams) => (
			<CoreAutocompleteInput
				{...inputParams}
				label={label}
				error={error}
				controlSx={controlSx}
				sx={sx}
				showSelectedCount={showSelectedCount}
				value={value}
				requiredMark={requiredMark}
				placeholder={placeholder}
				loading={loading}
				helperText={helperText}
				disabled={disabled ?? false}
				onInputChange={onInputChange}
				hide={hideInput}
				startAdornment={startAdornment}
				InputProps={{
					...inputParams.InputProps,
					endAdornment: inputParams.InputProps.endAdornment,
					startAdornment: (
						<>
							{startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>}
							{inputParams.InputProps.startAdornment}
						</>
					),
				}}
			/>
		),
		[
			label,
			error,
			controlSx,
			sx,
			showSelectedCount,
			value,
			requiredMark,
			placeholder,
			loading,
			helperText,
			disabled,
			onInputChange,
			hideInput,
			startAdornment,
		]
	);

	const renderOption = useCallback(
		(props: HTMLAttributes<HTMLLIElement>, option: Option, state: AutocompleteRenderOptionState) => {
			const { selected } = state;
			const { label, value } = option;
			const isAllOption = value === allOption?.value;

			return (
				<CoreSelectMenuItem
					{...props}
					key={value}
					selected={isAllOption ? allOptionsSelected : selected}
					withCheckbox={multiple}
					noWrap
					onClick={isAllOption ? handleAllOptionClick : props.onClick}
				>
					{outerRenderOption ? outerRenderOption(option) : label}
				</CoreSelectMenuItem>
			);
		},
		[outerRenderOption, allOption?.value, allOptionsSelected, multiple, handleAllOptionClick]
	);

	const renderTag = useCallback(
		(tagValues: Option[], getTagProps: AutocompleteRenderGetTagProps) =>
			showSelectedCount
				? undefined
				: renderTags?.(tagValues, getTagProps) ??
				  tagValues.map((option, index) => {
						const props = getTagProps({ index });

						return <CoreAutocompleteChip label={option.label} {...props} key={props.key} />;
				  }),
		[renderTags, showSelectedCount]
	);

	const isOptionEqualToValue = useCallback((option: Option, value: Option) => option.value === value.value, []);

	return (
		<Autocomplete
			id={id}
			open={open}
			onBlur={onBlur}
			onOpen={handleOpen}
			value={value}
			isOptionEqualToValue={isOptionEqualToValue}
			multiple={multiple}
			onChange={onChange}
			disableCloseOnSelect={disableCloseOnSelect}
			onClose={handleClose}
			loadingText={t('loading')}
			noOptionsText={noOptionsText || t('autocomplete.no-options')}
			disabled={disabled}
			sx={coreAutocompleteStyles}
			fullWidth={fullWidth}
			renderOption={renderOption}
			slotProps={{
				paper: {
					sx: selectPaperStyles,
				},
			}}
			renderTags={renderTag}
			renderInput={renderInput}
			options={extendedOptions}
			forcePopupIcon
			disableClearable={disableClearable}
			loading={loading}
			clearIcon={
				<Close
					style={{
						color: disabled ? 'rgba(0, 0, 0, 0.26)' : '',
					}}
				/>
			}
			popupIcon={
				<ExpandMoreRounded
					style={{
						color: disabled ? 'rgba(0, 0, 0, 0.26)' : '',
					}}
				/>
			}
		/>
	);
};
