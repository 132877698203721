import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Box, ClickAwayListener, OutlinedInputProps, Slider, SxProps, Theme } from '@mui/material';
import { NumericFormat, useNumericFormat } from 'react-number-format';
import { NumberFormatValues, NumericFormatProps, SourceInfo } from 'react-number-format/types/types';
import { useTranslation } from 'react-i18next';
import { NumericInputWrapperComponent } from '../core-numeric-field';
import { CoreInput, CoreInputProps } from '../core-input';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';

export type CoreNumericRangeProps = Omit<
	NumericFormatProps<OutlinedInputProps & CoreInputProps>,
	'label' | 'onValueChange'
> &
	CoreFormControlProps & {
		numberFrom?: number;
		numberTo?: number;
		currencySymb?: string;
		positionStatic?: boolean;
		withSlider?: boolean;
		step?: number;
		maxSliderValue?: number;
		minSliderDistance?: number;
		sliderColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
		errorFrom?: boolean;
		errorTo?: boolean;
		sliderSx?: SxProps<Theme>;
		onValueChange?(values: { from?: number; to?: number }): void;
	};

export const CoreNumericRange: FC<CoreNumericRangeProps> = (props) => {
	const {
		id,
		currencySymb,
		thousandSeparator = ' ',
		decimalSeparator = '.',
		numberFrom,
		numberTo,
		maxSliderValue = 100,
		minSliderDistance = 1,
		label,
		fullWidth,
		controlSx,
		focused,
		disabled,
		error,
		helperText,
		requiredMark,
		onValueChange,
		positionStatic,
		withSlider,
		step,
		sliderColor,
		errorFrom,
		errorTo,
		sliderSx,
		...numericInputProps
	} = props;

	const [open, setOpen] = useState(false);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const [t] = useTranslation();

	const { value: fromValue, ...fromFormat } = useNumericFormat({
		...numericInputProps,
		value: numberFrom,
		thousandSeparator,
		decimalSeparator,
		step,
	});

	const { value: toValue, ...toFormat } = useNumericFormat({
		...numericInputProps,
		value: numberTo,
		thousandSeparator,
		decimalSeparator,
		step,
	});

	const sliderValues = useMemo(() => {
		if (typeof numberFrom === 'number' && typeof numberTo === 'number') {
			return [numberFrom, numberTo];
		}

		return [0, maxSliderValue];
	}, [maxSliderValue, numberFrom, numberTo]);

	const handlePriceRangeChange = useCallback(
		(field: 'from' | 'to', values: NumberFormatValues, info: SourceInfo) => {
			if (field === 'from') {
				onValueChange?.({
					from: values.floatValue,
					to: numberTo,
				});
				fromFormat.onValueChange?.(values, info);
			} else {
				onValueChange?.({
					to: values.floatValue,
					from: numberFrom,
				});
				toFormat.onValueChange?.(values, info);
			}
		},
		[onValueChange, numberFrom, fromFormat, numberTo, toFormat]
	);

	const handleSliderChange = useCallback(
		(event: unknown, newValue: number | number[], activeThumb: number) => {
			if (!Array.isArray(newValue)) {
				return;
			}

			const changeEvent = event as ChangeEvent<HTMLInputElement>;

			const [from, to] = newValue as number[];

			if (activeThumb === 0) {
				const numberFrom = Math.min(from, to - minSliderDistance);
				const numberTo = to;

				changeEvent.target.value = String(numberFrom);
				fromFormat.onChange?.(changeEvent);

				onValueChange?.({
					from: numberFrom,
					to: numberTo,
				});
			} else if (activeThumb === 1) {
				const numberFrom = from;
				const numberTo = Math.max(to, from + minSliderDistance);

				changeEvent.target.value = String(numberTo);
				toFormat.onChange?.(changeEvent);

				onValueChange?.({
					from: numberFrom,
					to: numberTo,
				});
			}
		},
		[fromFormat, minSliderDistance, onValueChange, toFormat]
	);

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Box
				sx={{
					position: 'relative',
				}}
				onClick={handleOpen}
			>
				<CoreFormControl
					disabled={disabled}
					requiredMark={requiredMark}
					error={error || errorFrom || errorTo}
					fullWidth={fullWidth}
					helperText={helperText}
					label={label}
					id={id}
					focused={focused}
					controlSx={controlSx}
				>
					<CoreInput
						id={id}
						sx={{
							display: positionStatic ? 'none' : 'initial',
						}}
						inputProps={{
							style: {
								cursor: 'pointer',
							},
						}}
						fullWidth
						value={`${currencySymb ?? ''}${fromValue} - ${currencySymb ?? ''}${toValue}`}
					/>
				</CoreFormControl>
				{(open || positionStatic) && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							columnGap: '8px',
							position: positionStatic ? 'static' : 'absolute',
							top: '100%',
							pt: positionStatic ? '0px' : '10px',
							zIndex: '100',
							backgroundColor: 'white',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '8px',
							}}
						>
							<NumericFormat
								{...fromFormat}
								value={fromValue}
								onValueChange={(values, info) => {
									handlePriceRangeChange('from', values, info);
								}}
								placeholder={t('від')}
								decimalSeparator={decimalSeparator}
								thousandSeparator={thousandSeparator}
								customInput={NumericInputWrapperComponent}
								endAdornment={currencySymb}
								error={errorFrom}
								sx={{
									flexGrow: '1',
								}}
							/>
							<Box
								sx={{
									fontSize: '14px',
									fontWeight: '500',
								}}
							>
								-
							</Box>
							<NumericFormat
								{...toFormat}
								value={toValue}
								onValueChange={(values, info) => {
									handlePriceRangeChange('to', values, info);
								}}
								placeholder={t('до')}
								decimalSeparator={decimalSeparator}
								thousandSeparator={thousandSeparator}
								customInput={NumericInputWrapperComponent}
								endAdornment={currencySymb}
								error={errorTo}
								sx={{
									flexGrow: '1',
								}}
							/>
						</Box>
						{withSlider && (
							<Box
								sx={{
									mx: 1,
									maxHeight: '25px',
									...sliderSx,
								}}
							>
								<Slider
									sx={{
										'& .MuiSlider-thumb': {
											backgroundColor: (theme) => theme.colors.gray,
											border: 'none',
											width: '16px',
											height: '16px',

											'&:before': {
												backgroundColor: 'transparent',
												boxShadow: 'none',
												border: 'none',

												'&:hover': {
													backgroundColor: 'transparent',
												},
											},
										},

										'& .MuiSlider-track': {
											backgroundColor: (theme) => theme.colors.lightBlue,
											height: '2px',
										},
									}}
									color={sliderColor}
									getAriaLabel={() => 'Number range'}
									value={sliderValues}
									min={0}
									max={maxSliderValue}
									onChange={handleSliderChange}
									valueLabelDisplay="off"
									disableSwap
									getAriaValueText={(value) => `${value}`}
								/>
							</Box>
						)}
					</Box>
				)}
			</Box>
		</ClickAwayListener>
	);
};
