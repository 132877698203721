import { Box, SxProps, Theme, TooltipProps, Typography } from '@mui/material';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Info } from '../../../constants/icons.constants';
import { CoreTooltip } from '../core-tooltip';

interface ExclamationTooltipProps {
	title?: string;
	iconSx?: SxProps<Theme>;
	tooltipSx?: SxProps<Theme>;
	placement?: TooltipProps['placement'];
}

export const ExclamationTooltip: FC<ExclamationTooltipProps> = (props) => {
	const { title, placement, iconSx, tooltipSx } = props;

	return (
		<CoreTooltip
			arrow
			placement={placement}
			tooltipSx={tooltipSx}
			title={
				<Trans i18nKey={title} components={[<Typography key="0" sx={{ color: (theme) => theme.colors.danger }} />]} />
			}
		>
			<Box
				component={Info}
				sx={{
					ml: 0.5,
					width: 12,
					height: 12,
					...iconSx,
				}}
			/>
		</CoreTooltip>
	);
};
