import { SxProps, Theme } from '@mui/material';

export const receiptItemStyles = {
  paper: {
    p: 2,
    backgroundColor: 'background.paper',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  divider: {
    margin: '8px 0'
  },
  infoText: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  accountContainer: {
    mt: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  amountText: {
    marginLeft: '8px',
    fontWeight: 'bold',
    color: (theme: Theme) => theme.colors.darkGray,
  } as SxProps<Theme>,
};