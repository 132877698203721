import React, { forwardRef, useMemo } from 'react';
import { CoreInput, CoreInputProps } from '../core-input/core-input.component';
import { InputAdornment, OutlinedInputProps } from '@mui/material';
import { Calendar } from '../../../constants/icons.constants';
import { coreCalendarInputStyles } from './core-calendar.styles';

export type CalendarInputProps = CoreInputProps &
	OutlinedInputProps & {
		clearable?: string;
	};

export const CalendarInput = forwardRef<HTMLInputElement, CalendarInputProps>((props, ref) => {
	const { value, sx, clearable, endAdornment } = props;

	const allowClear = useMemo(() => clearable === 'true' && value, [clearable, value]);

	return (
		<CoreInput
			{...props}
			autoComplete="off"
			sx={coreCalendarInputStyles(value, sx)}
			inputRef={ref}
			endAdornment={
				<InputAdornment position="end">
					{allowClear ? <></> : endAdornment ?? <Calendar style={{ marginTop: '-2px', cursor: 'pointer' }} />}
				</InputAdornment>
			}
		/>
	);
});

CalendarInput.displayName = 'CalendarInput';
