import React, { FC, useCallback, useEffect, useState } from 'react';
import {Container, Typography, Box, AppBar, Toolbar, IconButton, Chip, CircularProgress, Snackbar} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReceiptListDto, addedWay } from "libs/models/dto/receipts-list.dto";
import { ReceiptsListFilter } from "libs/models/filters";
import { useGetFilteredReceiptsListMutation } from "../../../../store/api/transactions.api";
import CoreInfiniteList from '../../../_core/components/_ui/core-infinite-list/core-infinite-list.component';
import CoreInfiniteListEmpty from '../../../_core/components/_ui/core-infinite-list/core-infinite-list-empty.component';
import {ReceiptItem} from './receipt-list-item';
import { receiptsListPageStyles } from './receipts-list-page.styles';
import {
  AddReceiptSDA
} from "../../../add-transaction/components/add-transaction-floating-action-button/add-transaction-floating-action-button.component";
import { ReceiptSortModal } from '../../components/receipt-sort/receipt-sort.modal';
import {SortOption, sortOptions} from '../../constants/sort-options.constants';
import {Sort, SortAsc, SortDesc, Filter} from '../../../_core/constants/icons.constants';
import { ReceiptFilterModal } from '../../components/receipts-filter/receipt-filter.modal';
import {addedWays} from '../../../_core/constants/receipt.constants';
import {useFetchUsersData} from "../../../add-transaction/hooks/fetch-account-data.hook";
import MuiAlert, {AlertProps} from "@mui/material/Alert";

const initialFilters: ReceiptsListFilter = {
  includeProperties: [],
  pageSize: 10,
  page: 0,
  orderedBy: [],
  orderReversed: [],
  ids: [],
  accountId: 'all',
  shop: '',
  purchaseDateFrom: null,
  purchaseDateTo: null,
  creatingDateFrom: null,
  creatingDateTo: null,
  priceFrom: 0,
  addedWayEnum: 'all',
  priceTo: 1000000,
};

export const ReceiptsListPage: FC = () => {
  const { t } = useTranslation();
  const [receipts, setReceipts] = useState<ReceiptListDto[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [getFilteredReceiptsList, { isLoading, isError }] = useGetFilteredReceiptsListMutation();
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState<SortOption>(sortOptions[3]);
  const [previousSortOption, setPreviousSortOption] = useState<SortOption>(sortOptions[4]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<ReceiptsListFilter>(initialFilters);
  const [notification, setNotification] = useState<{ message: string, severity: 'success' | 'error' } | null>(null);


  const { accountsList, isListLoading } = useFetchUsersData();

  const AlertComponent = React.forwardRef<HTMLDivElement, AlertProps>(function AlertComponent(
    props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const fetchData = async (currentPage: number, reset: boolean = false) => {
    setLoading(true);

    let addedWay = null;
    if(filters.addedWayEnum == 'manual')
    {
      addedWay = 0
    }
    if(filters.addedWayEnum == 'qrcode')
    {
      addedWay = 1
    }

    const filter: ReceiptsListFilter = {
      ...filters,
      accountId: filters.accountId != 'all' ? filters.accountId: null,
      shop: filters.shop != '' ? filters.shop : null,
      addedWayEnum: addedWay,
      page: currentPage,
      orderedBy: [sortOption.value],
      orderReversed: [sortOption.orderBy === 1 ? 'false' : 'true'],
    };

    try {
      const result = await getFilteredReceiptsList(filter).unwrap();
      if (reset) {
        setReceipts(result.data.list);
      } else {
        setReceipts(prevReceipts => [...prevReceipts, ...result.data.list]);
      }
      setHasMore((result.data.currentPage + 1) * result.data.pageSize < result.data.totalCount);
    } catch (error) {
      console.error('Error fetching receipts', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSuccess = () => {
    setPage(0);
    fetchData(0, true);
    setNotification({message: t('Видалено'), severity: 'success'})
  };

  const handleUpdate = () => {
    setPage(0);
    fetchData(0, true);
  };

  const handleCloseNotification = useCallback(() => {
    setNotification(null);
  }, []);

  const handleEditSuccess = () => {
    setPage(0);
    fetchData(0, true);
    setNotification({message: t('Дані оновлено'), severity: 'success'});
  };

  const getAddedWayLabel = (key:string | null) => {
    return t(addedWays.find(x=>x.value == key)?.label as string);
  }

  const getAccountName = (key:string | null) => {
    return t(accountsList.find(x=>x.id == key)?.name as string);
  }

  useEffect(() => {
    fetchData(0, true);
  }, [filters]);

  useEffect(() => {
    if (sortOption.value !== previousSortOption.value || sortOption.orderBy !== previousSortOption.orderBy) {
      setPreviousSortOption(sortOption);
      setPage(0);
      fetchData(0, true);
    }
  }, [sortOption]);

  const fetchMore = useCallback(() => {
    if (!loading && !isLoading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchData(nextPage);
    }
  }, [loading, isLoading, hasMore, page]);

  const estimateSize = useCallback(() => 150, []);

  const renderItem = useCallback((item: ReceiptListDto) => (
    <ReceiptItem onEditSuccess={handleEditSuccess} item={item} onDeleteSuccess={handleDeleteSuccess} />
  ), [t]);

  const renderSeparator = useCallback(() => <Box sx={{ pt: 1 }} />, []);

  const handleChipClick = () => {
    setSortModalOpen(true);
  };

  const getShortLabel = (value: string) => {
    switch (value) {
      case 'sum':
        return t('ціна');
      case 'dateOfPurchase':
        return  t('дата покупки');
      case 'addedDate':
        return  t('додано');
      default:
        return value;
    }
  };

  const getFilterChips = () => {
    const chips = [];
    if (filters.accountId !== initialFilters.accountId) {
      chips.push({ label: `${t('Аккаунт')}: ${getAccountName(filters.accountId)}`, key: 'accountId' });
    }
    if (filters.shop) {
      chips.push({ label: `${t('Магазин')}: ${filters.shop}`, key: 'shop' });
    }
    if (filters.addedWayEnum && filters.addedWayEnum !== 'all') {
      chips.push({ label: `${t('Спосіб додавання')}: ${getAddedWayLabel(filters.addedWayEnum as string)}`, key: 'addedWay' });
    }
    if (filters.priceFrom !== initialFilters.priceFrom || filters.priceTo !== initialFilters.priceTo) {
      chips.push({ label: `${t('Вартість')}: ₴ ${filters.priceFrom} - ₴ ${filters.priceTo}`, key: 'priceRange' });
    }
    if (filters.purchaseDateFrom || filters.purchaseDateTo) {
      chips.push({ label: `${t('Дата покупки')}: ${filters.purchaseDateFrom?.toLocaleDateString()} - ${filters.purchaseDateTo?.toLocaleDateString()}`, key: 'purchaseDate' });
    }
    if (filters.creatingDateFrom || filters.creatingDateTo) {
      chips.push({ label: `${t('Дата додавання')}: ${filters.creatingDateFrom?.toLocaleDateString()} - ${filters.creatingDateTo?.toLocaleDateString()}`, key: 'addingDate' });
    }
    return chips;
  };

  const handleApplyFilter = (receiptsListFilter: ReceiptsListFilter) => {
    setFilters(receiptsListFilter);
    setPage(0);
  };

  const handleResetFilter = () => {
    setFilters(initialFilters);
    setPage(0);
  };

  return (
    <>
      <AppBar position="sticky" sx={receiptsListPageStyles.appBar}>
        <Toolbar sx={receiptsListPageStyles.header}>
          <Typography variant="h6" sx={receiptsListPageStyles.headerTitle}>
            {t('Список покупок')}
          </Typography>
          <Box sx={receiptsListPageStyles.headerActions}>
            <IconButton color="inherit" onClick={() => setSortModalOpen(true)} sx={receiptsListPageStyles.sortButton}>
              <Sort />
            </IconButton>
            <IconButton color="inherit" onClick={() => setFilterModalOpen(true)}>
              <Filter />
            </IconButton>
          </Box>
        </Toolbar>
        <Box sx={receiptsListPageStyles.chipContainer}>
          <Chip
            label={getShortLabel(sortOption.value)}
            icon={sortOption.orderBy === 1 ? <SortAsc /> : <SortDesc />}
            sx={receiptsListPageStyles.chip}
            onClick={handleChipClick}
          />
          {getFilterChips().map(chip => (
            <Chip
              key={chip.key}
              label={chip.label}
              sx={receiptsListPageStyles.chip}
            />
          ))}
        </Box>
      </AppBar>
      <Container maxWidth="xl" sx={receiptsListPageStyles.container}>
        {loading && (
          <Box sx={receiptsListPageStyles.blurOverlay}>
            <CircularProgress />
          </Box>
        )}
        <Box sx={receiptsListPageStyles.content}>
          <CoreInfiniteList
            items={receipts}
            emptyListComponent={
              <CoreInfiniteListEmpty
                label={t('Схоже що тут пусто...')}
                description={t('Виберіть один із способів та додайте вашу першу покупку :)')}
              />
            }
            loading={loading}
            hasMore={hasMore}
            fetchMore={fetchMore}
            renderSeparator={renderSeparator}
            estimateSize={estimateSize}
            renderItem={renderItem}
          />
        </Box>
        <AddReceiptSDA update={handleUpdate}/>
      </Container>
      <ReceiptSortModal
        open={sortModalOpen}
        onClose={() => setSortModalOpen(false)}
        onSort={(option) => {
          if (option.value !== sortOption.value || option.orderBy !== sortOption.orderBy) {
            setSortOption(option);
            setPage(0);
          }
        }}
        currentSort={sortOption}
      />
      <ReceiptFilterModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        onApplyFilter={handleApplyFilter}
        onResetFilter={handleResetFilter}
      />
      {notification && (
        <Snackbar
          open={!!notification}
          autoHideDuration={3000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <AlertComponent onClose={handleCloseNotification} severity={notification.severity}>
            {notification.message}
          </AlertComponent>
        </Snackbar>
      )}
    </>
  );
};