import { FC, useState, useEffect } from 'react';
import { Container, Typography, Box, ButtonGroup, Button, Paper, Skeleton, Alert, IconButton, Divider, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { useTranslation } from 'react-i18next';
import { AddReceiptSDA } from '../../../add-transaction/components/add-transaction-floating-action-button/add-transaction-floating-action-button.component';
import { useGetTotalSpendsQuery, useGetTotalSpendsByAccountQuery, useGetSpendingTrendQuery, useGetFrequencyOfSpendingQuery } from '../../../../store/api/statistics.api';
import { homePageStyles } from './home-page.styles';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, BarChart, Bar } from 'recharts';
import { TimeFilterEnum } from '../../../../libs/enums/time-filter.enum';
import { useGetFilteredReceiptsListMutation, useRemoveReceiptMutation } from '../../../../store/api/transactions.api';
import { ReceiptsListFilter } from "../../../../libs/models/filters";
import { ReceiptViewModal } from '../../../receipts/components/receipt-view/receipt-view.modal';
import { ReceiptEditModal } from '../../../receipts/components/receipt-edit/receipt-edit.modal';
import BaseDialog from "../../../_core/components/_ui/base-dialog/base-dialog.component";

const initialFilters: ReceiptsListFilter = {
	includeProperties: [],
	pageSize: 5,
	page: 0,
	orderedBy: ['dateOfPurchase'],
	orderReversed: ['true'],
	ids: [],
	accountId: null,
	shop: null,
	purchaseDateFrom: null,
	purchaseDateTo: null,
	creatingDateFrom: null,
	creatingDateTo: null,
	priceFrom: null,
	addedWayEnum: null,
	priceTo: null,
};

//TODO: move to theme
const COLORS = ['#ffa600', '#bc5090', '#003f5c'];

const formatNumber = (num: number): string => {
	if (num >= 1000000) {
		return (num / 1000000).toFixed(1) + 'млн';
	}
	if (num >= 1000) {
		return (num / 1000).toFixed(1) + 'тис';
	}
	return num.toString();
};

export const HomePage: FC = () => {
	const [t] = useTranslation();
	const [period, setPeriod] = useState<TimeFilterEnum>(TimeFilterEnum.Week);
	const { data, isLoading, refetch } = useGetTotalSpendsQuery();
	const { data: dataTotalSpendsByAccount, isLoading: isLoadingTotalSpendsByAccount, refetch: refetchTotalSpendsByAccount } = useGetTotalSpendsByAccountQuery(period);
	const { data: spendingTrendData, isLoading: isLoadingSpendingTrend, refetch: refetchSpendingTrend } = useGetSpendingTrendQuery(period);
	const { data: frequencyOfSpendingData, isLoading: isLoadingFrequencyOfSpending, refetch: refetchFrequencyOfSpending } = useGetFrequencyOfSpendingQuery(period);
	const [getFilteredReceiptsList, { data: receiptsData, isLoading: isLoadingFilteredList, isError }] = useGetFilteredReceiptsListMutation();
	const [removeReceipt] = useRemoveReceiptMutation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [selectedReceiptId, setSelectedReceiptId] = useState<string | null>(null);
	const [selectedReceiptIsTransaction, setSelectedReceiptIsTransaction] = useState<boolean>(false);

	const open = Boolean(anchorEl);

	useEffect(() => {
		handleUpdate();
	}, []);

	useEffect(() => {
		refetch();
		refetchTotalSpendsByAccount();
		refetchSpendingTrend();
		refetchFrequencyOfSpending();
		customRefetchReceiptsList();
	}, [period]);

	const handleUpdate = () => {
		refetch();
		refetchTotalSpendsByAccount();
		refetchSpendingTrend();
		refetchFrequencyOfSpending();
		customRefetchReceiptsList();
	};

	const customRefetchReceiptsList = async () => {
		try {
			await getFilteredReceiptsList(initialFilters).unwrap();
		} catch (error) {
			console.error('Error fetching filtered receipts list', error);
		}
	};

	const getTotalSpends = () => {
		if (isLoading || !data) return 0;

		switch (period) {
			case TimeFilterEnum.Week:
				return data.data.forWeek;
			case TimeFilterEnum.Month:
				return data.data.forMonth;
			case TimeFilterEnum.Last90Days:
				return data.data.for90Days;
			case TimeFilterEnum.Year:
				return data.data.forYear;
			default:
				return 0;
		}
	};

	const shouldShowNoDataMessage = () => {
		if (isLoadingTotalSpendsByAccount) return false;
		if (!dataTotalSpendsByAccount) return true;
		return !dataTotalSpendsByAccount.data.some(x => x.totalSum !== 0);
	};

	const renderCharts = () => {
		if (shouldShowNoDataMessage()) {
			return (
				<Box component={Paper} sx={homePageStyles.paper} mt={1}>
					<Alert severity="info">{t('Даних за цей період не знайдено')}</Alert>
				</Box>
			);
		}

		const formattedData = dataTotalSpendsByAccount?.data
			.filter(account => account.totalSum !== 0)
			.map((account) => ({
				name: t(account.accountName),
				value: parseFloat(account.totalSum.toFixed(2)),
			}));

		return (
			<>
				<Box component={Paper} sx={homePageStyles.paper} mt={1}>
					<Box mt={2} sx={{ width: '100%', height: 300 }}>
						<ResponsiveContainer width="100%" height={300}>
							<PieChart>
								<text x="50%" y={20} fill="black" textAnchor="middle" dominantBaseline="central">
									<tspan fontWeight={'bold'} fontSize="1rem">{t('Розподіл витрат по аккаунтам')}</tspan>
								</text>
								<Pie
									data={formattedData}
									cx="50%"
									cy="50%"
									outerRadius={70}
									fill="#8884d8"
									dataKey="value"
									label={({ name, value }) => `₴${formatNumber(value)}`}
									isAnimationActive={false}
								>
									{formattedData?.map((entry, index) => (
										<Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
									))}
								</Pie>
								<Legend verticalAlign="bottom" height={36} />
							</PieChart>
						</ResponsiveContainer>
					</Box>
				</Box>
				{renderAreaChart(spendingTrendData, isLoadingSpendingTrend, t('Тенденція витрат'))}
				{renderStackedBarChart(frequencyOfSpendingData, isLoadingFrequencyOfSpending, t('Кількість витрат'))}
			</>
		);
	};

	const renderAreaChart = (data: any, isLoading: boolean, chartTitle: string) => {
		if (isLoading) {
			return <Skeleton animation="wave" sx={homePageStyles.skeleton} variant="rectangular" width="100%" height={300} />;
		}

		if (!data || !data.data) return null;

		const formattedData = data.data.map((item: any) => ({
			name: item.name,
			...item.values
		}));

		return (
			<Box component={Paper} sx={homePageStyles.paper} mt={1}>
				<Typography variant="h6" sx={homePageStyles.chartTitle}>{chartTitle}</Typography>
				<ResponsiveContainer width="100%" height={300}>
					<AreaChart data={formattedData}>
						<XAxis dataKey="name" />
						<YAxis />
						<Tooltip />
						<CartesianGrid strokeDasharray="3 3" />
						{formattedData.length && Object.keys(formattedData[0]).filter(key => key !== 'name').map((key, index) => (
							<Area key={key} type="monotone" dataKey={key} stackId="1" stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} name={t(key)} />
						))}
						<Legend verticalAlign="bottom" height={36} />
					</AreaChart>
				</ResponsiveContainer>
			</Box>
		);
	};

	const renderStackedBarChart = (data: any, isLoading: boolean, chartTitle: string) => {
		if (isLoading) {
			return <Skeleton animation="wave" sx={homePageStyles.skeleton} variant="rounded" width="100%" height={300} />;
		}

		if (!data || !data.data) return null;

		const formattedData = data.data.map((item: any) => ({
			name: item.name,
			...item.values
		}));

		return (
			<Box component={Paper} sx={homePageStyles.paper} mt={1}>
				<Typography variant="h6" sx={homePageStyles.chartTitle}>{chartTitle}</Typography>
				<ResponsiveContainer width="100%" height={300}>
					<BarChart data={formattedData}>
						<XAxis dataKey="name" />
						<YAxis />
						<Tooltip />
						<CartesianGrid strokeDasharray="3 3" />
						{formattedData.length && Object.keys(formattedData[0]).filter(key => key !== 'name').map((key, index) => (
							<Bar key={key} dataKey={key} stackId="1" fill={COLORS[index % COLORS.length]} name={t(key)} />
						))}
						<Legend verticalAlign="bottom" height={36} />
					</BarChart>
				</ResponsiveContainer>
			</Box>
		);
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, receiptId: string, isTransaction: boolean) => {
		event.stopPropagation();
		setSelectedReceiptId(receiptId);
		setSelectedReceiptIsTransaction(isTransaction);
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAction = (action: string) => {
		if (action === 'open') {
			setModalOpen(true);
		} else if (action === 'edit') {
			setEditModalOpen(true);
		} else if (action === 'delete') {
			setConfirmOpen(true);
		}
		handleMenuClose();
	};

	const handleDeleteConfirm = async () => {
		try {
			await removeReceipt(selectedReceiptId!).unwrap();
			setConfirmOpen(false);
			handleUpdate();
		} catch (error) {
			console.error('Error deleting receipt', error);
		}
	};

	const renderReceiptsList = () => {
		if (isLoadingFilteredList) {
			return <Skeleton animation="wave" sx={homePageStyles.skeleton} variant="rectangular" width="100%" height={300} />;
		}

		if (!receiptsData || !receiptsData.data || receiptsData.data.list.length === 0) {
			return (
				<Box component={Paper} sx={homePageStyles.paper} mt={1}>
					<Alert severity="info">{t('Немає останніх транзакцій')}</Alert>
				</Box>
			);
		}

		return (
			<Box component={Paper} sx={homePageStyles.transactionContainer} mt={1}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Typography variant="h6" sx={homePageStyles.chartTitle}>{t('Останні транзакції')}</Typography>
					<Button
						component={Link}
						to="/receipts/list"
						variant="outlined"
						size="small"
						endIcon={<ArrowForwardIcon />}
						sx={homePageStyles.showMoreButton}
					>
						{t('Показати більше')}
					</Button>
				</Box>
				{receiptsData.data.list.map((receipt) => (
					<Box key={receipt.id} sx={homePageStyles.transactionItem} onClick={() => { setModalOpen(true); setSelectedReceiptId(receipt.id); setSelectedReceiptIsTransaction(receipt.isTransaction); }}>
						<Box sx={homePageStyles.transactionDetails}>
							<Box>
								<Typography variant="body2" color="textSecondary">{
									new Date(receipt.dateOfPurchase).toLocaleString('uk-UA', {
										year: '2-digit',
										month: 'numeric',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit'
									})}</Typography>
								<Typography variant="body1" fontWeight="bold">{receipt.shopName || t('Невідомо')}</Typography>
							</Box>
							<Box sx={homePageStyles.transactionAmount}>
								<Typography variant="body1" fontWeight="bold" color="primary">₴{receipt.sum.toFixed(2)}</Typography>
								<Typography variant="body2" color="textSecondary">{t(receipt.accountName)}</Typography>
							</Box>
							<IconButton
								aria-label="more"
								aria-controls="long-menu"
								aria-haspopup="true"
								onClick={(e) => handleMenuOpen(e, receipt.id, receipt.isTransaction)}
								size="small"
								sx={homePageStyles.moreButton}
							>
								<MoreVertIcon fontSize="small" />
							</IconButton>
							<Menu
								id="long-menu"
								anchorEl={anchorEl}
								keepMounted
								open={open}
								onClose={handleMenuClose}
								onClick={(e) => e.stopPropagation()}
							>
								<MenuItem onClick={() => handleAction('edit')}>
									<ListItemIcon>
										<EditIcon fontSize="small" />
									</ListItemIcon>
									{t('Змінити')}
								</MenuItem>
								<MenuItem onClick={() => handleAction('delete')}>
									<ListItemIcon>
										<DeleteIcon fontSize="small" />
									</ListItemIcon>
									{t('Видалити')}
								</MenuItem>
							</Menu>
						</Box>
						<Divider sx={{ mt: 1 }} />
					</Box>
				))}
			</Box>
		);
	};

	return (
		<Container sx={homePageStyles.container}>
			<Box component={Paper} sx={homePageStyles.paper}>
				<Typography variant="h5" sx={homePageStyles.title}>{t('Загальна сума витрат')}</Typography>
				<Box sx={homePageStyles.amountContainer}>
					<Typography sx={homePageStyles.amountSymbol}>₴</Typography>
					{isLoading ? (
						<Skeleton animation="wave" variant="text" width={100} />
					) : (
						<Typography sx={homePageStyles.amountValue}>{getTotalSpends().toFixed(2)}</Typography>
					)}
				</Box>
				<ButtonGroup sx={homePageStyles.buttonGroup} variant="outlined" aria-label="outlined primary button group">
					<Button onClick={() => setPeriod(TimeFilterEnum.Week)} variant={period === TimeFilterEnum.Week ? 'contained' : 'outlined'}>
						<Typography fontSize={'10px'}>{t('Тиждень')}</Typography>
					</Button>
					<Button onClick={() => setPeriod(TimeFilterEnum.Month)} variant={period === TimeFilterEnum.Month ? 'contained' : 'outlined'}>
						<Typography fontSize={'10px'}>{t('Місяць')}</Typography>
					</Button>
					<Button onClick={() => setPeriod(TimeFilterEnum.Last90Days)} variant={period === TimeFilterEnum.Last90Days ? 'contained' : 'outlined'}>
						<Typography fontSize={'10px'}>{t('3 місяці')}</Typography>
					</Button>
					<Button onClick={() => setPeriod(TimeFilterEnum.Year)} variant={period === TimeFilterEnum.Year ? 'contained' : 'outlined'}>
						<Typography fontSize={'10px'}>{t('Рік')}</Typography>
					</Button>
				</ButtonGroup>
			</Box>
			{renderCharts()}
			{renderReceiptsList()}
			<Box mt={2}>
				<AddReceiptSDA update={handleUpdate} />
			</Box>
			{selectedReceiptId && (
				<ReceiptViewModal
					open={modalOpen}
					onClose={() => setModalOpen(false)}
					receiptId={selectedReceiptId}
					isTransaction={selectedReceiptIsTransaction}
				/>
			)}
			{selectedReceiptId && (
				<ReceiptEditModal
					open={editModalOpen}
					onClose={() => setEditModalOpen(false)}
					onSuccess={handleUpdate}
					receiptId={selectedReceiptId}
					isTransaction={selectedReceiptIsTransaction}
				/>
			)}
			<BaseDialog
				open={confirmOpen}
				onClose={() => setConfirmOpen(false)}
				title="Видалення"
				description="Ви точно бажаєте видалити покупку?"
				onConfirm={handleDeleteConfirm}
				confirmText="Так"
				cancelText="Ні"
			/>
		</Container>
	);
};
