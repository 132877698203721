import { styled } from "@mui/material/styles";
import { Radio, RadioProps } from "@mui/material";
import { StyledComponent } from "@emotion/styled";

export const CoreRadio: StyledComponent<RadioProps> = styled((props: RadioProps) => (
	<Radio
		{...props}
		disableRipple={false}
	/>
))(
	({ theme }) => ({
		color: theme.colors.gray,

		'&.Mui-focusVisible': {
			color: theme.colors.lightBluePressed
		},

		'&.Mui-disabled': {
			color: theme.colors.blueTransparent
		},

		'&.Mui-checked': {
			color: theme.colors.lightBlue
		}
	})
);
