import React, { FC, useState, useRef } from 'react';
import {
  Container,
  Box,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addTransactionPageStyles } from './add-transaction.styles';
import { CoreModal } from "../../../_core/components/_ui/core-modal";
import { CoreButton, CoreIconButton } from "../../../_core/components/_ui/core-button/index";
import CloseIcon from "@mui/icons-material/Close";
import AddTransactionOutcomePage from '../add-transaction/tabs/add-transaction-outcome.page';
import { NotifyModalProps } from "modules/add-transaction/interfaces/notify-modal-props.interface";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const AddTransactionPage: FC<NotifyModalProps> = ({ onClose, onOpen, onNotify }) => {
  const [t] = useTranslation();
  const [value, setValue] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const outcomePageRef = useRef<{ handleFormSubmit: () => void }>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFormValidation = (isValid: boolean) => {
    setIsFormValid(isValid);
  };

  const handleSaveClick = () => {
    if (value === 0 && outcomePageRef.current) {
      outcomePageRef.current.handleFormSubmit();
    }
  };

  return (
    <CoreModal
      open={onOpen}
      bodySx={{ p: 0 }}
      onClose={onClose}
      hideCloseIcon
      dialogSx={{ px: 0 }}
      paperSx={{ height: '100%', m: 0, pt: 0 }}>
      <Container sx={addTransactionPageStyles.newProduct}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="expense tabs">
            <Tab label={t('Витрата')} {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AddTransactionOutcomePage
            onClose={onClose}
            onNotify={onNotify}
            isOpen={onOpen}
            onValidate={handleFormValidation}
            ref={outcomePageRef}
          />
        </TabPanel>
      </Container>
      <Box sx={addTransactionPageStyles.footer}>
        <CoreIconButton
          aria-label="close"
          onClick={onClose}
          sx={addTransactionPageStyles.closeButton}
        >
          <CloseIcon />
        </CoreIconButton>
        <CoreButton
          aria-label="save"
          onClick={handleSaveClick}
          sx={addTransactionPageStyles.saveButton}
          disabled={!isFormValid}
        >
          <Typography noWrap>
            {`Зберегти`}
          </Typography>
        </CoreButton>
      </Box>
    </CoreModal>
  );
};

export default React.memo(AddTransactionPage);
