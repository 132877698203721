import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const overlayStyles: SxProps<Theme> = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  bgcolor: 'rgba(0, 0, 0, 0.88)',
  zIndex: 999,
};

export const actionContainerStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  padding: '4px 8px'
};

export const modalStyles: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  pb: 0
}

export const typographyAddPurchaseStyles: SxProps<Theme> = {
  flexGrow: 1, 
  textAlign: 'center'
}

export const modalHeaderStyles: SxProps<Theme> = { 
  position: 'sticky',
  height: '8%', 
  top: 0, 
  zIndex: 999, 
  bgcolor: 'background.paper',
  display: 'flex', 
  justifyContent: 'space-between',
  alignItems: 'center', 
  p: 1, 
  borderBottom: '1px solid #ddd' 
}

export const actionSpeedDialogStyles: SxProps<Theme> = {
  justifyContent: "flex-end"
};

export const actionTextStyles: SxProps<Theme> = {
  mr: 3,
  color: "white",
  whiteSpace: "nowrap"
};

export const iconStyles: SxProps<Theme> = {
  color: theme => theme.colors.orangePressed
};

export const speedDialStyles: SxProps<Theme> = () => ({
  position: 'absolute',
  bottom: 16,
  right: 16
});
