import { ReactNode, useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import { useScrollFetcher } from "../../../hooks/use-scroll-fetcher.hook";
import { useVirtualizer } from "@tanstack/react-virtual";
import CoreInfiniteListVirtualRow from "./core-infinite-list-virtual-row.component";
import CoreInfiniteListLoader from "./core-infinite-list-loader.component";

interface CoreInfiniteListProps<TData> {
	items?: TData[];
	loading?: boolean;
	hasMore?: boolean;
	emptyListComponent?: ReactNode;
	threshold?: number;
	paddingStart?: number;
	paddingEnd?: number;
	estimateSize(index: number): number;
	renderItem(item: TData, index: number): ReactNode;
	renderSeparator?(item: TData, index: number): ReactNode;
	fetchMore?(count: number): void;
}

const CoreInfiniteList = <TData extends object>(props: CoreInfiniteListProps<TData>) => {
	const {
		items,
		emptyListComponent,
		loading,
		fetchMore,
		estimateSize,
		hasMore,
		threshold = 100,
		renderItem,
		renderSeparator,
		paddingStart,
		paddingEnd
	} = props;

	const itemsCount = items?.length ?? 0;
	const shouldAttachScrollListener = hasMore && !loading;

	const showEmptyComponent = !loading && itemsCount === 0;

	const { containerRef, onScroll } = useScrollFetcher({
		itemsCount,
		threshold,
		fetchMore
	});

	const { getTotalSize, getVirtualItems, measureElement } = useVirtualizer({
		count: !loading ? itemsCount : itemsCount + 1,
		getScrollElement: () => containerRef.current,
		estimateSize,
		overscan: 5,
		paddingStart,
		paddingEnd,
		measureElement: element => element?.getBoundingClientRect().height,
	});

	const totalSize = getTotalSize();
	const virtualRows = getVirtualItems();

	const [lastScrollTop, setLastScrollTop] = useState(0);
	const fetchingMore = useRef(false);

	const handleScroll = (event: Event) => {
		const target = event.target as HTMLDivElement;
		const scrollTop = target.scrollTop;
		const clientHeight = target.clientHeight;
		const scrollHeight = target.scrollHeight;

		if (!loading && !fetchingMore.current && hasMore && scrollTop + clientHeight + threshold >= scrollHeight) {
			fetchingMore.current = true;
			fetchMore?.(itemsCount);
		}

		if (scrollTop !== lastScrollTop) {
			setLastScrollTop(scrollTop);
		}
	};

	useEffect(() => {
		if (!loading) {
			fetchingMore.current = false;
		}
	}, [loading]);

	useEffect(() => {
		if (containerRef.current) {
			const element = containerRef.current;
			element.addEventListener('scroll', handleScroll);
			return () => element.removeEventListener('scroll', handleScroll);
		}
		return undefined;
	}, [shouldAttachScrollListener, lastScrollTop]);

	return (
		<Box
			ref={containerRef}
			sx={{
				overflow: 'auto',
				flex: 1,
				position: 'relative',
				height: '100%'
			}}
		>
			{!showEmptyComponent && (
				<Box
					sx={{
						height: totalSize,
						position: 'relative'
					}}
				>
					{virtualRows.map(virtualRow => {
						const data = (items as TData[])[virtualRow.index];

						const isLoaderRow = virtualRow.index > itemsCount - 1;
						const isLastRow = (virtualRow.index + 1) === itemsCount;
						const shouldRenderSeparator = !!renderSeparator && !isLastRow;

						return (
							<CoreInfiniteListVirtualRow
								key={virtualRow.key}
								measureElement={measureElement}
								virtualRow={virtualRow}
							>
								{!isLoaderRow && (
									<>
										{renderItem(data, virtualRow.index)}
										{shouldRenderSeparator && renderSeparator?.(data, virtualRow.index)}
									</>
								)}
								{isLoaderRow && <CoreInfiniteListLoader />}
							</CoreInfiniteListVirtualRow>
						);
					})}
				</Box>
			)}
			{showEmptyComponent && emptyListComponent}
		</Box>
	);
};

export default CoreInfiniteList;
