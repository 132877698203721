import { SxProps, Theme } from '@mui/material';

export const addReceiptByScanQrcodeStyles = {
  container: {
    pt: 3,
    px: 1,
    pb: 2,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
  } as SxProps<Theme>,
  alert: {
    mt: 1,
  } as SxProps<Theme>,
  fullScreenContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1300,
  } as SxProps<Theme>,
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SxProps<Theme>,
  modalHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    borderBottom: '1px solid #e0e0e0',
    pb: 2,
    textAlign: 'center'
  } as SxProps<Theme>,
};
