import React, { FC, useState, useCallback, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addReceiptByScanQrcodeStyles } from './add-receipt-by-scan-qrcode.styles';
import QrScannerComponent from "../../components/qr-scanner/qr-scanner";
import { NotifyModalProps } from "modules/add-transaction/interfaces/notify-modal-props.interface";
import { useQrcodeReceiptProcessing } from '../../hooks/use-qrcode-receipt-processing';
import PermissionDialog from '../../components/dialogs/permission-dialog.component';
import DeniedDialog from '../../components/dialogs/denied-dialog.component';
import ConfirmationReceiptDialog from '../../components/dialogs/confirmation-receipt-dialog.component';
import { CoreModal } from "../../../_core/components/_ui/core-modal";

const AddReceiptByScanQrcodePage: FC<NotifyModalProps> = ({ onClose, onOpen, onNotify }) => {
  const { t } = useTranslation();
  const [showQrReader, setShowQrReader] = useState<boolean>(false);
  const [cameraAccess, setCameraAccess] = useState<boolean>(true);
  const [openPermissionDialog, setOpenPermissionDialog] = useState<boolean>(false);
  const [openDeniedDialog, setOpenDeniedDialog] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);
  const [notificationSeverity, setNotificationSeverity] = useState<'success' | 'error'>('success');

  const {
    qrData,
    isDataSaved,
    loading,
    errorMessage,
    openConfirmation,
    handleSetQrData,
    handleFormSubmit,
    handleConfirmationClose,
    handleConfirmationAccept,
    resetState,
    processing,
  } = useQrcodeReceiptProcessing((message, severity) => {
    setNotificationMessage(message || undefined);
    setNotificationSeverity(severity);
    setTimeout(() => {
      setNotificationMessage(undefined);
    }, 2000);
  });

  const handleShowQrReader = useCallback(() => {
    navigator.permissions.query({ name: 'camera' as PermissionName })
      .then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          setCameraAccess(true);
          setShowQrReader(true);
        } else if (permissionStatus.state === 'denied') {
          setCameraAccess(false);
          setOpenDeniedDialog(true);
        } else {
          setOpenPermissionDialog(true);
        }
      });
  }, []);

  const handleCancelQrReader = useCallback(() => {
    onClose();
    resetState();
    setShowQrReader(false);
    setCameraAccess(true);
    setOpenPermissionDialog(false);
    setOpenDeniedDialog(false);
    setNotificationMessage(undefined);
    setNotificationSeverity('success');
  }, [onClose, resetState]);

  const handleRequestPermission = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(() => {
        setCameraAccess(true);
        setShowQrReader(true);
        setOpenPermissionDialog(false);
      })
      .catch(() => {
        setCameraAccess(false);
        setOpenPermissionDialog(false);
        handleCancelQrReader();
      });
  }, [handleCancelQrReader]);

  useEffect(() => {
    if (onOpen) {
      handleShowQrReader();
    } else {
      resetState();
    }
  }, [onOpen, handleShowQrReader, resetState]);

  useEffect(() => {
    if (qrData && !processing) {
      handleFormSubmit();
    }
  }, [qrData, processing]);

  const handleDelayedRestart = () => {
    setTimeout(() => {
      setShowQrReader(true);
    }, 3000);
  };

  return (
    <CoreModal
      modalTitle={'Додати покупку'}
      titleSx={addReceiptByScanQrcodeStyles.modalHeader}
      open={onOpen}
      bodySx={{ p: 0 }}
      hideCloseIcon
      onClose={handleCancelQrReader}
      dialogSx={{ px: 0 }}
      paperSx={{ height: '100%', m: 0, pt: 0 }}>
      <Box sx={addReceiptByScanQrcodeStyles.container}>
        {showQrReader && cameraAccess && (
          <Box sx={addReceiptByScanQrcodeStyles.fullScreenContainer}>
            <QrScannerComponent
              showQrReader={showQrReader}
              handleCancelQrReader={handleCancelQrReader}
              setQrData={handleSetQrData}
              notificationMessage={notificationMessage}
              notificationSeverity={notificationSeverity}
              processing={processing}
            />
            {loading && (
              <Box sx={addReceiptByScanQrcodeStyles.loaderContainer}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}
        <PermissionDialog
          open={openPermissionDialog}
          onClose={handleCancelQrReader}
          onRequestPermission={handleRequestPermission}
        />
        <DeniedDialog
          open={openDeniedDialog}
          onClose={handleCancelQrReader}
        />
        <ConfirmationReceiptDialog
          open={openConfirmation}
          onClose={() => {
            handleConfirmationClose();
            handleDelayedRestart();
          }}
          onConfirm={async () => {
            await handleConfirmationAccept();
            setNotificationMessage(t('Успішно додано'));
            setNotificationSeverity('success');
            setTimeout(() => {
              setNotificationMessage(undefined);
              setShowQrReader(true);
            }, 2000);
          }}
        />
      </Box>
    </CoreModal>
  );
};

export default React.memo(AddReceiptByScanQrcodePage);
