import { FC, useRef, useEffect } from 'react';
import { GSIClientContextProvider } from '../../../contexts/gsi-client.context';
import { googleApiClientId, isGoogleAuthEnabled } from '../../../../../libs/constants';
import {
	AuthRoutes,
	REGISTER_CACHE_KEY,
	SIGN_IN_CACHE_KEY,
	VERIFY_CACHE_KEY,
	authWithGoogleToken,
} from '../../../../../modules/auth/index';

export interface GoogleAuthButtonProps {
	type?: 'standard' | 'icon';
	theme?: 'filled_blue' | 'outline' | 'filled_black';
	size?: 'small' | 'medium' | 'large';
	text?: 'continue_with' | 'signin_with' | 'signup_with';
	shape?: 'rectangular' | 'square' | 'circle' | 'pill';
	logoAlignment?: 'left' | 'center';
	show?: boolean;
	onSuccess?(response: any): void;
	setButtonRef?(ref: Element | null): void;
}

export const GoogleAuthButton: FC<GoogleAuthButtonProps> = ({
																															show = true,
																															type,
																															theme = 'filled_blue',
																															size = 'large',
																															text = 'continue_with',
																															shape = 'rectangular',
																															logoAlignment = 'left',
																															setButtonRef,
																															onSuccess,
																														}) => {
	const buttonRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (typeof window === 'undefined' || !buttonRef.current) {
			return;
		}

		const initializeGoogleAPI = () => {
			if (!buttonRef.current) return;

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const google = (window as any).google;

			// Ensure the Google API client is initialized
			if (google.accounts.id.initialize) {
				google.accounts.id.initialize({
					client_id: googleApiClientId,
					callback: (response: any) => {
						authWithGoogleToken(response);
					},
				});
			}

			google.accounts.id.renderButton(buttonRef.current, {
				type,
				theme,
				size,
				text,
				shape,
				logo_alignment: logoAlignment,
			});

			const divButton = buttonRef.current.querySelector('div[role=button]');
			setButtonRef?.(divButton);
		};

		const loadGoogleAPI = () => {
			const script = document.createElement('script');
			script.src = 'https://accounts.google.com/gsi/client';
			script.async = true;
			script.onload = initializeGoogleAPI;
			document.body.appendChild(script);
		};

		if ((window as any).google && (window as any).google.accounts && (window as any).google.accounts.id) {
			initializeGoogleAPI();
		} else {
			loadGoogleAPI();
		}
	}, [type, theme, size, text, shape, logoAlignment, setButtonRef, onSuccess]);

	if (onSuccess) {
		return (
			<GSIClientContextProvider clientId={googleApiClientId} callback={onSuccess}>
				<div
					id="google-auth-button"
					className="custom-google-button"
					ref={buttonRef}
					style={{
						...(show ? {} : { display: 'none' }),
					}}
				></div>
			</GSIClientContextProvider>
		);
	}

	return (
		<div
			id="google-auth-button"
			className="custom-google-button"
			ref={buttonRef}
			style={{
				...(show ? {} : { display: 'none' }),
			}}
		/>
	);
};
