export class ReceiptListDto {
  id: string;
  shopName?: string;
  accountName: string;
  sum: number;
  address?: string;
  dateOfPurchase: string;
  addedDate: string;
  isTransaction: boolean;
  addedWay: addedWay;
}

export enum addedWay {
  manual,
  byQRCode,
  gpt
}
