import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { CoreIconButton } from '../core-button/core-icon-button.component';
import { ChevronRight, ChevronLeft } from '../../../constants/icons.constants';
import { format } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';
import {
	calendarHeaderButtonLeftStyles,
	calendarHeaderButtonRightStyles,
	calendarHeaderDateTextStyles,
	coreCalendarHeaderContainerStyles,
} from './core-calendar.styles';
import { LangCodesEnum } from '../../../../../libs/enums/lang.enum';

interface CalendarHeaderProps {
	prevMonthButtonDisabled: boolean;
	nextMonthButtonDisabled: boolean;
	date: Date;
	locale?: string;
	increaseMonth(): void;
	decreaseMonth(): void;
}

export const CalendarHeader: FC<CalendarHeaderProps> = memo((props) => {
	const { prevMonthButtonDisabled, increaseMonth, nextMonthButtonDisabled, decreaseMonth, date, locale } = props;

	return (
		<Box sx={coreCalendarHeaderContainerStyles}>
			<CoreIconButton sx={calendarHeaderButtonLeftStyles} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
				<ChevronLeft />
			</CoreIconButton>
			<CoreIconButton sx={calendarHeaderButtonRightStyles} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
				<ChevronRight />
			</CoreIconButton>
			<Typography variant="body1" sx={calendarHeaderDateTextStyles}>
				{format(date, 'LLLL yyyy', {
					locale: locale === LangCodesEnum.EN ? en : uk,
				})}
			</Typography>
		</Box>
	);
});

CalendarHeader.displayName = 'CalendarHeader';
