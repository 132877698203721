import { clsx } from '../../../utils/style.utils';
import { Box, Dialog, DialogProps, ModalProps, SxProps, Theme, Typography, TypographyOwnProps } from '@mui/material';
import { FC, ReactNode, useCallback } from 'react';
import { Close } from '../../../constants/icons.constants';
import { CoreIconButton } from '../core-button';
import { CoreLoadingWall } from '../core-loading-wall';
import { closeIconStyles, containerStyles, loaderWrapperStyles, scrollWrapperStyles } from './core-modal.styles';

export interface CoreModalProps extends DialogProps {
	containerSx?: SxProps<Theme>;
	titleSx?: SxProps<Theme>;
	bodySx?: SxProps<Theme>;
	paperSx?: SxProps<Theme>;
	dialogSx?: SxProps<Theme>;
	headerComponent?: ReactNode;
	modalTitle?: string | null;
	modalTitleVariant?: TypographyOwnProps['variant'];
	closeDisabled?: boolean;
	loading?: boolean;
	modalWidth?: number;
	modalHeight?: number;
	hideCloseIcon?: boolean;
	closeIconSx?: SxProps<Theme>;

	/**
	 * Called before canceling the modal to determine if it's allowed
	 */
	beforeClose?(): boolean;
}

export const CoreModal: FC<CoreModalProps> = (props) => {
	const {
		headerComponent,
		loading,
		closeDisabled,
		modalTitle,
		children,
		containerSx,
		onClose,
		bodySx,
		modalWidth = 700,
		modalHeight = 700,
		hideCloseIcon,
		paperSx,
		dialogSx,
		titleSx,
		closeIconSx,
		modalTitleVariant,
		...modalProps
	} = props;

	const handleClose = useCallback(() => {
		onClose?.({}, 'escapeKeyDown');
	}, [onClose]);

	const dialogClose = useCallback<NonNullable<ModalProps['onClose']>>(
		(event, action) => {
			if (closeDisabled) {
				return;
			}

			onClose?.(event, action);
		},
		[onClose, closeDisabled]
	);

	return (
		<Dialog
			{...modalProps}
			onClose={dialogClose}
			fullWidth
			aria-labelledby={modalTitle ?? undefined}
			sx={clsx(
				[
					{
						'& .MuiDialog-container': {
							alignItems: 'center',
						},
					},
					({ breakpoints }) => ({
						[breakpoints.down('sm')]: {
							'& .MuiDialog-container': {
								alignItems: 'flex-end',
							},
						},
					}),
				],
				dialogSx
			)}
			PaperProps={{
				sx: [
					{ width: '100%',
						minHeight: '100%',
						borderRadius: 0
					},
					...(Array.isArray(paperSx) ? paperSx : [paperSx]),
				],
			}}
			slotProps={{
				backdrop: {
					sx: { bgcolor: 'rgba(17, 17, 17, 0.4)' },
				},
			}}
		>
			<Box
				sx={clsx(
					containerStyles,
					({ breakpoints }) => ({
						[breakpoints.down('sm')]: {
							pt: 4,
						},
					}),
					containerSx
				)}
			>
				{!hideCloseIcon && (
					<CoreIconButton sx={clsx(closeIconStyles, closeIconSx)} onClick={handleClose} disabled={closeDisabled}>
						<Close />
					</CoreIconButton>
				)}
				{!headerComponent && modalTitle && (
					<Typography
						variant={modalTitleVariant || 'h3'}
						sx={clsx(
							{ mb: 2, px: 4, ...titleSx },
							({ breakpoints }) => ({
								[breakpoints.down('sm')]: { px: 3 },
							}),
							titleSx
						)}
					>
						{modalTitle}
					</Typography>
				)}
				{headerComponent}
				<Box sx={loaderWrapperStyles}>
					<Box
						sx={clsx(
							scrollWrapperStyles,
							({ breakpoints }) => ({
								[breakpoints.down('sm')]: {
									px: 3,
									pb: 3,
								},
							}),
							bodySx
						)}
					>
						{children}
					</Box>
					{loading && <CoreLoadingWall />}
				</Box>
			</Box>
		</Dialog>
	);
};
