import { SxProps, Theme } from '@mui/material';

export const receiptEditStyle = {
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px dashed #ccc',
    pb: 1,
    mb: 2,
    gap: 1
  } as SxProps<Theme>,
  title: {
    color: theme => theme.colors.black,
    fontSize: 20,
    fontWeight: 'bold',
    borderBottom: '1px solid #e0e0e0',
    pb: 2,
    textAlign: 'center'
  } as SxProps<Theme>,
  date: {
    color: '#666',
    fontSize: '0.875rem',
  } as SxProps<Theme>,
  content: {
    pb: 7
  } as SxProps<Theme>,
  sectionTitle: {
    color: theme => theme.colors.orange,
    mt: 2,
    mb: 1,
    fontWeight: 'bold',
    pb: 1
  } as SxProps<Theme>,
  fieldRow: {
    display: 'flex',
    mb: 2,
    alignItems: 'center',
    gap: 1
  } as SxProps<Theme>,
  fieldLabel: {
    color: '#333',
    flex: 1,
  } as SxProps<Theme>,
  fieldValue: {
    color: '#333',
    flex: 1,
    textAlign: 'right',
  } as SxProps<Theme>,
  product: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    mt: 1,
    borderBottom: '1px dashed #ccc',
    pb: 1,
    '&:last-child': {
      borderBottom: 'none',
    },
  } as SxProps<Theme>,
  productName: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } as SxProps<Theme>,
  productDetails: {
    color: '#555',
  } as SxProps<Theme>,
  detailRow: {
    display: 'flex',
    alignItems: 'center',
    mt: 1,
  } as SxProps<Theme>,
  icon: {
    color: theme => theme.colors.orangePressed,
    mt:4
  } as SxProps<Theme>,
  iconButton: {
    color: theme => theme.colors.orangePressed,
    backgroundColor: theme => theme.colors.white
  } as SxProps<Theme>,
  totalAmount: {
    color: theme => theme.colors.orangePressed,
    fontWeight: 'bold',
    textAlign: 'right',
    mt: 1,
    pb: 1
  } as SxProps<Theme>,
  footer: {
    zIndex: 9,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    borderTop: '1px solid #e0e0e0',
    px: 1,
    py: 2,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  } as SxProps<Theme>,
  addButton: {
    mt: 0,
    mb: 12,
  } as SxProps<Theme>,
  newProduct: {
    overflowY: 'auto',
    overflowX: 'hidden',
    m: 0,
  },
};
