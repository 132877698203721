import { clsx } from '../../../utils/style.utils';
import { Box, FormControl, FormHelperText, FormLabel, SxProps, Theme, TooltipProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ExclamationTooltip } from '../exclamation-tooltip';
import { labelStyle } from './core-form-control.styles';

export interface CoreFormControlProps {
	label?: string | null | ReactNode;
	labelHint?: string;
	placementLabelHint?: TooltipProps['placement'];
	tooltipSx?: SxProps<Theme>;
	fullWidth?: boolean;
	controlSx?: SxProps<Theme>;
	focused?: boolean;
	disabled?: boolean;
	error?: boolean;
	id?: string;
	labelSx?: SxProps<Theme>;
	children?: ReactNode;
	requiredMark?: boolean;
	helperText?: string;
	labelHintIconSx?: SxProps<Theme>;
}

export const CoreFormControl: FC<CoreFormControlProps> = (props) => {
	const {
		helperText,
		children,
		id,
		label,
		labelHint,
		fullWidth,
		placementLabelHint,
		tooltipSx,
		controlSx,
		focused,
		disabled,
		error,
		requiredMark,
		labelSx,
		labelHintIconSx,
	} = props;

	return (
		<FormControl focused={focused} disabled={disabled} error={error} fullWidth={fullWidth} sx={controlSx}>
			{label && (
				<FormLabel
					htmlFor={id}
					sx={clsx(
						{
							display: 'flex',
							alignItems: 'center',
							mb: 1,
							'&.Mui-focused': {
								color: (theme) => theme.colors.blue,
							},
							...labelStyle,
						},
						labelSx
					)}
				>
					{label}
					{requiredMark && (
						<Box component="span" sx={{ color: (theme) => theme.colors.red }}>
							*
						</Box>
					)}
					{labelHint && (
						<ExclamationTooltip
							title={labelHint}
							placement={placementLabelHint}
							tooltipSx={tooltipSx}
							iconSx={labelHintIconSx}
						/>
					)}
				</FormLabel>
			)}
			{children}
			{helperText && (
				<FormHelperText
					sx={{
						...labelStyle,
						mx: 0,
						mt: 1,
						fontFamily: 'inherit',
						fontWeight: 'inherit',
					}}
				>
					{helperText}
				</FormHelperText>
			)}
		</FormControl>
	);
};
