import React, { FC } from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { containerStyles, googleAuthRowStyles, footerStyles, titleStyles, descriptionStyles, carouselItemStyles } from './login-with-email.styles';
import { GoogleAuthButton } from '../../components/_ui/google-auth-button';
import Carousel from 'react-material-ui-carousel';

const carouselItems = [
	{
		content: 'https://picsum.photos/400',
		description: 'Додавання покупки вручну або сканнуванням чеку',
	},
	{
		content: 'https://picsum.photos/401',
		description: 'Детальна інформація про покупку',
	},
	{
		content: 'https://picsum.photos/402',
		description: 'Різноманітна інформативна статистика',
	},
	{
		content: 'https://picsum.photos/403',
		description: 'Поради щодо економії від AI асистента',
	},
];

const LoginBySocialNetworks: FC = () => {
	return (
		<Container sx={containerStyles}>
			<Carousel
				
				navButtonsAlwaysInvisible
				indicatorContainerProps={{
					style: {
						marginTop: '10px',
					}
				}}
			>
				{carouselItems.map((item, index) => (
					<Box key={index} sx={carouselItemStyles}>
						<img src={item.content} alt={`carousel-item-${index}`} style={{ height: '300px',
							width: '300px', marginBottom: '10px' }} />
						<Typography sx={titleStyles} variant="h6">
							{item.description}
						</Typography>
					</Box>
				))}
			</Carousel>
			<Box sx={googleAuthRowStyles}>
			</Box>
			<Typography sx={descriptionStyles} variant="body2">
				Увійдіть або створіть профіль, щоб отримати доступ до своїх даних з будь-якого девайсу
			</Typography>
			<Box sx={googleAuthRowStyles}>
				<GoogleAuthButton />
			</Box>
			<Box sx={footerStyles}>
				<Typography variant="body2" color="textSecondary">
					Авторизуючись, ви підтверджуєте що погоджуєтесь з
					<Link href="#" underline="none"> Умовами використання </Link>
					та
					<Link href="#" underline="none"> Політикою конфіденційності</Link>
				</Typography>
			</Box>
		</Container>
	);
};

export default LoginBySocialNetworks;
