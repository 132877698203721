import React, { ElementType, FC, forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';
import { InputBaseProps, Select, SelectProps, SxProps, Theme, Typography } from '@mui/material';
import { selectPaperStyles } from './core-select.styles';
import { DropdownArrow } from './dropdown-arrow.component';
import { CoreInput, CoreInputProps } from '../core-input';
import { Option } from '../core-autocomplete';
import { CoreSelectMenuItem, CoreSelectMenuItemProps } from './core-select-menu-item.component';
import { clsx } from '../../../utils/style.utils';

export type SelectOption = { value: Option['value']; label: Option['label'] | JSX.Element };

export interface CoreSelectProps extends CoreFormControlProps, InputBaseProps, CoreInputProps {
	SelectProps?: SelectProps;
	IconComponent?: ElementType;
	disableIconRotate?: boolean;
	iconSize?: number;
	paperSx?: SxProps<Theme>;
	fitMenuWidth?: boolean;
	menuMinWidth?: number;
	emptyLabel?: string;
	options?: SelectOption[];
	MenuItemProps?: CoreSelectMenuItemProps;
	autoSelectOnlyOption?: boolean;
	renderOption?(option: { value: Option['value']; label: Option['label'] | JSX.Element }): JSX.Element;
	onClose?(): void;
}

export const CoreSelect = forwardRef((props: CoreSelectProps, ref) => {
	const {
		menuMinWidth,
		children,
		options,
		MenuItemProps,
		renderOption,
		focused,
		helperText,
		error,
		small,
		controlSx,
		SelectProps,
		disabled,
		fullWidth,
		label,
		labelHint,
		requiredMark,
		id,
		IconComponent,
		disableIconRotate,
		iconSize = 16,
		onClose,
		paperSx,
		fitMenuWidth,
		value = '',
		placeholder,
		autoSelectOnlyOption,
		onChange,
		...inputProps
	} = props;

	const selectedOption = useMemo(() => options?.find((option) => (value as string) === option.value), [options, value]);

	useEffect(() => {
		if (options?.length === 1 && autoSelectOnlyOption && selectedOption === undefined && !Array.isArray(value)) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onChange?.({ target: { value: options[0].value } } as any);
		}
	}, [options, autoSelectOnlyOption, onChange, selectedOption, value]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const renderOptionMapper = useCallback(
		renderOption ??
		(({ label, value }: SelectOption) => (
			<CoreSelectMenuItem key={value} value={value} noWrap {...MenuItemProps}>
				{label}
			</CoreSelectMenuItem>
		)),
		[MenuItemProps]
	);

	const emptyLabel = useMemo(() => SelectProps?.placeholder ?? placeholder, [SelectProps?.placeholder, placeholder]);

	const renderPlaceholder = useCallback(
		(hasSelectedOption: boolean) =>
			hasSelectedOption || !emptyLabel
				? undefined
				: () => (
					<Typography
						component="span"
						sx={{
							color: (theme) => (disabled ? theme.colors.blueTransparent : theme.colors.gray),
							fontWeight: '300',
						}}
					>
						{emptyLabel}
					</Typography>
				),
		[disabled, emptyLabel]
	);

	const selectContainerRef = useRef<HTMLDivElement | null>(null);
	const offsetWidth = selectContainerRef.current?.offsetWidth;

	const inputHeight = small ? 36 : 48;

	return (
		<CoreFormControl
			label={label}
			labelHint={labelHint}
			id={id}
			requiredMark={requiredMark}
			fullWidth={fullWidth}
			focused={focused}
			controlSx={controlSx}
			helperText={helperText}
			error={error}
			disabled={disabled}
		>
			<Select
				SelectDisplayProps={{
					style: {
						display: 'flex',
						alignItems: 'center',
						paddingTop: '13px',
						paddingRight: small ? 40 : 44,
					},
				}}
				{...SelectProps}
				placeholder={placeholder}
				displayEmpty={SelectProps?.displayEmpty ?? true}
				renderValue={SelectProps?.renderValue ?? renderPlaceholder(!!selectedOption)}
				ref={selectContainerRef}
				sx={{
					'& .MuiSelect-icon': {
						right: small ? 12 : 16,
						top: (inputHeight - iconSize) / 2,
						transform: disableIconRotate ? 'none' : undefined,
					},

					'& .MuiSelect-iconOpen': {
						color: theme => disableIconRotate ? 'inherit' : theme.colors.black,
					},

					'&.MuiSelect-select': {
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						minWidth: 0,
						display: 'inline !important',
					},

					'&.Mui-disabled .MuiSelect-select': {
						color: theme => `${theme.colors.blueTransparent} !important`,
					},

					...SelectProps?.sx,
				}}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left"
					},
					...SelectProps?.MenuProps,
					TransitionProps: {
						onExited: onClose,
					},
					PaperProps: {
						sx: clsx(
							{
								width: fitMenuWidth
									? (menuMinWidth ?? 0) > (offsetWidth ?? 0)
										? menuMinWidth
										: offsetWidth
									: undefined,
							},
							selectPaperStyles,
							paperSx
						),
					},
				}}
				IconComponent={IconComponent ?? DropdownArrow}
				input={<CoreInput {...inputProps} onChange={onChange} value={value} ref={ref} small={small} id={id} />}
			>
				{children}
				{options?.map(renderOptionMapper)}
			</Select>
		</CoreFormControl>
	);
});

CoreSelect.displayName = 'CoreSelect';
