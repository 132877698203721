import React, { FC } from 'react';
import { Grid, TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { productQuantityPriceStyles } from './product-quantity-price-input.styles';
import { CoreNumericField } from "../../../../_core/components/_ui/core-numeric-field/index";

interface ProductQuantityPriceInputProps {
  quantity: number;
  price: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  quantityError: string;
  priceError: string;
  disabled: boolean;
}

export const ProductQuantityPriceInput: FC<ProductQuantityPriceInputProps> = ({ quantity, price, onChange, onFocus, onBlur, quantityError, priceError, disabled }) => {
  const [t] = useTranslation();

  return (
    <Grid sx={{mt:0.25}} container spacing={2}>
      <Grid item xs={5}>
        <CoreNumericField
          fullWidth
          id="quantity"
          name="quantity"
          label={t('Кількість')}
          maxLength={7}
          inputMode="decimal"
          requiredMark={true}
          decimalScale={3}
          value={quantity}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          sx={productQuantityPriceStyles.quantityField}
          disabled={disabled}
          error={!!quantityError}
          helperText={quantityError}
        />
      </Grid>
      <Grid item xs={7}>
        <CoreNumericField
          fullWidth
          id="price"
          name="price"
          decimalScale={2}
          label={t('Ціна')}
          inputMode="decimal"
          value={price}
          requiredMark={true}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          sx={productQuantityPriceStyles.priceField}
          disabled={disabled}
          error={!!priceError}
          helperText={priceError}
          startAdornment={<InputAdornment position="start">₴</InputAdornment>}
        />
      </Grid>
    </Grid>
  );
};
