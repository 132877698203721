import { SxProps, Theme } from '@mui/material';

export const ReceiptFilterStyles = {
  modal: {
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    px: 3,
    pt:3,
    pb:2,
    overflowY: "hidden"
  } as SxProps<Theme>,
  title: {
    textAlign: 'center',
  } as SxProps<Theme>,
  field: {
    mt:3,
    mb: 2,
  } as SxProps<Theme>,
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2,
  } as SxProps<Theme>,
  button: {
    flex: 1,
    mx: 1,
  } as SxProps<Theme>,
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
    mb: 2,
    pb: 1
  } as SxProps<Theme>,
};
