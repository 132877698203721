import { SxProps, Theme } from '@mui/material';

export const coreConditionalSelectContainerStyles = (sxProp?: SxProps<Theme>): SxProps<Theme> => ({
	maxHeight: 420,
	...sxProp,
});

export const selectedValueWithCountStyles: SxProps<Theme> = {
	display: 'flex',
	alignItems: 'center',
	gap: '8px',
};

export const selectedCountLabelStyles = (isDisabled?: boolean, count?: number): SxProps<Theme> => ({
	fontSize: '12px',
	color: (theme) => (isDisabled ? theme.colors.blueTransparent : count ? theme.colors.black : theme.colors.gray),
});

export const selectedCountWrapperStyles = (isDisabled?: boolean, count?: number): SxProps<Theme> => ({
	boxSizing: 'border-box',
	display: count ? 'flex' : 'none',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: isDisabled ? (count ? '#A6BBEC' : '#EDEFF3') : count ? (theme) => theme.colors.lightBlue : '#EDEFF3',
	borderRadius: '4px',
	padding: '2px 5px',
	minWidth: '20px',
	minHeight: '20px',
	flexShrink: 0,
});

export const selectedCountNumberStyles = (isDisabled?: boolean, count?: number): SxProps<Theme> => ({
	color: theme =>  isDisabled ? (count ? '#DDDDDD' : '#DDDDDD') : count ? 'white' : theme.colors.gray,
	fontSize: '10px',
	fontWeight: '700',
	lineHeight: '12px',
});

export const emptyValueStyles = (isDisabled?: boolean): SxProps<Theme> => ({
	color: (theme) => (isDisabled ? theme.colors.blueTransparent : theme.colors.gray),
	fontWeight: '300',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

export const allValueStyles = (isDisabled?: boolean): SxProps<Theme> => ({
	color: theme =>  isDisabled ? theme.colors.gray : theme.colors.black,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	...(isDisabled ? { fontWeight: 300 } : {}),
});

export const selectedValuesStyles = (isDisabled?: boolean): SxProps<Theme> => ({
	color: (theme) => (isDisabled ? theme.colors.blueTransparent : ''),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

export const operatorSelectStyles: SxProps<Theme> = {
	px: 3,
	mt: -1,
	pt: 1,
	position: 'sticky',
	top: 0,
	zIndex: 2,
	bgcolor: 'white',
};

export const operatorsRadioGroupStyles: SxProps<Theme> = {
	pb: 1,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
};

export const dividerStyles: SxProps<Theme> = {
	mb: 1,
};
