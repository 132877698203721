import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography, Paper, Box } from "@mui/material";

export const ReceiptsStatisticPage: FC = () => {
  const [t] = useTranslation();

  return (
    <Container maxWidth="xl" sx={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('Статистика по чеках')}
      </Typography>
      <Box sx={{ marginTop: 2, marginBottom: 4 }}>
        <Typography variant="body1" sx={{ margin: '20px 0' }}>
          {t('На цій сторінці ви зможете переглядати детальну статистику за вашими чеками.')}
        </Typography>
        <Typography variant="body1">
          {t('Ось що буде доступно:')}
        </Typography>
        <Typography component="ul" sx={{ textAlign: 'left', marginLeft: 4 }}>
          <li>{t('Аналіз витрат за періоди – день, місяць, рік.')}</li>
          <li>{t('Категорії найбільших витрат.')}</li>
          <li>{t('Порівняння витрат із попередніми періодами.')}</li>
          <li>{t('Графічне відображення даних для кращого сприйняття.')}</li>
          <li>{t('Експорт даних для подальшого аналізу.')}</li>
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {t('Ця сторінка допоможе вам краще зрозуміти ваші фінансові звички та оптимізувати ваші витрати.')}
        </Typography>
      </Box>
    </Container>
  );
};
