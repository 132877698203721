import React, { useState, useEffect, ChangeEvent, forwardRef, useImperativeHandle } from 'react';
import {
  Box,
  Grid,
  InputAdornment
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addTransactionPageStyles } from '../add-transaction.styles';
import AccountSelect from "../../../components/account-select/account-select";
import { useAddNewTransactionOutcomeMutation } from "../../../../../store/api/transactions.api";
import DateTimePicker from "../../../../_core/components/_ui/core-datetime-picker/core-datetime-picker.component";
import StoreSelect from '../../../components/store-select/store-select.component';
import { CoreTextField } from "../../../../_core/components/_ui/core-textfield/core-textfield.component";
import { CoreNumericField } from "../../../../_core/components/_ui/core-numeric-field";
import { NewTransactionDto } from "../../../../../libs/models/dto/new-transaction.dto";

interface AddTransactionOutcomePageProps {
  onClose: () => void;
  onNotify: (message: string, type: 'success' | 'error') => void;
  isOpen: boolean;
  onValidate: (isValid: boolean) => void;
}

const getCurrentLocalDateTime = () => {
  const now = new Date();
  const offset = now.getTimezoneOffset();
  const localDate = new Date(now.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, 16);
};

const AddTransactionOutcomePage = forwardRef<{ handleFormSubmit: () => void }, AddTransactionOutcomePageProps>(
  ({ onClose, onNotify, isOpen, onValidate }, ref) => {
    const [t] = useTranslation();
    const [account, setAccount] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [date, setDate] = useState<string>(getCurrentLocalDateTime());
    const [store, setStore] = useState<string>('');
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [isValid, setIsValid] = useState<boolean>(false);

    const [addNewTransactionOutcome, { isLoading }] = useAddNewTransactionOutcomeMutation();

    const handleAccountChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAccount(event.target.value as string);
    };

    const validateStore = (value: string) => {
      if (value.trim() === '') return '';
      if (value.length < 3) return t('Магазин має містити мінімум 3 символи');
      if (/^\d+$/.test(value)) return t('Магазин не може складатися тільки з цифр');
      return '';
    };

    const validateForm = () => {
      const isAccountValid = account.trim() !== '';
      const isDateValid = date.trim() !== '';
      const isStoreValid = validateStore(store) === '';
      const isTotalAmountValid = totalAmount > 0;

      const formIsValid = isAccountValid && isDateValid && isStoreValid && isTotalAmountValid;
      setIsValid(formIsValid);
      onValidate(formIsValid); // Оновлюємо стан валідації в батьківському компоненті
    };

    useEffect(() => {
      validateForm();
    }, [account, date, store, totalAmount]);

    useImperativeHandle(ref, () => ({
      handleFormSubmit: async () => {
        if (!isValid) return;

        const transactionData: NewTransactionDto = {
          accountId: account,
          date,
          sum: totalAmount,
          description: description !== '' ? description : undefined,
          shop: store
        };

        try {
          await addNewTransactionOutcome(transactionData).unwrap();
          onNotify('Успішно додано', 'success');
          onClose();
        } catch (error) {
          onNotify('Виникли проблеми', 'error');
          onClose();
        }
      }
    }));

    useEffect(() => {
      if (!isOpen) {
        setAccount('');
        setDescription('');
        setDate(getCurrentLocalDateTime());
        setStore('');
        setTotalAmount(0);
        setIsValid(false);
        onValidate(false); // Скидаємо стан валідації
      }
    }, [isOpen]);

    return (
      <Box component="form" sx={{ p: 0, pt:1 }} noValidate autoComplete="off">
        <Grid container spacing={2} alignItems="center" sx={addTransactionPageStyles.dateFieldContainer}>
          <Grid item xs={12}>
            <AccountSelect account={account} requiredMark={true} handleAccountChange={handleAccountChange} disabled={isLoading} />
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              label={t('Дата')}
              value={date}
              onChange={setDate}
              allowFuture={false}
              requiredMark={true}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <StoreSelect
              store={store}
              setStore={setStore}
              isLoading={isLoading}
              requiredMark
            />
          </Grid>
          <Grid item xs={12}>
            <CoreNumericField
              fullWidth
              id="price"
              name="price"
              decimalScale={2}
              label={t('Ціна')}
              inputMode="decimal"
              value={totalAmount}
              requiredMark={true}
              onChange={(e) => setTotalAmount(Number(e.target.value))}
              onFocus={() => { }}
              onBlur={() => { }}
              helperText={''}
              startAdornment={<InputAdornment position="start">₴</InputAdornment>}
            />
          </Grid>
          <Grid item xs={12}>
            <CoreTextField
              fullWidth
              id="description"
              label={t('Опис')}
              placeholder={t('Оплата за навчання')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={addTransactionPageStyles.description}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default AddTransactionOutcomePage;
