import { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

export const CalendarBase: StyledComponent<ReactDatePickerProps<never, boolean | undefined>> = styled(
	<WithRange extends boolean | undefined = undefined>(props: ReactDatePickerProps<never, WithRange>) => (
		<ReactDatePicker {...props} calendarClassName={props.className} />
	)
)(({ theme }) => ({
	'&.react-datepicker': {
		borderRadius: 12,
		backgroundColor: theme.colors.white,
		overflow: 'hidden',
		border: `1px solid ${theme.colors.blueTransparent}`,
		boxShadow: `0px 4px 4px rgba(68, 71, 77, 0.08)`,
	},

	'& .react-datepicker__input-time-container': {
		margin: '-8px 0 0 0',
		padding: '0 24px 24px 24px',

		'.react-datepicker-time__input-container .react-datepicker-time__input': {
			input: {
				width: '100%',
			},
			margin: 0,
			alignItems: 'center',
			display: 'flex',
		},
	},

	'& .react-datepicker__header': {
		backgroundColor: theme.colors.white,
		margin: '18px 24px 0 24px',
		padding: 0,
		borderBottom: `1px solid ${theme.colors.divider}`,

		'.react-datepicker__day-names': {
			margin: 0,
			marginBottom: 4,

			'.react-datepicker__day-name': {
				...theme.typography.body1,
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
				textTransform: 'capitalize',
				width: 32,
				height: 32,
				margin: 0,
				marginRight: 8,

				'&:last-child': {
					marginRight: 0,
				},
			},
		},
	},

	'& .react-datepicker__month': {
		margin: 0,
		padding: '16px 24px 24px 24px',

		'.react-datepicker__week': {
			marginBottom: 8,

			'&:last-child': {
				marginBottom: 0,
			},

			'.react-datepicker__day': {
				...theme.typography.body2,
				textAlign: 'center',
				margin: 0,
				marginLeft: 8,
				width: 32,
				height: 32,
				borderRadius: 12,
				display: 'inline-flex',
				justifyContent: 'center',
				alignItems: 'center',
				transition: theme.transitions.create(['background-color', 'color'], {
					duration: theme.transitions.duration.short,
				}),

				'&--keyboard-selected': {
					backgroundColor: theme.colors.blueTransparent,
				},

				'&--outside-month': {
					color: theme.colors.blueTransparent,
				},

				'&--in-selecting-range': {
					color: theme.colors.black,
					backgroundColor: theme.colors.blueTransparent,
				},

				'&--selected, &--in-range': {
					color: theme.colors.white,
					backgroundColor: theme.colors.blue,
				},

				'&--today': {
					fontWeight: 700,
				},

				'&--disabled': {
					backgroundColor: theme.colors.blueTransparentLight,
					color: theme.colors.blueTransparentLight,
				},

				'&:first-of-type': {
					marginLeft: 0,
				},
			},
		},
	},
}));
