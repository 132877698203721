import { FC, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { profileNavRowStyles } from './profile-nav.styles';
import { CoreNavTabs, NavTab } from '../../../_core/components/_ui/core-nav-tabs';
import { ProfileRoutes } from '../../constants';
import { useSignOut } from '../../../auth';
import { LogoutButton } from '../logout-button';
import { PortraitOutlined } from '@mui/icons-material';
import { MobileConfirmationModal } from '../profile/modals/sign-out-confirmation.modal'; // новий імпорт

export const ProfileNavigation: FC = () => {
	const [t] = useTranslation();

	const handleLogout = useSignOut();

	const [isModalOpen, setModalOpen] = useState(false);

	const handleOpenLogoutConfirmModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleConfirmLogout = useCallback(() => {
		handleLogout();
		handleCloseModal();
	}, [handleLogout, handleCloseModal]);

	const tabs = useMemo<NavTab[]>(
		() => [
			{
				to: ProfileRoutes.Profile,
				label: '',
				icon: <PortraitOutlined />,
				id: 'profile-panel',
				replace: true,
			}
		],
		[t]
	);

	return (
		<Box sx={profileNavRowStyles}>
			<CoreNavTabs tabs={tabs} minTabWidth={0} />

			<LogoutButton onLogout={handleOpenLogoutConfirmModal} hideLabel={true} />

			<MobileConfirmationModal
				open={isModalOpen}
				onClose={handleCloseModal}
				onConfirm={handleConfirmLogout}
			/>
		</Box>
	);
};
