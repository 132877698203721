import { UpdateUserDataDto, UserDto } from 'libs/models/dto';
import api from '.';
import {AccountDto} from "libs/models/dto/account.dto";
import {transformApiResponse } from '../../modules/_core/utils/api'

const usersApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getUserById: builder.query<UserDto, string>({
			query: (id) => ({
				url: `users/user/${id}`,
			}),
		}),
		updateUser: builder.mutation<UserDto, { id: string; data: UpdateUserDataDto }>({
			query: ({ id, data }) => ({
				url: `users/user/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['UserProfile'],
		}),
		getAccounts: builder.query<AccountDto[], void>({
			query: () => ({
				url: `users/accounts`,
			}),
			transformResponse: transformApiResponse<AccountDto[]>,
		}),
	}),
});

export default usersApi;

export const { useGetUserByIdQuery, useUpdateUserMutation, useGetAccountsQuery } = usersApi;
