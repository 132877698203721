import { JwtPayload } from 'libs/interfaces';
import jwtDecode from 'jwt-decode';
import { SessionState } from '../store/session.slice';

export const createTokenPair = (access: string | null, refresh: string | null): SessionState => {
	try {
		if (!access || !refresh) {
			return {
				access: null,
				refresh: null
			};
		}

		const accessPayload = jwtDecode<JwtPayload>(access);

		return {
			access: {
				token: access,
				payload: accessPayload
			},
			refresh: {
				token: refresh,
				payload: {}
			}
		};
	} catch {
		return {
			access: null,
			refresh: null
		};
	}
};
