import { SxProps, Theme } from '@mui/material';

export const cardStyles: SxProps<Theme> = {
	mb: 3,
	p: 2,
	borderRadius: 2,
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

export const profileDataCategoryStyles: SxProps<Theme> = {
	mt: 2,
	mb: 2,
};

export const contactInfoRowStyles: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	mb: 1,
};

export const contactInfoButtonStyles: SxProps<Theme> = {
	p: '0.25rem',
	backgroundColor: '#f0f0f0',
	borderRadius: '50%',
};

export const subtitleStyles: SxProps<Theme> = {
	borderBottom: '2px solid #ddd',
	mb: 1,
};