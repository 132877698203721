import { SxProps, Theme } from '@mui/material';

export const receiptsListPageStyles = {
  appBar: {
    backgroundColor: (theme: Theme) => theme.colors.darkGray,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    mx: 0,
    height: 'auto',
    mb: 1,
    transition: 'background-color 0.3s ease',
    zIndex: 2,
    borderRadius: 1
  } as SxProps<Theme>,
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    pb: 17,
    px: 1
  } as SxProps<Theme>,
  content: {
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: '2px',
  },
  blur: {
    filter: 'blur(2px)',
    pointerEvents: 'none',
  },
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as SxProps<Theme>,
  headerTitle: {
    fontWeight: 'bold',
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  } as SxProps<Theme>,
  sortButton: {
    fontSize: '1.5rem',
  },
  chip: {
    bgcolor: 'white',
    '&:hover': {
      bgcolor: 'white',
    },
  },
  blurOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  chipContainer: {
    display: 'flex',
    gap: 1,
    px: 2,
    mt: 2,
    mb: 2,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
    '::after': {
      content: '""',
      flex: '0 0 16px'
    }
  } as SxProps<Theme>,
};
