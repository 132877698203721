import { SxProps, Theme } from '@mui/material';

export const receiptViewModalStyles = {
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: 0,
    backgroundImage: 'url("/path/to/receipt-texture.png")',
    backgroundSize: 'cover',
    overflowY: 'auto',
    borderBottom: '20px solid transparent',
    borderImage: 'url("/path/to/torn-effect.png") 30 round',
  } as SxProps<Theme>,
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px dashed #ccc',
    pb: 1,
    mb: 2,
    gap: 1
  } as SxProps<Theme>,
  title: {
    color: theme => theme.colors.black,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  } as SxProps<Theme>,
  date: {
    color: '#666',
    fontSize: '0.875rem',
  } as SxProps<Theme>,
  content: {
    overflowY: 'auto',
    flex: 1,
    height: '100%',
    pb: 7,
    scrollbarWidth: 'none', // Firefox
    '&::-webkit-scrollbar': { // Chrome, Safari
      display: 'none'
    }
  } as SxProps<Theme>,
  sectionTitle: {
    color: theme => theme.colors.orange,
    mt: 2,
    mb: 1,
    fontWeight: 'bold',
    pb: 1
  } as SxProps<Theme>,
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 1,
    alignItems: 'center',
    gap: 1
  } as SxProps<Theme>,
  fieldLabel: {
    color: '#333',
    flex: 1,
  } as SxProps<Theme>,
  fieldValue: {
    color: '#333',
    flex: 1,
    textAlign: 'right',
  } as SxProps<Theme>,
  product: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    mt: 1,
    borderBottom: '1px dashed #ccc',
    pb: 1,
    '&:last-child': {
      borderBottom: 'none',
    },
  } as SxProps<Theme>,
  productName: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } as SxProps<Theme>,
  productDetails: {
    color: '#555',
  } as SxProps<Theme>,
  detailRow: {
    display: 'flex',
    alignItems: 'center',
    mt: 1,
  } as SxProps<Theme>,
  icon: {
    color: theme => theme.colors.orangePressed
  } as SxProps<Theme>,
  iconButton: {
    color: theme => theme.colors.orangePressed,
    backgroundColor: theme => theme.colors.white
  } as SxProps<Theme>,
  totalAmount: {
    color: theme => theme.colors.orangePressed,
    fontWeight: 'bold',
    textAlign: 'right',
    mt: 1
  } as SxProps<Theme>,
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    bgcolor: 'background.paper',
    p: 2,
    boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'center',
  } as SxProps<Theme>,
};
