import { clsx } from '../../../utils/style.utils';
import { StyledComponent } from '@emotion/styled';
import { Tooltip, TooltipProps } from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';

interface OffsetProps {
	placement: NonNullable<TooltipProps['placement']>;
}
interface CoreTooltipProps extends TooltipProps {
	tooltipSx?: SxProps<Theme>;
}

const modifiers = [
	{
		name: 'offset',
		options: {
			offset: ({ placement }: OffsetProps) => {
				if (placement === 'top-start') {
					return [-16, -15];
				}

				if (placement === 'top-end') {
					return [16, -15];
				}

				return [];
			},
		},
	},
];

export const CoreTooltip: StyledComponent<CoreTooltipProps> = styled(
	({ className, tooltipSx, ...props }: CoreTooltipProps) => (
		<Tooltip
			{...props}
			enterTouchDelay={0}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			classes={{ popper: className }}
			PopperProps={{ modifiers }}
		/>
	)
)(({ theme, tooltipSx }) => ({
	color: theme.colors.gray,
	transition: theme.transitions.create('color', {
		duration: theme.transitions.duration.short,
	}),

	'&:hover': {
		cursor: 'pointer',
		color: theme.colors.dark,
	},

	'& .MuiTooltip-arrow:before': {
		color: theme.colors.white,
		boxShadow: `0px 0px 4px 0px #9EA3A980`,
	},

	'& .MuiTooltip-tooltip': clsx(
		{
			...theme.typography.label,
			maxWidth: 273,
			fontSize: 12,
			fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif',
			lineHeight: 1.4,
			fontWeight: 400,
			p: {
				display: 'inline',
				font: 'inherit',
				fontWeight: 500,
			},
			borderRadius: '16px',
			color: theme.colors.dark,
			padding: '12px',
			boxShadow: `0px 2px 4px 0px #9EA3A980`,
			backgroundColor: theme.colors.white,
		},
		tooltipSx
	),
}));
