import React, { FC, useCallback, useState, useEffect } from 'react';
import { Box, Modal, Backdrop, Fade, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReceiptSortModalStyles } from './receipt-sort.styles';
import { CoreRadioGroup } from '../../../_core/components/_ui/core-radio/core-radio-group.component';
import { CoreRadio } from '../../../_core/components/_ui/core-radio/core-radio.component';
import { RadioFormLabel } from '../../../_core/components/_ui/core-radio/radio-form-label.component';
import {SortOption, sortOptions} from '../../constants/sort-options.constants';
import { Close, Filter} from '../../../_core/constants/icons.constants';
import {useTranslation} from "react-i18next";

interface ReceiptSortModalProps {
  open: boolean;
  onClose: () => void;
  onSort: (sortOption: SortOption ) => void;
  currentSort: SortOption;
}

export const ReceiptSortModal: FC<ReceiptSortModalProps> = ({ open, onClose, onSort, currentSort }) => {
  const [selectedOption, setSelectedOption] = useState<string>(`${currentSort.value}-${currentSort.orderBy}`);
  const { t } = useTranslation();
  
  const handleSortOptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const [value, orderBy] = event.target.value.split('-');
    const selectedSortOption = sortOptions.find(x => x.value === value && x.orderBy === parseInt(orderBy));

    if (selectedSortOption) {
      setSelectedOption(event.target.value);
      onSort({ label: selectedSortOption.label, value, orderBy: parseInt(orderBy) });
      onClose();
    }
  }, [onSort, onClose]);

  useEffect(() => {
    setSelectedOption(`${currentSort.value}-${currentSort.orderBy}`);
  }, [currentSort]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={ReceiptSortModalStyles.modalBox}>
          <Box sx={ReceiptSortModalStyles.header}>
            <Typography variant="h6">Сортувати</Typography>
            <Close onClick={onClose} />
          </Box>
          <CoreRadioGroup
            name="sort-options"
            value={selectedOption}
            onChange={handleSortOptionChange}
            sx={ReceiptSortModalStyles.radioGroup}
          >
            {sortOptions.map(option => (
              <RadioFormLabel
                key={`${option.value}-${option.orderBy}`}
                value={`${option.value}-${option.orderBy}`}
                control={<CoreRadio />}
                label={t(option.label)}
                sx={ReceiptSortModalStyles.radio}
              />
            ))}
          </CoreRadioGroup>
        </Box>
      </Fade>
    </Modal>
  );
};
