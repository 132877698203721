import React, { FC, useState } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { addReceiptByQrcodeInstructionModalStyles } from './add-receipt-by-qrcode-instruction-modal.styles';
import { CoreButton } from "../../../_core/components/_ui/core-button/index";
import { useTranslation } from 'react-i18next';
import { CoreModal } from "../../../_core/components/_ui/core-modal";

interface AddReceiptByQrcodeInstructionModalProps {
  open: boolean;
  onClose: () => void;
}

const AddReceiptByQrcodeInstructionModal: FC<AddReceiptByQrcodeInstructionModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const carouselItems = [
    {
      description: t('Це приклад правильно зчитаного QR-коду.'),
      image: '/images/correct.jpg',
      isCorrect: true,
    },
    {
      description: t('Переконайтеся, що QR-код належно освітлений для кращого зчитування.'),
      image:'/images/incorrect_2.jpg',
      isCorrect: false,
    },
    {
      description: t('Розмістіть QR-код у центрі поля зору камери.'),
      image:'/images/incorrect_3.jpg',
      isCorrect: false,
    },
    {
      description: t('Забезпечте чітке, без потертостей та вільне від перешкод відображення QR-коду.'),
      image:'/images/incorrect_1.jpg',
      isCorrect: false,
    },
  ];


  return (
    <CoreModal
      modalTitle={'Інструкція для сканування'}
      titleSx={addReceiptByQrcodeInstructionModalStyles.modalHeader}
      open={open}
      onClose={onClose}
      bodySx={{ p: 0 }}
      hideCloseIcon
      dialogSx={{ px: 0 }}
      paperSx={{ height: '100%', m: 0, pt: 0 }}>
      <Box sx={addReceiptByQrcodeInstructionModalStyles.container}>
        <Carousel>
          {carouselItems.map((item, index) => (
            <Box key={index} sx={addReceiptByQrcodeInstructionModalStyles.carouselItem}>
              <Typography sx={addReceiptByQrcodeInstructionModalStyles.carouselText}>
                {item.description}
              </Typography>
              <Box sx={addReceiptByQrcodeInstructionModalStyles.imageItem}>
                <img
                  src={item.image}
                  alt={`carousel-item-${index}`}
                />
                {item.isCorrect ? (
                  <CheckCircleIcon sx={addReceiptByQrcodeInstructionModalStyles.iconCorrect} />
                ) : (
                  <CancelIcon sx={addReceiptByQrcodeInstructionModalStyles.iconIncorrect} />
                )}
              </Box>
            </Box>
          ))}
        </Carousel>
        <Box sx={addReceiptByQrcodeInstructionModalStyles.footer}>
          <CoreButton onClick={onClose} variant={'secondary'} sx={addReceiptByQrcodeInstructionModalStyles.saveButton}>
            {t('Зрозуміло')}
          </CoreButton>
        </Box>
      </Box>
    </CoreModal>
  );
};

export default AddReceiptByQrcodeInstructionModal;
