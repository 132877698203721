import { SxProps, Theme } from '@mui/material';

export const productStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderTop: '2px solid #ccc',
    mb: 2,
  } as SxProps<Theme>,
  textField: {
    width: '100%',
  } as SxProps<Theme>,
  deleteButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    backgroundColor: 'white',
    padding: '2px',
    '&.Mui-disabled': {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  } as SxProps<Theme>,
};

