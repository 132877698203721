import api from '.';
import {NewReceiptByGpt, NewReceiptByManualDto, NewReceiptByQrCodeDto} from "libs/models/dto/new-receipt-by-manual.dto";
import { ReceiptListDto } from "libs/models/dto/receipts-list.dto";
import { ReceiptsListFilter } from "libs/models/filters";
import { MinMax, ResultListWithTotal, ResultWithoutTotal } from "libs/interfaces/result";
import {
  EditOutcomeTransactionDto,
  EditReceiptDto,
  OutcomeTransactionDto,
  ReceiptDto
} from "libs/models/dto/receipt.dto";
import { NewTransactionDto } from "libs/models/dto/new-transaction.dto";

const transactionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addNewReceiptManual: builder.mutation<boolean, NewReceiptByManualDto>({
      query: (receiptByManualDto) => ({
        url: 'transactions/add-receipt-manual',
        method: 'POST',
        body: receiptByManualDto,
      }),
    }),
    addNewReceiptQrCode: builder.mutation<boolean, NewReceiptByQrCodeDto>({
      query: (receiptByQrCode) => ({
        url: 'transactions/add-receipt-qrcode',
        method: 'POST',
        body: receiptByQrCode,
      }),
    }),
    addNewReceiptGpt: builder.mutation<boolean, NewReceiptByGpt>({
      query: (receiptByGpt) => ({
        url: 'transactions/add-receipt-by-gpt',
        method: 'POST',
        body: receiptByGpt,
      }),
    }),
    addNewTransactionOutcome: builder.mutation<boolean, NewTransactionDto>({
      query: (transaction) => ({
        url: 'transactions/add-transaction-outcome',
        method: 'POST',
        body: transaction,
      }),
    }),
    checkIsReceiptAlreadyAdded: builder.query<boolean, NewReceiptByQrCodeDto>({
      query: (receiptByManualDto) => ({
        url: 'transactions/is-transaction-already-added',
        method: 'POST',
        body: receiptByManualDto,
      })
    }),
    getFilteredReceiptsList: builder.mutation<ResultListWithTotal<ReceiptListDto>, ReceiptsListFilter>({
      query: (receiptsListFilter) => ({
        url: 'transactions/receipts',
        method: 'POST',
        body: receiptsListFilter,
      })
    }),
    getUsersShopsByQuery: builder.query<ResultWithoutTotal<string>, string>({
      query: (shops) => ({
        url: `shops/get-shops-for-filter`,
        params: { query: shops }
      })
    }),
    getMinAndMaxForRange: builder.query<MinMax, void>({
      query: () => ({
        url: `transactions/get-min-max`
      })
    }),
    removeReceipt: builder.mutation<void, string>({
      query: (id) => ({
        url: `transactions/delete-receipt/${id}`,
        method: 'DELETE',
      })
    }),
    getReceipt: builder.query<ReceiptDto, string>({
      query: (id) => ({
        url: `transactions/receipt/${id}`
      })
    }),
    editReceipt: builder.mutation<ReceiptDto, EditReceiptDto>({
      query: (receiptDto) => ({
        url: `transactions/edit-receipt`,
        method: 'PUT',
        body: receiptDto
      })
    }),
    getOutcomeTransaction: builder.query<OutcomeTransactionDto, string>({
      query: (id) => ({
        url: `transactions/outcome/${id}`
      })
    }),
    editOutcomeTransaction: builder.mutation<OutcomeTransactionDto, EditOutcomeTransactionDto>({
      query: (outcomeTransaction) => ({
        url: `transactions/edit-outcome-transaction`,
        method: 'PUT',
        body: outcomeTransaction
      })
    }),
  }),
});

export default transactionsApi;

export const {
  useAddNewReceiptManualMutation,
  useAddNewReceiptGptMutation,
  useAddNewReceiptQrCodeMutation,
  useAddNewTransactionOutcomeMutation,
  useLazyCheckIsReceiptAlreadyAddedQuery,
  useGetFilteredReceiptsListMutation,
  useGetUsersShopsByQueryQuery,
  useGetMinAndMaxForRangeQuery,
  useRemoveReceiptMutation,
  useLazyGetReceiptQuery,
  useLazyGetOutcomeTransactionQuery,
  useEditReceiptMutation,
  useEditOutcomeTransactionMutation
} = transactionsApi;
