import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreSelectStyles } from './store-select.styles';
import { CoreAutocomplete } from '../../../_core/components/_ui/core-autocomplete';
import { useGetUsersShopsByQueryQuery } from '../../../../store/api/transactions.api';
import { Option } from '../../../_core/components/_ui/core-autocomplete/core-autocomplete.types';

interface StoreSelectProps {
  store: string;
  setStore: (value: string) => void;
  isLoading: boolean;
  requiredMark?: boolean;
  setError?: (value: boolean) => void;
}

const StoreSelect: FC<StoreSelectProps> = ({ store, setStore, isLoading, setError, requiredMark = false }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const { data, error, refetch } = useGetUsersShopsByQueryQuery(store, {
    skip: store == undefined || store.length < 1
  });

  useEffect(() => {
    if (store != undefined && store.length >= 1) {
      refetch();
    } else {
      setOptions([]);
      if (setError) {
        setError(true);
      }
    }
  }, [store, refetch, setError]);

  useEffect(() => {
    if (data && data.data) {
      const formattedOptions = data.data.map((shop: string) => ({
        label: shop,
        value: shop
      }));
      setOptions(formattedOptions);
      if (setError) {
        setError(formattedOptions.length === 0);
      }
    } else {
      if (setError) {
        setError(true);
      }
    }
  }, [data, setError]);

  if (error) {
  }

  const handleInputChange = (value: string) => {
    if (value != undefined) {
      setStore(value);
    }
  };

  return (
    <CoreAutocomplete
      fullWidth
      requiredMark={requiredMark}
      label={t('Магазин')}
      multiple={false}
      placeholder={`Введіть ім'я магазину`}
      value={store ? { label: store, value: store } : null}
      onChange={(
        _: React.SyntheticEvent<Element, Event>,
        option: Option | null,
        reason: string
      ) => {
        if (reason === 'clear') {
          setStore('');
        } else if (reason === 'selectOption' && option?.value) {
          setStore(option.value);
        }
      }}
      sx={StoreSelectStyles.textField}
      disabled={isLoading}
      options={options}
      onInputChange={handleInputChange}
      noOptionsText={t('Збігів немає')}
    />
  );
};

export default StoreSelect;
