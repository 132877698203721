import { useCallback } from 'react';
import { useGetAccountsQuery } from '../../../store/api/users.api';
import { useCurrentRefreshTokenSelector } from '../../auth';

export const useFetchUsersData = () => {
  const refreshToken = useCurrentRefreshTokenSelector();

  const { data: accountsList = [], isFetching: isListLoading, refetch: refetchAccountsList } = useGetAccountsQuery();

  const handleInterruptOtherSessions = useCallback(async () => {
    await refetchAccountsList();
  }, [refreshToken?.token, refetchAccountsList]);

  return {
    accountsList,
    isListLoading,
    handleInterruptOtherSessions,
  };
};
