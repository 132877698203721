import React, { FC, useCallback } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CoreNumericField } from '../core-numeric-field';
import { NumberFormatValues } from 'react-number-format/types/types';
import { coreCalendarTimeInputStyles, coreCalendarTimeSeparatorStyles } from './core-calendar.styles';

interface CalendarTimeInputProps {
	value?: string;
	onChange?(time: string): void;
}

export const CalendarTimeInput: FC<CalendarTimeInputProps> = (props) => {
	const { onChange, value } = props;

	const { t } = useTranslation();

	const isAllowedHour = useCallback((values: NumberFormatValues) => {
		const { floatValue } = values;
		const hour = floatValue ?? 0;

		return hour >= 0 && hour <= 23;
	}, []);

	const isAllowedMinute = useCallback((values: NumberFormatValues) => {
		const { floatValue } = values;
		const minute = floatValue ?? 0;

		return minute >= 0 && minute <= 59;
	}, []);

	const [hour, minute] = value?.split(':') ?? [];

	const handleHourChange = useCallback(
		(values: NumberFormatValues) => {
			const min = minute ?? '00';
			const hour = values.formattedValue ?? '00';

			onChange?.(`${hour}:${min}`);
		},
		[minute, onChange]
	);

	const handleMinuteChange = useCallback(
		(values: NumberFormatValues) => {
			const h = hour ?? '00';
			const minute = values.formattedValue ?? '00';

			onChange?.(`${h}:${minute}`);
		},
		[hour, onChange]
	);

	return (
		<>
			<CoreNumericField
				allowNegative={false}
				decimalScale={0}
				allowLeadingZeros
				sx={coreCalendarTimeInputStyles}
				onValueChange={handleHourChange}
				value={hour ?? ''}
				valueIsNumericString
				isAllowed={isAllowedHour}
				label={t('calendar.hours')}
				placeholder={t('calendar.HH')}
			/>
			<Typography variant="body2" sx={coreCalendarTimeSeparatorStyles}>
				:
			</Typography>
			<CoreNumericField
				decimalScale={0}
				allowLeadingZeros
				value={minute ?? ''}
				sx={coreCalendarTimeInputStyles}
				valueIsNumericString
				onValueChange={handleMinuteChange}
				isAllowed={isAllowedMinute}
				allowNegative={false}
				label={t('calendar.minutes')}
				placeholder={t('calendar.MM')}
			/>
		</>
	);
};
