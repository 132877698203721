import React, { useCallback } from 'react';
import { ReactDatePickerCustomHeaderProps, ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { CalendarBase } from './calendar-base.component';
import { CalendarHeader } from './calendar-header.component';

import en from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarInput } from './calendar-input.component';
import { CoreInputProps } from '../core-input/core-input.component';
import { CalendarTimeInput } from './calendar-time-input.component';

registerLocale('en', en);
registerLocale('uk', uk);

export interface CoreCalendarProps<WithRange extends boolean | undefined = undefined>
	extends ReactDatePickerProps<never, WithRange> {
	inputProps?: CoreInputProps & { endAdornment?: React.ReactNode };
}

export const CoreCalendar = <WithRange extends boolean | undefined = undefined>(
	props: CoreCalendarProps<WithRange>
) => {
	const { inputProps, dateFormat = 'dd/MM/yyyy', isClearable, ...calendarProps } = props;
	const { i18n } = useTranslation();

	const renderCustomHeader = useCallback((props: ReactDatePickerCustomHeaderProps) => {
		const { increaseMonth, decreaseMonth, date, nextMonthButtonDisabled, prevMonthButtonDisabled } = props;

		return (
			<CalendarHeader
				date={date}
				locale={i18n.resolvedLanguage}
				nextMonthButtonDisabled={nextMonthButtonDisabled}
				prevMonthButtonDisabled={prevMonthButtonDisabled}
				increaseMonth={increaseMonth}
				decreaseMonth={decreaseMonth}
			/>
		);
	}, [i18n.resolvedLanguage]);

	return (
		<CalendarBase
			dateFormat={dateFormat}
			showPopperArrow={false}
			locale={i18n.resolvedLanguage}
			renderCustomHeader={renderCustomHeader}
			customTimeInput={<CalendarTimeInput />}
			timeInputLabel=""
			customInput={<CalendarInput clearable={String(isClearable)} {...inputProps} />}
			isClearable={isClearable}
			clearButtonClassName="react-datepicker__clear-button"
			{...calendarProps}
		/>
	);
};
