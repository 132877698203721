import React, { FC, ReactNode } from "react";
import { CoreFormControl, CoreFormControlProps } from "../core-form-control/core-form-control.component";
import { RadioGroup } from "@mui/material";
import { RadioGroupProps } from "@mui/material/RadioGroup/RadioGroup";

interface CoreRadioGroupProps extends
	CoreFormControlProps,
	RadioGroupProps {
	children?: ReactNode;
}

export const CoreRadioGroup: FC<CoreRadioGroupProps> = (props) => {
	const { row, name, value, onChange, children, ...formControlProps } = props;

	return (
		<CoreFormControl {...formControlProps}>
			<RadioGroup
				row={row}
				name={name}
				value={value}
				onChange={onChange}
			>
				{children}
			</RadioGroup>
		</CoreFormControl>
	);
};
