export const ReceiptSortModalStyles = {
  modalBox: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 3,
    pt:3,
    pb:2,
    borderRadius: '16px 16px 0 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
    pb: 2,
  },
  radioGroup: {
    mt: 2,
  },
  radio: {
    display: 'block'
  },
};
