import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddNewReceiptQrCodeMutation, useLazyCheckIsReceiptAlreadyAddedQuery } from "../../../store/api/transactions.api";
import { NewReceiptByQrCodeDto } from "libs/models/dto/new-receipt-by-manual.dto";
import { ReceiptUtils, QrCodeReceiptModel } from '../services/receipt-utils';

export const useQrcodeReceiptProcessing = (onNotify: (message: string, severity: 'success' | 'error') => void) => {
  const { t } = useTranslation();
  const [qrData, setQrData] = useState<string>('');
  const [isDataSaved, setIsDataSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [addNewReceipt] = useAddNewReceiptQrCodeMutation();
  const [checkReceipt, { data: isReceiptAlreadyAdded }] = useLazyCheckIsReceiptAlreadyAddedQuery();
  const [receiptData, setReceiptData] = useState<QrCodeReceiptModel | null>(null);

  const checkForMultipleEmptyFields = useCallback((data: QrCodeReceiptModel): boolean => {
    const emptyFields = ['id', 'fn', 'sum', 'date', 'time'].filter(field => !data[field as keyof QrCodeReceiptModel]);
    return emptyFields.length >= 2;
  }, []);

  const handleSetQrData = useCallback((data: string) => {
    setQrData(data);
    setReceiptData(null);
    setErrorMessage(null);
    setProcessing(true);
    try {
      const parsedData = ReceiptUtils.convertToSearchModel(data);
      if (!parsedData || checkForMultipleEmptyFields(parsedData)) {
        const errorMsg = 'Не вдалося розпізнати QR-код. Спробуйте інше зображення.';
        setErrorMessage(errorMsg);
        onNotify(errorMsg, 'error');
        setProcessing(false);
      } else {
        setReceiptData(parsedData);
        setErrorMessage(null);
        setIsDataSaved(true);
        setProcessing(false); // додано щоб зупинити обробку
      }
    } catch (error) {
      const errorMsg = 'Не вдалося розпізнати QR-код. Спробуйте інше зображення.';
      setErrorMessage(errorMsg);
      onNotify(errorMsg, 'error');
      setProcessing(false);
    }
  }, [checkForMultipleEmptyFields, onNotify]);

  const isValid = useMemo(() => qrData !== '' && !errorMessage, [qrData, errorMessage]);

  const handleFormSubmit = useCallback(async () => {
    if (!isValid || processing || openConfirmation) return;

    setLoading(true);

    const transactionData: NewReceiptByQrCodeDto = {
      qrCodeData: qrData
    };

    try {
      let result = await checkReceipt(transactionData);

      if (result.data && !result.isError) {
        setOpenConfirmation(true);
      } else if (!result.data && !result.isError) {
        await addNewReceipt(transactionData).unwrap();
        onNotify('Успішно додано', 'success');
        setIsDataSaved(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      onNotify(t('Виникли проблеми. Спробуйте пізніше. Дайте час нашому розробнику'), 'error');
    } finally {
      setLoading(false);
    }
  }, [isValid, qrData, addNewReceipt, onNotify, checkReceipt, t]);

  const handleConfirmationClose = useCallback(() => {
    setOpenConfirmation(false);
    setProcessing(false);
  }, []);

  const handleConfirmationAccept = useCallback(async () => {
    setOpenConfirmation(false);
    setLoading(true);
    try {
      await addNewReceipt({ qrCodeData: qrData }).unwrap();
      onNotify('Успішно додано', 'success');
      setIsDataSaved(true);
    } catch (error) {
      onNotify('Виникли проблеми', 'error');
    } finally {
      setLoading(false);
      setProcessing(false); // додано щоб зупинити обробку
    }
  }, [addNewReceipt, onNotify, qrData]);

  const resetState = useCallback(() => {
    setQrData('');
    setIsDataSaved(false);
    setLoading(false);
    setErrorMessage(null);
    setOpenConfirmation(false);
    setReceiptData(null);
    setProcessing(false);
  }, []);

  return {
    qrData,
    isDataSaved,
    setIsDataSaved,
    loading,
    errorMessage,
    openConfirmation,
    receiptData,
    handleSetQrData,
    handleFormSubmit,
    handleConfirmationClose,
    handleConfirmationAccept,
    resetState,
    processing
  };
};
