import { RouteObject } from 'react-router-dom';
import { ProfilePage } from './pages/profile/profile.page';
import { ProfileSecurityPage } from './pages/security/security.page';
import { HomePage } from './pages/home/homePage';
import { ProfileRoutes } from './constants';
import {ReceiptsListPage} from "../receipts/pages/receipts-list/receipts-list-page";
import {ReceiptsStatisticPage} from "../statistics/pages/receipts-statistic/receipts-statistic-page";

export const ProfileModuleRouter: RouteObject[] = [
	{ path: ProfileRoutes.Root, element: <HomePage />, handle: { title: 'Головна' } },
	{ path: ProfileRoutes.Profile, element: <ProfilePage />, handle: { title: 'nav.profile', to: ProfileRoutes.Root } },
	{ path: ProfileRoutes.Transactions, element: <ReceiptsListPage />, handle: { title: 'Покупки', to: ProfileRoutes.Root } },
	{ path: ProfileRoutes.Statistic, element: <ReceiptsStatisticPage />, handle: { title: 'Статистика', to: ProfileRoutes.Root } },
	{ path: ProfileRoutes.SecuritySettings, element: <ProfileSecurityPage />, handle: { title: 'nav.profile', to: ProfileRoutes.Root } },
];
