import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Typography, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { qrScannerStyles } from './qr-scanner.styles';
import QrScanner from 'qr-scanner';
import { CoreButton, CoreIconButton } from "../../../_core/components/_ui/core-button/index";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import InfoIcon from '@mui/icons-material/Info'; // Додано
import { FileUploadScanner } from '../file-upload-qrcode-scanner/file-upload-qrcode-scanner';
import AddReceiptByQrcodeInstructionModal from '../../components/add-receipt-by-qrcode-instruction/add-receipt-by-qrcode-instruction.modal';

interface QrScannerProps {
  showQrReader: boolean;
  handleCancelQrReader: () => void;
  setQrData: (data: string) => void;
  notificationMessage?: string;
  notificationSeverity?: 'success' | 'error';
  processing?: boolean;
}

declare global {
  interface Window {
    ImageCapture: any;
  }
}

const QrScannerComponent: React.FC<QrScannerProps> = ({
                                                        showQrReader,
                                                        handleCancelQrReader,
                                                        setQrData,
                                                        notificationMessage,
                                                        notificationSeverity,
                                                        processing
                                                      }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const qrScannerRef = useRef<QrScanner | null>(null);
  const [flashOn, setFlashOn] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false); // Додано
  const { t } = useTranslation();

  const toggleFlash = useCallback(() => {
    setFlashOn(prev => !prev);
  }, []);

  const toggleInfoModal = useCallback(() => { // Додано
    setShowInfoModal(prev => !prev);
  }, []);

  const handleQrData = (data: string) => {
    if (!processing) {
      setQrData(data);
    }
  };

  const handleFileUploadError = (error: string | null) => {
    if (error != null && !processing) {
      setQrData(error as string);
    }
  };

  useEffect(() => {
    const startScanner = async () => {
      if (showQrReader && videoRef.current) {
        const videoConstraints = {
          facingMode: 'environment',
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        };

        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: videoConstraints });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
          const qrScanner = new QrScanner(videoRef.current, (result) => {
            handleQrData(result.data);
          }, {
            onDecodeError: () => { },
            maxScansPerSecond: 1
          });

          qrScannerRef.current = qrScanner;
          qrScanner.start().catch(err => console.error('Error starting scanner:', err));
        } catch (error) {
          console.error('Error accessing camera:', error);
        }
      }
    };

    startScanner();

    return () => {
      qrScannerRef.current?.stop();
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [showQrReader]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const videoTrack = stream.getVideoTracks()[0];
      const imageCapture = new window.ImageCapture(videoTrack);

      imageCapture.getPhotoCapabilities().then((capabilities: any) => {
        if (capabilities.fillLightMode.includes('flash')) {
          videoTrack.applyConstraints({
            advanced: [{ torch: flashOn } as any]
          }).catch((err: any) => console.error('Error setting torch mode:', err));
        }
      }).catch((err: any) => console.error('Error getting photo capabilities:', err));
    }
  }, [flashOn]);

  useEffect(() => {
    const infoModalShown = localStorage.getItem('qrCodeInstructionModalShown');
    if (!infoModalShown) {
      setShowInfoModal(true);
      localStorage.setItem('qrCodeInstructionModalShown', 'true');
    }
  }, []);

  return (
    <>
      {showQrReader && (
        <Box sx={qrScannerStyles.fullScreenContainer}>
          <Snackbar
            open={!!notificationMessage}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity={notificationSeverity}>
              {notificationMessage}
            </Alert>
          </Snackbar>
          <Box sx={qrScannerStyles.qrReaderContainer}>
            <video ref={videoRef} style={qrScannerStyles.video} />
            <Typography variant="h6" sx={qrScannerStyles.title} noWrap>
              {t('Сканування QR-коду')}
            </Typography>
            <CoreIconButton onClick={toggleInfoModal} sx={qrScannerStyles.infoButton}>
              <InfoIcon sx={{ fontSize: 40 }} />
            </CoreIconButton>
          </Box>
          <Box sx={qrScannerStyles.buttonContainer}>
            <FileUploadScanner setQrData={handleQrData} setError={handleFileUploadError} />
            <CoreButton
              color="primary"
              onClick={handleCancelQrReader}
              sx={qrScannerStyles.cancelButton}
            >
              {t('Скасувати')}
            </CoreButton>
            <CoreIconButton onClick={toggleFlash} sx={qrScannerStyles.flashButton}>
              {flashOn ? <FlashOffIcon sx={{ fontSize: 30 }} /> : <FlashOnIcon sx={{ fontSize: 30 }} />}
            </CoreIconButton>
          </Box>
          <AddReceiptByQrcodeInstructionModal open={showInfoModal} onClose={toggleInfoModal} />
        </Box>
      )}
    </>
  );
};

export default QrScannerComponent;
