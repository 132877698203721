import { SxProps, Theme } from '@mui/material';

export const addTransactionPageStyles = {
  container: {
    height: '100%',
    width: '100%',
  },
  newProduct: {
    overflowY: 'auto',
    overflowX: 'hidden',
    m: 0,
  },
  formControl: {
    margin: 'normal',
    minWidth: 120,
    width: '100%',
  },
  button: {
    mt: 0,
    mb: 12,
  },
  textField: {
    mt: 0.5,
    width: '100%',
  },
  description: {
    m: 0,
    width: '100%',
  },
  dateFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    p:0
  } as SxProps<Theme>,
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    height: '48px',
  } as SxProps<Theme>,
  iconButton: {
    marginTop: 0,
  } as SxProps<Theme>,
  footer: {
    zIndex: 9,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    borderTop: '1px solid #e0e0e0',
    px: 1,
    py: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  } as SxProps<Theme>,
  closeButton: {
    ml: 1,
    backgroundColor: theme => theme.colors.darkGray,
    color: theme => theme.colors.white,
    '&:hover': {
      backgroundColor: theme => theme.colors.gray,
    },
  } as SxProps<Theme>,
  saveButton: {
    width: '85%',
    mr: 1,
  } as SxProps<Theme>,
  modalHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    borderBottom: '1px solid #e0e0e0',
    pb: 2,
    textAlign: 'center'
  } as SxProps<Theme>,
};
