import { ForwardRefExoticComponent, forwardRef } from 'react';
import { MenuItem, MenuItemProps, Typography, Theme, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CoreCheckbox } from '../core-checkbox';
import { selectCheckboxStyles } from './core-select.styles';
import { StyledComponent } from '@emotion/styled';

export interface CoreSelectMenuItemProps extends MenuItemProps {
	withCheckbox?: boolean;
	noWrap?: boolean;
	customContainer?: boolean;
	forceSelected?: boolean;
	textSx?: SxProps<Theme>;
}

const CoreForwardedSelectMenuItem = forwardRef<
	HTMLLIElement,
	CoreSelectMenuItemProps
>((props, ref) => {
	const { forceSelected, withCheckbox, noWrap, selected, children, customContainer, textSx, ...menuItemProps } = props;

	const isMenuItemSelected = forceSelected ?? selected;

	return (
		<MenuItem {...menuItemProps} ref={ref} selected={isMenuItemSelected}>
			{withCheckbox && <CoreCheckbox checked={isMenuItemSelected} sx={selectCheckboxStyles} />}
			{customContainer && children}
			{!customContainer && (
				<Typography variant="label" noWrap={noWrap} sx={textSx}>
					{children}
				</Typography>
			)}
		</MenuItem>
	);
});

CoreForwardedSelectMenuItem.displayName = 'CoreSelectMenuItem';

export const CoreSelectMenuItem = styled(CoreForwardedSelectMenuItem)(
	({ theme }) => ({
		padding: '0 16px',
		minHeight: 'auto',
		height: 34,

		'&.Mui-selected': {
			backgroundColor: theme.colors.blueTransparentLight,
		},
		'&:hover': {
			backgroundColor: theme.colors.blueTransparentLight,
		},
		'& .MuiTypography-root': {
			fontWeight: 300,
		},
	})
);
