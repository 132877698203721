import { FC, memo, useCallback, useState } from 'react';
import { AutocompleteRenderInputParams, Box, InputAdornment, Typography, useTheme } from '@mui/material';
import { CoreTextField, CoreTextFieldProps } from '../core-textfield';
import { FadeSpinner } from '../fade-spinner';
import {
	selectedCountNumberStyles,
	selectedCountWrapperStyles,
} from '../core-conditional-select/core-conditional-select.styles';
import { Option } from './core-autocomplete.types';

export interface CoreAutocompleteInputProps
	extends AutocompleteRenderInputParams,
		Omit<CoreTextFieldProps, 'inputProps'> {
	id: string;
	disabled: boolean;
	fullWidth: boolean;
	size: 'small' | undefined;
	value?: Option | Option[] | null;
	loading?: boolean;
	showSelectedCount?: boolean;
	hide?: boolean;
	onInputChange?(value?: string): void;
}

export const CoreAutocompleteInput: FC<CoreAutocompleteInputProps> = memo((props) => {
	const {
		id,
		fullWidth,
		disabled,
		inputProps,
		InputProps,
		label,
		error,
		controlSx,
		helperText,
		sx,
		value,
		requiredMark,
		placeholder,
		loading,
		showSelectedCount,
		onInputChange,
		hide = false,
	} = props;

	const { ref, endAdornment, ...wrapperProps } = InputProps;

	const [inputValue, setInputValue] = useState<string | undefined>('');

	const theme = useTheme();

	const handleSetInputValue = useCallback(
		(newValue?: string) => {
			setInputValue(newValue || undefined);
			onInputChange?.(newValue || undefined);
		},
		[onInputChange]
	);

	const handleChangeInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			handleSetInputValue(e.target.value);
		},
		[handleSetInputValue]
	);

	const resetInputValue = useCallback(() => {
		handleSetInputValue();
	}, [handleSetInputValue]);

	return (
		<CoreTextField
			id={id}
			label={label}
			error={error}
			controlSx={controlSx}
			helperText={helperText}
			wrapperRef={ref}
			onChange={handleChangeInput}
			onFocus={resetInputValue}
			onBlur={resetInputValue}
			sx={{
				'& .MuiInputBase-input': {
					opacity: hide ? '0' : '1',
				},

				'&.MuiInputBase-root': {
					pt: '3px',
				},
				'& input::placeholder': {
					color: `${
						disabled
							? theme.colors.blueTransparent
							: showSelectedCount && Array.isArray(value) && value.length
							? theme.colors.black
							: theme.colors.gray
					} !important`,
				},
				...sx,
			}}
			requiredMark={requiredMark}
			placeholder={placeholder}
			inputProps={inputProps}
			fullWidth={fullWidth}
			disabled={disabled}
			endAdornment={
				<InputAdornment position='end'>
					{loading ? (
						<FadeSpinner size={17} height={5} width={2} borderRadius={1} color={theme.colors.black} />
					) : (
						showSelectedCount &&
						Array.isArray(value) &&
						!inputValue && (
							<Box
								sx={{
									position: 'relative',
									right: '8px',
									top: '1px',
									pointerEvents: 'none',
								}}
							>
								<Box sx={selectedCountWrapperStyles(disabled, value.length)}>
									<Typography sx={selectedCountNumberStyles(disabled, value.length)}>{value.length}</Typography>
								</Box>
							</Box>
						)
					)}
					{endAdornment}
				</InputAdornment>
			}
			{...wrapperProps}
			startAdornment={InputProps.startAdornment}
		/>
	);
});

CoreAutocompleteInput.displayName = 'CoreAutocompleteInput';
