import { SxProps, Theme } from '@mui/material';

export const homePageStyles = {
  container: {
    maxWidth: 'sm',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: (theme: Theme) => theme.colors.grayLight,
    overflowY: 'auto'
  } as SxProps<Theme>,
  paper: {
    p: 2,
    width: '100%',
    textAlign: 'center',
    borderRadius: 2,
    boxShadow: '1 rgba(0, 0, 0, 0.1)',
  } as SxProps<Theme>,
  title: {
    mb: 2,
    fontSize: '1.2rem',
    fontWeight: 'bold',
  } as SxProps<Theme>,
  skeleton: {
    p: 3
  } as SxProps<Theme>,
  chartTitle: {
    mb: 2,
    fontSize: '1rem',
    fontWeight: 'bold',
  } as SxProps<Theme>,
  amountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 2,
  } as SxProps<Theme>,
  amountSymbol: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    mr: 1,
    color: (theme: Theme) => theme.palette.primary.main,
  } as SxProps<Theme>,
  amountValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: (theme: Theme) => theme.palette.primary.main,
  } as SxProps<Theme>,
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButton-root': {
      mx: 0.5,
      fontSize: '0.75rem',
      borderRadius: '20px',
      padding: '4px 12px',
      minWidth: 'auto',
      border: '1px solid',
      '&.MuiButton-contained': {
        backgroundColor: (theme: Theme) => theme.palette.primary.main,
        borderColor: (theme: Theme) => theme.palette.primary.main,
      },
    },
  } as SxProps<Theme>,
  transactionContainer: {
    width: '100%',
    p: 2,
    borderRadius: 2,
    boxShadow: '1 rgba(0, 0, 0, 0.1)',
  } as SxProps<Theme>,
  transactionItem: {
    display: 'flex',
    flexDirection: 'column',
    p: 1,
  } as SxProps<Theme>,
  showMoreButton: {
    textTransform: 'none',
    fontSize: '0.75rem',
    padding: '4px 8px',
    borderRadius: '12px',
    mb: 2,
  } as SxProps<Theme>,
  transactionDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as SxProps<Theme>,
  transactionAmount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  } as SxProps<Theme>,
  moreButton: {
    padding: 0,
  } as SxProps<Theme>,
};
