export interface SortOption {
  label: string;
  value: string;
  orderBy: number;
}

export const sortOptions: SortOption[] = [
  { label: 'від дорогих до дешевих', value: 'sum', orderBy: 0 },
  { label: 'від дешевих до дорогих', value: 'sum', orderBy: 1 },
  { label: 'від старих до нових', value: 'dateOfPurchase', orderBy: 1 },
  { label: 'від нових до старих', value: 'dateOfPurchase', orderBy: 0 },
  { label: 'від нещодавно доданих', value: 'addedDate', orderBy: 0 },
  { label: 'від давно доданих', value: 'addedDate', orderBy: 1 },
];
