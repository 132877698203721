import { SxProps, Theme } from '@mui/material';

export const containerStyles: SxProps<Theme> = [
	{
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		backgroundColor: '#ffffff',
		p: 2,
		pt: 5
	}
];

export const googleAuthRowStyles: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	marginBottom: '2rem',
};

export const footerStyles: SxProps<Theme> = {
	marginTop: 'auto',
	textAlign: 'center',
	padding: '20px 0',
};

export const titleStyles: SxProps<Theme> = {
	fontWeight: 'bold',
	mt: 2,
	mb: 3,
	textAlign: 'center',
};

export const descriptionStyles: SxProps<Theme> = {
	marginBottom: '2rem',
	color: '#757575',
	textAlign: 'center',
};

export const carouselItemStyles: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '10px',
	marginBottom: '10px',
	textAlign: 'center',
};
