import React, { FC, useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Product } from '../../interfaces/product.interface';
import { productStyles } from './product.styles';
import { ProductNameInput } from './product-name-input/product-name-input.component';
import { ProductQuantityPriceInput } from './product-quantity-price-input/product-quantity-price-input.component';
import DeleteIcon from '@mui/icons-material/Delete';

interface ProductProps {
  product: Product;
  onProductChange: (product: Product) => void;
  onDelete: () => void;
  disabled?: boolean;
  onValidationChange?: (isValid: boolean) => void;
  canDelete?: boolean;
  autoFocus?: boolean;
}

export const ProductComponent: FC<ProductProps> = ({ product, onProductChange, onDelete, disabled = false, onValidationChange, canDelete = true, autoFocus = false }) => {
  const [t] = useTranslation();
  const [errors, setErrors] = useState({
    name: '',
    quantity: '',
    price: '',
  });

  const validate = (name: keyof Product, value: any) => {
    let error = '';
    switch (name) {
      case 'name':
        if (value.length < 3) {
          error = 'Ім\'я продукту має містити мінімум 3 символи';
        } else if (/^\d+$/.test(value)) {
          error = 'Ім\'я продукту не може складатися тільки з цифр';
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const fieldName = name as keyof Product;

    if (name === 'quantity' || name === 'price') {
      if (value === '' || (name === 'quantity' && value.match(/^(\d+(\.\d{0,3})?)?$/)) || (name === 'price' && value.match(/^(\d+(\.\d{0,2})?)?$/))) {
        setErrors({
          ...errors,
          [name]: validate(fieldName, value),
        });

        onProductChange({
          ...product,
          [fieldName]: value,
        });
      }
    } else {
      setErrors({
        ...errors,
        [name]: validate(fieldName, value),
      });

      onProductChange({
        ...product,
        [fieldName]: value,
      });
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const fieldName = name as keyof Product;
    if (product[fieldName] === 0) {
      onProductChange({
        ...product,
        [fieldName]: '',
      });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const fieldName = name as keyof Product;
    if (product[fieldName] === '' || isNaN(product[fieldName] as unknown as number)) {
      onProductChange({
        ...product,
        [fieldName]: 0,
      });
    } else if (fieldName === 'quantity' || fieldName === 'price') {
      onProductChange({
        ...product,
        [fieldName]: parseFloat(product[fieldName] as unknown as string).toFixed(fieldName === 'quantity' ? 3 : 2),
      });
    }
  };

  useEffect(() => {
    const allValid = !errors.name && !errors.quantity && !errors.price &&
      product.name.length >= 3 &&
      !/^\d+$/.test(product.name) &&
      product.quantity > 0 &&
      product.price > 0;
    if (onValidationChange) {
      onValidationChange(allValid);
    }
  }, [errors, product, onValidationChange]);

  return (
    <Box sx={productStyles.container}>
      <ProductNameInput
        value={product.name}
        onChange={handleInputChange}
        error={errors.name}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      <ProductQuantityPriceInput
        quantity={product.quantity}
        price={product.price}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        quantityError={errors.quantity}
        priceError={errors.price}
        disabled={disabled}
      />
      <IconButton
        onClick={onDelete}
        sx={productStyles.deleteButton}
        disabled={disabled || !canDelete}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};
