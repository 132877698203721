import { FC, ReactNode } from "react";
import { VirtualItem } from "@tanstack/react-virtual";
import { Box } from "@mui/material";

interface CoreInfiniteListVirtualRowProps {
	virtualRow: VirtualItem;
	children?: ReactNode;
	measureElement(node: (Element | null)): void;
}

const CoreInfiniteListVirtualRow: FC<CoreInfiniteListVirtualRowProps> = (props) => {
	const { children, measureElement, virtualRow } = props;

	return (
		<Box
			ref={measureElement}
			data-index={virtualRow.index}
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				transform: `translateY(${virtualRow.start}px)`,
			}}
		>
			{children}
		</Box>
	);
};

export default CoreInfiniteListVirtualRow;
