import React, { FC, useEffect } from 'react';
import { Modal, Box, Typography, CircularProgress, Grid, Tooltip } from '@mui/material';
import { receiptViewModalStyles } from './receipt-view.styles';
import { useLazyGetReceiptQuery, useLazyGetOutcomeTransactionQuery } from '../../../../store/api/transactions.api';
import QrCodeIcon from '@mui/icons-material/QrCode';
import StoreIcon from '@mui/icons-material/Store';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useTranslation } from "react-i18next";
import { CoreButton, CoreIconButton } from "../../../_core/components/_ui/core-button";

interface ReceiptViewModalProps {
  open: boolean;
  onClose: () => void;
  receiptId: string;
  isTransaction: boolean;
}

export const ReceiptViewModal: FC<ReceiptViewModalProps> = ({ open, onClose, receiptId, isTransaction }) => {
  const [trigger, { data, error, isLoading }] = useLazyGetReceiptQuery();
  const [transactionTrigger, { data: transactionData, error: transactionError, isLoading: transactionIsLoading }] = useLazyGetOutcomeTransactionQuery();

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      if (isTransaction) {
        transactionTrigger(receiptId);
      } else {
        trigger(receiptId);
      }
    }
  }, [open, trigger, transactionTrigger, receiptId, isTransaction]);

  const handleQrCodeClick = () => {
    const qrCodeLink = data?.qrCodeLink;
    if (qrCodeLink) {
      window.open(qrCodeLink, '_blank');
    }
  };

  const getIcon = (method: string | undefined) => {
    switch (method) {
      case 'qrcode':
        return <QrCodeIcon fontSize={'large'} sx={receiptViewModalStyles.icon} onClick={handleQrCodeClick} />;
      default:
        return null;
    }
  };

  const formatDate = (date: string | undefined) => {
    return new Date(date as string).toLocaleString('uk-UA',
      {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
  }

  const formatNumber = (num: number | undefined) => {
    if(num != undefined){
      return num.toFixed(2);
    }
    return undefined;
  };

  const renderData = isTransaction ? transactionData : data;

  const hasAddress = (data: any): data is { address: string } => data && 'address' in data;
  const hasCashiersName = (data: any): data is { cashiersName: string } => data && 'cashiersName' in data;
  const hasTax = (data: any): data is { tax: number } => data && 'tax' in data;
  const hasAddedWay = (data: any): data is { addedWay: string } => data && 'addedWay' in data;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={receiptViewModalStyles.modal}>
        {isLoading || transactionIsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={receiptViewModalStyles.header}>
              <ReceiptIcon sx={receiptViewModalStyles.icon} />
              <Typography variant="h6" sx={receiptViewModalStyles.title}>
                {t('Чек за')} {formatDate(renderData?.dateOfPurchase) ?? '---'}
              </Typography>
            </Box>
            <Box sx={receiptViewModalStyles.content}>
              <Typography variant="body1" sx={receiptViewModalStyles.sectionTitle}>
                Інформація про покупку
              </Typography>
              <Box sx={receiptViewModalStyles.fieldRow}>
                <StoreIcon sx={receiptViewModalStyles.icon} />
                <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Магазин:</strong></Typography>
                <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{renderData?.shopName ?? '---'}</Typography>
              </Box>
              {hasAddress(renderData) && (
                <Box sx={receiptViewModalStyles.fieldRow}>
                  <LocationOnIcon sx={receiptViewModalStyles.icon} />
                  <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Адреса:</strong></Typography>
                  <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{renderData?.address ?? '---'}</Typography>
                </Box>
              )}
              {hasCashiersName(renderData) && (
                <Box sx={receiptViewModalStyles.fieldRow}>
                  <PersonIcon sx={receiptViewModalStyles.icon} />
                  <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Касир:</strong></Typography>
                  <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{renderData?.cashiersName ?? '---'}</Typography>
                </Box>
              )}
              <Box sx={receiptViewModalStyles.fieldRow}>
                <DateRangeIcon sx={receiptViewModalStyles.icon} />
                <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Дата додавання:</strong></Typography>
                <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{
                  formatDate(renderData?.addedDate) ?? '---'}</Typography>
              </Box>
              <Box sx={receiptViewModalStyles.fieldRow}>
                <PaymentIcon sx={receiptViewModalStyles.icon} />
                <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Спосіб покупки:</strong></Typography>
                <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{t(renderData?.accountName as string) ?? '---'}</Typography>
              </Box>
              <Box sx={receiptViewModalStyles.fieldRow}>
                <DescriptionIcon sx={receiptViewModalStyles.icon} />
                <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Опис:</strong></Typography>
                <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{renderData?.description ?? '---'}</Typography>
              </Box>
              {!isTransaction && (
                <>
                  <Typography variant="body1" sx={receiptViewModalStyles.sectionTitle}>
                    Товари
                  </Typography>
                  {data?.products.map((product: any, index: number) => (
                    <Box key={index} sx={receiptViewModalStyles.product}>
                      <Tooltip title={product.name} arrow>
                        <Typography variant="body2" sx={receiptViewModalStyles.productName} noWrap>{product.name}</Typography>
                      </Tooltip>
                      <Typography variant="body2" sx={receiptViewModalStyles.productDetails}>{product.quantity} x {product.price} грн = <span style={{ fontWeight: 'bold', textAlign: 'right' }}>{formatNumber(product.sum)} грн</span></Typography>
                    </Box>
                  ))}
                </>
              )}

              <Typography variant="body1" sx={receiptViewModalStyles.sectionTitle}>
                Загальна вартість
              </Typography>
              {hasTax(renderData) && (
                <Box sx={receiptViewModalStyles.fieldRow}>
                  <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Податок:</strong></Typography>
                  <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{formatNumber(renderData.tax)} грн</Typography>
                </Box>
              )}
              <Box sx={receiptViewModalStyles.fieldRow}>
                <Typography variant="body2" sx={receiptViewModalStyles.fieldLabel}><strong>Сума без податку:</strong></Typography>
                <Typography variant="body2" sx={receiptViewModalStyles.fieldValue}>{renderData ? formatNumber(renderData.sum - (hasTax(renderData) ? renderData.tax : 0)) : '---'} грн</Typography>
              </Box>
              <Grid container sx={{ pt: 3 }}>
                <Grid item xs={6}>
                  {hasAddedWay(renderData) && renderData.addedWay !== 'manual' && (
                    <CoreIconButton>
                      {getIcon(renderData.addedWay)}
                    </CoreIconButton>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={receiptViewModalStyles.totalAmount}>
                    Сума: {formatNumber(renderData?.sum) ?? '---'} грн
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Box sx={receiptViewModalStyles.footer}>
          <CoreButton variant={'secondary'} onClick={onClose}>
            Закрити
          </CoreButton>
        </Box>
      </Box>
    </Modal>
  );
};
