import { SxProps, Theme } from '@mui/material';

export const coreCalendarHeaderContainerStyles: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	mb: 1.5,
	position: 'relative',
};

export const calendarHeaderButtonLeftStyles: SxProps<Theme> = {
	top: 0,
	left: 0,
	color: theme => theme.colors.gray,
	position: 'absolute',
};

export const calendarHeaderButtonRightStyles: SxProps<Theme> = {
	top: 0,
	right: 0,
	color: theme => theme.colors.gray,
	position: 'absolute',
};

export const calendarHeaderDateTextStyles: SxProps<Theme> = {
	flex: 1,
	textAlign: 'center',
	fontWeight: 700,
	lineHeight: '36px',
	height: '36px',
	textTransform: 'capitalize',
};

export const coreCalendarInputStyles = (hasValue?: unknown, sxProp?: SxProps<Theme>): SxProps<Theme> => ({
	...sxProp,
	pr: '16px',

	'& .MuiInputBase-input': {
		fontSize: '12px',
		pr: 0,
	},

	'& .MuiInputAdornment-root': {
		color: ({ colors }) => (hasValue ? colors.blue : colors.gray),
	},
	'&.Mui-focused .MuiInputAdornment-root': {
		color: (theme) => theme.colors.blue,
	},
	'&.Mui-disabled .MuiInputAdornment-root': {
		color: (theme) => theme.colors.blueTransparent,
	},
});

export const coreCalendarTimeInputStyles: SxProps<Theme> = {
	maxWidth: 126,
};

export const coreCalendarTimeSeparatorStyles: SxProps<Theme> = {
	mx: 1,
	mt: '19.6px',
};
