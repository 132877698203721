import React, {useState, useCallback, useEffect} from 'react';
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Box,
  Typography,
  Snackbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import CreateIcon from '@mui/icons-material/Create';
import SmartToy from '@mui/icons-material/SmartToy';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import AddReceiptByManualPage from '../../pages/add-receipt-by-manual/add-receipt-by-manual.page';
import AddTransactionPage from '../../pages/add-transaction/add-transaction.page';
import AddReceiptByScanQrcodePage from '../../pages/add-receipt-by-scan-qrcode/add-receipt-by-scan-qrcode.page';
import AddTransactionByGptPage from '../../pages/add-transaction-by-gpt/add-transaction-by-gpt.page';

import {
  overlayStyles,
  actionContainerStyles,
  actionTextStyles,
  actionSpeedDialogStyles,
  iconStyles,
  speedDialStyles
} from './add-transaction-floating-action-button.styles';

const AlertComponent = React.forwardRef<HTMLDivElement, AlertProps>(function AlertComponent(
  props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export type PageMode = 'manual' | 'scan' | 'transaction' | 'gpt';
interface AddReceiptSDAProps {
  update?: () => void;
}

export const AddReceiptSDA: React.FC<AddReceiptSDAProps> = ({ update }) => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState<null | PageMode>(null);
  const [notification, setNotification] = useState<{ message: string, severity: 'success' | 'error' } | null>(null);

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => setOpen(false);

  const handleModalOpen = (page: PageMode) => {
    setSelectedPage(page);
    setModalOpen(true);
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setOpen(false);
  };

  const handleNotification = useCallback((message: string, severity: 'success' | 'error') => {
    setNotification({ message, severity });
    setModalOpen(false);
    setOpen(false);
    if (update) {
      update();
    }
  }, [update]);

  const handleCloseNotification = useCallback(() => {
    setNotification(null);
  }, []);

  return (
    <>
      {open && <Box sx={overlayStyles} onClick={handleClose}/>}
      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={speedDialStyles}
        icon={<SpeedDialIcon icon={<AddIcon/>} openIcon={<MenuOutlinedIcon/>}/>}
        onClose={handleClose}
        onClick={handleOpen}
        open={open}
      >
        <SpeedDialAction
          icon={
            <Box sx={actionContainerStyles}>
              <Typography sx={actionTextStyles}>Додати через QR-код</Typography>
              <DocumentScannerOutlinedIcon sx={iconStyles}/>
            </Box>
          }
          onClick={() => handleModalOpen('scan')}
          sx={actionSpeedDialogStyles}
        />
        <SpeedDialAction
          icon={
            <Box sx={actionContainerStyles}>
              <Typography sx={actionTextStyles}>Додати транзакцію</Typography>
              <SwapHorizIcon sx={iconStyles}/>
            </Box>
          }
          onClick={() => handleModalOpen('transaction')}
          sx={actionSpeedDialogStyles}
        />
        <SpeedDialAction
          icon={
            <Box sx={actionContainerStyles}>
              <Typography sx={actionTextStyles}>Додати вручну</Typography>
              <CreateIcon sx={iconStyles}/>
            </Box>
          }
          onClick={() => handleModalOpen('manual')}
          sx={actionSpeedDialogStyles}
        />
        <SpeedDialAction
          icon={
            <Box sx={actionContainerStyles}>
              <Typography sx={actionTextStyles}>Додати через ШІ</Typography>
              <SmartToy sx={iconStyles}/>
            </Box>
          }
          onClick={() => handleModalOpen('gpt')}
          sx={actionSpeedDialogStyles}
        />
      </SpeedDial>

      {selectedPage === 'transaction' &&
          <AddTransactionPage onOpen={modalOpen} onClose={handleModalClose} onNotify={handleNotification}/>}
      {selectedPage === 'manual' &&
          <AddReceiptByManualPage onOpen={modalOpen} onClose={handleModalClose} onNotify={handleNotification}/>}
      {selectedPage === 'scan' &&
          <AddReceiptByScanQrcodePage onOpen={modalOpen} onClose={handleModalClose} onNotify={handleNotification}/>}
      {selectedPage === 'gpt' &&
          <AddTransactionByGptPage onOpen={modalOpen} onClose={handleModalClose} onNotify={handleNotification}/>}

      {notification && (
        <Snackbar
          open={!!notification}
          autoHideDuration={3000}
          onClose={handleCloseNotification}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <AlertComponent onClose={handleCloseNotification} severity={notification.severity}>
            {notification.message}
          </AlertComponent>
        </Snackbar>
      )}
    </>
  );
};