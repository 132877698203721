export enum LanguageEnum {
	English = '3fe1b09b-eeba-42da-a412-a4e2b26c005b',
	Ukrainian = 'beebf7d7-efe3-46dc-8717-971d3623e068',
}

export enum LangCodesEnum {
	EN = 'en',
	UK = 'uk',
}

export const LangCodeRegionEnum = {
	[LangCodesEnum.EN]: 'GB',
	[LangCodesEnum.UK]: 'UA',
};

export const LanguageCode3Map: Record<LanguageEnum, string> = {
	[LanguageEnum.English]: 'eng',
	[LanguageEnum.Ukrainian]: 'ukr',
};

export const LanguagesList = Object.values(LanguageEnum);

export const LangCodesList = Object.values(LangCodesEnum);
