import { SxProps, Theme } from '@mui/material';

export const productQuantityPriceStyles = {
  quantityField: {
    width: '100%',
  } as SxProps<Theme>,
  priceField: {
    width: '100%',
  } as SxProps<Theme>,
};
