import api from '.';
import {ResultWithoutTotal, ResultWithStatus} from "libs/interfaces/result";
import {AreaChartValue, TotalSpendsByAccountDto, TotalSpendsDto} from "libs/models/dto/statistic.dto";
import {TimeFilterEnum} from "libs/enums/time-filter.enum";

const statisticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTotalSpends: builder.query<ResultWithStatus<TotalSpendsDto>, void>({
      query: () => ({
        url: `statistics/get-total-spends`
      })
    }),
    getTotalSpendsByAccount: builder.query<ResultWithoutTotal<TotalSpendsByAccountDto>, TimeFilterEnum>({
      query: (time) => ({
        url: `statistics/get-total-spends-by-account`,
        params: {timeFilter: time}
      })
    }),
    getFrequencyOfSpending: builder.query<ResultWithoutTotal<AreaChartValue>, TimeFilterEnum>({
      query: (time) => ({
        url: `statistics/get-frequency-of-spending`,
        params: {timeFilter: time}
      })
    }),
    getSpendingTrend: builder.query<ResultWithoutTotal<AreaChartValue>, TimeFilterEnum>({
      query: (time) => ({
        url: `statistics/get-spending-trend`,
        params: {timeFilter: time}
      })
    }),
  }),
});

export default statisticsApi;

export const {
  useGetTotalSpendsQuery,
  useGetTotalSpendsByAccountQuery,
  useGetFrequencyOfSpendingQuery,
  useGetSpendingTrendQuery
} = statisticsApi;
