import React, { FC } from 'react';
import BaseDialog from '../../../_core/components/_ui/base-dialog/base-dialog.component';

interface DeniedDialogProps {
  open: boolean;
  onClose: () => void;
}

const DeniedDialog: FC<DeniedDialogProps> = ({ open, onClose }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Доступ до камери відхилено"
      description="Ви відхилили запит на доступ до камери. Для використання цієї функції, будь ласка, надайте доступ до камери у налаштуваннях вашого пристрою."
      cancelText="Закрити"
    />
  );
};

export default DeniedDialog;
