import { styled } from "@mui/material/styles";
import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import { StyledComponent } from "@emotion/styled";

export const RadioFormLabel: StyledComponent<FormControlLabelProps> = styled((props: FormControlLabelProps) => (
	<FormControlLabel
		{...props}
		componentsProps={{
			typography: { variant: 'body2' }
		}}
	/>
))(() => ({}));
