import {FC, useState} from 'react';
import {Box, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {addedWay, ReceiptListDto} from "../../../../libs/models/dto/receipts-list.dto";
import StoreIcon from '@mui/icons-material/Store';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {receiptItemStyles} from './receipts-list-item.styles';
import {ReceiptViewModal} from '../../components/receipt-view/receipt-view.modal';
import {useRemoveReceiptMutation} from '../../../../store/api/transactions.api';
import BaseDialog from "../../../_core/components/_ui/base-dialog/base-dialog.component";
import {ReceiptEditModal} from "../../components/receipt-edit/receipt-edit.modal";

interface ReceiptItemProps {
  item: ReceiptListDto;
  onDeleteSuccess: () => void;
  onEditSuccess: () => void;
}

export const ReceiptItem: FC<ReceiptItemProps> = ({ item, onDeleteSuccess, onEditSuccess }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [removeReceipt, { isLoading }] = useRemoveReceiptMutation();

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: string) => {
    if (action === 'open') {
      setModalOpen(true);
    } else if (action === 'edit') {
      setEditModalOpen(true);
    } else if (action === 'delete') {
      setConfirmOpen(true);
    }
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      await removeReceipt(item.id).unwrap();
      setConfirmOpen(false);
      onDeleteSuccess();
    } catch (error) {
      console.error('Error deleting receipt', error);
    }
  };

  const convertToLocalDate = (utcDateString: string) => {
    const utcDate = new Date(utcDateString);
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  };

  return (
    <>
      <Paper sx={receiptItemStyles.paper} onClick={() => setModalOpen(true)}>
        <Box sx={receiptItemStyles.header}>
          <Typography variant="h6" sx={receiptItemStyles.headerText}>
            <StoreIcon fontSize="small" /> {item.shopName ? item.shopName : t('Без назви')}
          </Typography>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            size="small"
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleMenuClose}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem onClick={() => handleAction('edit')}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              {t('Змінити')}
            </MenuItem>
            <MenuItem onClick={() => handleAction('delete')}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              {t('Видалити')}
            </MenuItem>
          </Menu>
        </Box>
        <Divider sx={receiptItemStyles.divider} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary" sx={receiptItemStyles.infoText}>
              <CalendarTodayIcon fontSize="small" />
              {t('Дата покупки')}: {
              item.addedWay === addedWay.byQRCode ? new Date(item.dateOfPurchase).toLocaleString('uk-UA', {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              } ): convertToLocalDate(item.dateOfPurchase).toLocaleString('uk-UA', {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })
            }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary" sx={receiptItemStyles.infoText}>
              <AddCircleOutlineIcon fontSize="small" />
              {t('Додано')}: {
              convertToLocalDate(item.addedDate).toLocaleString('uk-UA', {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })
            }
            </Typography>
          </Grid>
          {item.address && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary" sx={receiptItemStyles.infoText}>
                <LocationOnIcon fontSize="small" />
                {item.address}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6} sx={receiptItemStyles.accountContainer}>
            <Typography variant="body2" color="textSecondary" sx={receiptItemStyles.infoText}>
              <AccountBalanceWalletIcon fontSize="small" /> {t(item.accountName)}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={receiptItemStyles.amountText}>
              ₴ {item.sum.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <ReceiptViewModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        receiptId={item.id}
        isTransaction={item.isTransaction}
      />
      <ReceiptEditModal
        open={editModalOpen}
        onSuccess={onEditSuccess}
        onClose={() => setEditModalOpen(false)}
        receiptId={item.id}
        isTransaction={item.isTransaction}
      />
      <BaseDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title="Видалення"
        description="Ви точно бажаєте видалити покупку?"
        onConfirm={handleDeleteConfirm}
        confirmText="Так"
        cancelText="Ні">
      </BaseDialog>
    </>
  );
};